import React from "react";
import { Carousel, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./testimonials.css";

const TestimonialSection = ({ employees }) => {
  return (
    <div className="testimonial-section">
      <Row className="align-items-center">
        {/* Left Side: Heading & Subtitle */}
        <Col md={5} className="text-light text-left testimonial-text-section">
          <h2 className="testimonial-title">What Our Employees Say</h2>
          <div className="testimonial-divider"></div>
          <p className="testimonial-subtitle">
            We value our team members and their experiences. Here’s what they
            have to say about working with us.
          </p>
        </Col>

        {/* Right Side: Testimonial Carousel */}
        <Col md={7}>
          <Carousel
            interval={4000}
            controls={true}
            indicators={true}
            pause="hover"
          >
            {employees.map((employee, idx) => (
              <Carousel.Item key={idx}>
                <Card className="testimonial-card">
                  <Card.Body className="d-flex flex-column align-items-center text-center">
                    <div className="testimonial-avatar-wrapper mb-3">
                      <img
                        src={employee.picture}
                        alt={employee.name}
                        className="testimonial-avatar"
                      />
                    </div>
                    <blockquote className="blockquote">
                      <p className="testimonial-text">"{employee.reviews}"</p>
                    </blockquote>
                    <Card.Title className="testimonial-name">
                      {employee.name}
                    </Card.Title>
                    <Card.Subtitle className="text-muted">
                      {employee.position}
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};

export default TestimonialSection;
