import React from "react";
import { Carousel, Card, Container, Row, Col } from "react-bootstrap";
import "./benefitcarousel.css"; // Ensure CSS is properly imported

const benefits = [
  {
    title: "Comprehensive Analysis",
    description: "Gain a holistic view of your organization's ESG performance.",
    image: "../newimages/comprehensiveanalysis.jpg",
    icon: "bi bi-lightning-fill",
  },
  {
    title: "Risk Mitigation",
    description:
      "Identify and address potential compliance or sustainability challenges.",
    image: "../industry/riskMitigation.png",
    icon: "bi bi-shield-check",
  },
  {
    title: "Data-Driven Decisions",
    description: "Leverage insights to drive impactful ESG strategies.",
    image: "../industry/datadriven.png",
    icon: "bi bi-gear-wide-connected",
  },
  {
    title: "Stakeholder Trust",
    description:
      "Build confidence among investors and customers with transparent reporting.",
    image: "../industry/trust.png",
    icon: "bi bi-person-circle",
  },
  {
    title: "Scalable and Customizable",
    description: "Tailor analytics to your organization's unique needs.",
    image: "../industry/Scalable.png",
    icon: "bi bi-lock-fill",
  },
];

// Function to split benefits into chunks of 3
const chunkArray = (arr, size) => {
  return arr.reduce((acc, _, i) => {
    if (i % size === 0) acc.push(arr.slice(i, i + size));
    return acc;
  }, []);
};

const BenefitsCarousel = () => {
  const chunkedBenefits = chunkArray(benefits, 3); // Split into groups of 3

  return (
    <div
      id="advantages"
      className="csgadvantages-section esg-container"
      style={{
        background: "url(../industry/esgbgimage.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        padding: "50px 0",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Container className="text-center mb-4">
        <h2 className="csgsection-title">
          Why ESG is Your Ultimate Choice for Smarter Workflows
        </h2>
        <p className="csgsection-subtitle">
          Discover the unique benefits that set SupplyChain apart from the rest.
        </p>
      </Container>

      {/* Bootstrap Carousel with Smooth Scrolling */}
      <Carousel indicators={true} controls={false} interval={4000} className="smooth-carousel">
        {chunkedBenefits.map((group, index) => (
          <Carousel.Item key={index}>
            <Row className="justify-content-center custom-row" >
              {group.map((item, idx) => (
                <Col key={idx} xs={12} sm={6} md={4} className="d-flex justify-content-center">
                  <Card className="csgbenefit-card text-center">
                    <Card.Img
                      variant="top"
                      src={item.image}
                      alt={item.title}
                      className="csgfixed-card-img img-fluid"
                    />
                    <Card.Body>
                      <div className="csgadvantage-icon mb-3">
                        <i className={item.icon}></i>
                      </div>
                      <Card.Title className="csgcardtitle">{item.title}</Card.Title>
                      <Card.Text className="csgcardtext">{item.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default BenefitsCarousel;
