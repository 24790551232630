import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import { Email, LinkedIn, LocationOn, Phone } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";

// JSON Data for Footer Sections
const footerSections = [
    {
        title: "Products",
        links: [
            { name: "GLAM", path: "/product/glam" },
            { name: "EAS", path: "/product/eas" },
            { name: "ESG", path: "/product/esg" },
            { name: "VACH", path: "/product/vach" },
            { name: "DOOT", path: "//product/doot" }
        ],
    },
    // {
    //     title: "Industry",
    //     links: [
    //         { name: "BFSI", path: "/resource/training" },
    //         { name: "Case Studies", path: "/resources/case-studies" },
    //         { name: "Blogs", path: "/resources/blogs" }
    //     ],
    // },
    {
        title: "Resources",
        links: [
            { name: "Training", path: "/resource/training" },
            { name: "Case Studies", path: "/resource/caseStudies" },
            { name: "Blogs", path: "/resource/blogs" }
        ],
    },
    {
        title: "About",
        links: [
            { name: "Team", path: "/team" },
            { name: "Careers", path: "/career" },
            // { name: "Blog", path: "/resource/blogs" }
        ],
    },

    {
        title: "Social",
        icons: [
            { name: "LinkedIn", icon: <FaLinkedinIn fontSize="large" />, link: "https://www.linkedin.com/company/onelogica/" },
            {
                name: "Twitter",
                icon: (
                    <img
                        src="../homepgimg/twittericonimg.png"
                        alt="Twitter"
                        style={{ width: "30px", filter: "invert(1)" }}
                    />
                ),
                link: "https://x.com/Onelogica2023"
            },
            { name: "YouTube", icon: <YouTube />, link: "https://www.youtube.com/channel/UCBo9n4HdMCJZ6VTWvPrTLqg" },
        ],
    },
];

const Footer = () => {
    return (
        <footer className="text-light py-5" style={{ background: "#030456" }}>
            <Container>
                {/* Top Section */}
                <Grid container spacing={4} className="border-bottom pb-4">
                    {/* Logo & Contact Info */}
                    <Grid item xs={12} md={4}>
                        <img src="../images/logo1.png" alt="Company Logo" className="company-logo" />
                        <Typography variant="subtitle1" className="text-white mb-2">
                            Contact Us
                        </Typography>
                        <Box display="flex" alignItems="start" gap={1} mt={2}>
                            <Email fontSize="small" />
                            <Typography variant="body2" style={{fontFamily:"poppins"}}>sales@onelogica.com</Typography>
                        </Box>
                        <Box display="flex" alignItems="start" gap={1} mt={2}>
                            <Phone fontSize="small" />
                            <Typography variant="body2" style={{fontFamily:"poppins"}}>+91-9953793025</Typography>
                        </Box>
                        <Box display="flex" alignItems="start" gap={1} mt={2}>
                            <LocationOn fontSize="small" />
                            <Typography variant="body2" style={{fontFamily:"poppins"}}>Hope Tower, 18th Floor, Unit No. TS-1926,
                                Plot No. C-03, Sec-04, Galaxy Blue Sapphire Mall, Greater Noida 201309</Typography>
                        </Box>
                    </Grid>

                    {/* Dynamic Sections from JSON */}
                    {footerSections.map((section, index) => (
                        <Grid item xs={6} sm={6} md={2} key={index}>
                            <Typography variant="subtitle1" className="fw-semibold text-white mb-2">
                                {section.title}
                            </Typography>
                            <ul className="list-unstyled" style={{ padding: 0, margin: 0 }}>
                                {section.links?.map((link, idx) => (
                                    <li key={idx} style={{ marginBottom: "5px" }}>
                                        <Link to={link.path} className="text-light" style={{fontFamily:"poppins" }}>
                                            {link.name}
                                        </Link>
                                    </li>
                                ))}
                                {section.icons && (
                                    <div className="d-flex gap-3">
                                        {section.icons.map((social, idx) => (
                                            <a
                                                key={idx}
                                                href={social.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-light"
                                                style={{ textDecoration: "none",fontFamily:"poppins" }}
                                            >
                                                {social.icon}
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </ul>
                        </Grid>
                    ))}
                </Grid>

                {/* Bottom Section */}
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className="text-muted pt-3"
                    spacing={2} // Adds spacing between items for better responsiveness
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        textAlign={{ xs: "center", md: "left" }} // Centers text on small screens
                    >
                        <Typography variant="body2" className="text-white" style={{ fontSize: "12px",fontFamily:"poppins" }}>
                            Copyright ©2024 All rights reserved by Onelogica
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        textAlign={{ xs: "center", md: "right" }} // Centers links on small screens
                    >
                        <Link
                            to="/terms"
                            className="me-3 text-light"
                            style={{ textDecoration: "none", fontSize: "12px",fontFamily:"poppins" }}
                        >
                            Terms & Conditions
                        </Link>
                        <Link
                            to="/privacy"
                            className="me-3 text-light"
                            style={{ textDecoration: "none", fontSize: "12px" ,fontFamily:"poppins"}}
                        >
                            Privacy
                        </Link>
                    </Grid>
                </Grid>

            </Container>
        </footer>
    );
};

export default Footer;
