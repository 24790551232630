import React from "react";
import { MdOutlinePlayLesson } from "react-icons/md";
import { FaUsers, FaStar, FaArrowRight } from "react-icons/fa";
import "./CourseCard.css";

const CourseCard = ({ course }) => {
  return (
    <div className="course-card">
      <div className="course-card__image">
        <img src={course.image || "/placeholder.svg"} alt={course.title} />
      </div>

      <div className="course-card__content">
        <div className="course-card__details">
          <span>
            <MdOutlinePlayLesson size={18} color="#1a1a3d" /> {course.lessons} Lessons
          </span>
          <span>
            <FaUsers size={18} color="#1a1a3d" /> {course.students}+ Students
          </span>
        </div>

        <h3 className="course-card__title">{course.title}</h3>

        <div className="course-card__ratings">
          <span>({course.ratingsCount} ratings)</span>
          <div className="stars">
            {[...Array(5)].map((_, i) => (
              <FaStar key={i} size={16} color={i < Math.round(course.ratingsCount) ? "#ffbb00" : "#ccc"} />
            ))}
          </div>
        </div>

        <div className="course-card__footer">
          <div className="course-card__instructor">
            <img src={course.instructorImage || "/placeholder.svg"} alt={course.instructor} />
            <span>{course.instructor}</span>
          </div>

          <button className="course-card__button">
            <FaArrowRight size={20} color="white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
