import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../../Footer/FooterSection';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from '@material-ui/core';
import { BsLightningCharge, BsShieldLock, BsGraphUp, BsBoxSeam } from "react-icons/bs";
import "../glam/glam.css";
import { Helmet } from 'react-helmet-async';
import { QuestionAnswer, Translate } from '@material-ui/icons';
import { Insights, Summarize } from '@mui/icons-material';
import VideoSection from '../common/VideoCOmponent';
import VachFeatures from './VachFeatures';
import BenefitVach from './BenefitVach';
import RequestButton from '../common/RequestButton';
import { FaQuoteLeft } from 'react-icons/fa';
import VideoShowcase from '../video/video-showcase';
import ContactSection from '../common/ContactSection';


const VachLandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Product | VACH</title>
                <meta name="description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="keywords" content="AI, analytics, document translation, summarization, insights, Q&A, GLAM" />
                <meta name="robots" content="index, follow" />
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="OneLogica Team" />

                {/* Open Graph */}
                <meta property="og:title" content="Product | VACH" />
                <meta property="og:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta property="og:image" content="URL_of_image.jpg" />
                <meta property="og:url" content="https://www.onelogica.com/product/vach" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="OneLogica" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Product | VACH" />
                <meta name="twitter:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="twitter:image" content="URL_of_image.jpg" />
                <meta name="twitter:site" content="@OneLogica" />

                <link rel="canonical" href="https://www.onelogica.com/product/vach" />
            </Helmet>

            {/* Hero Section */}
            <Container fluid className="hero-section   text-white  product-container" style={{
                // backgroundColor: "rgb(51 64 80)",
                // background: "#101c3d",
                backgroundImage: `url(../newimages/callcentervach.jpg)`,
                // filter: "brightness(75%)",
                backgroundSize: "cover",
                backgroundPosition: "left",
                backgroundRepeat: "no-repeat",
                marginTop: "3rem",
                height: "95vh"
                // background: "linear-gradient(45deg, rgb(47 61 77), rgb(53 73 95))", paddingBottom: "0px "
            }}>
                <Row className="align-items-center" >
                    <Col md={8} className="text-left p-5 " style={{ background: "rgb(255 255 255 / 27%)" }}>
                        <h6 className="display-5" style={{ fontWeight: "400", color: "rgb(255 255 255)"}}>Voice-based Analysis & Customer Heuristics(VACH)</h6>
                        {/* <h1 className="display-1" style={{ fontWeight: "700", color: "white" }}>Voice-based Analysis & Customer Heuristics</h1> */}
                        {/* <p className="lead">GLAM (Generative Language based Analytics and Maximization). It is a cutting-edge platform that leverages Azure's AI capabilities to transform how you interact with your documents. Whether you need translations, summaries, insights, or Q&A, GLAM has got you covered.</p> */}
                        <RequestButton href="/product/support">Request Demo</RequestButton>  
                    </Col>
                </Row>
            </Container>
            <Container fluid className="hero-section  text-white  product-container" style={{ background: "linear-gradient(45deg, #0b0b4c, #5a83b1)"}}>
                <Row style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                    <Col md={5} className="text-center" style={{ padding: "50px" }}>
                        <img
                            src="../newimages/vachsideimg.png"
                            alt="vach Illustration"
                            className="img-fluid"
                            style={{ borderRadius: "35px", textAlign:"center" }}
                        />
                    </Col>
                    <Col md={7} className="text-left p-5" >
                        {/* <h6 className="display-5" style={{ fontWeight: "400", color: " #d19e94" }}>Generative Language based Analytics and Maximization</h6> */}
                          <p className="lead" style={{ fontSize: "18px", }}><FaQuoteLeft style={{ fontSize: "3rem" }}></FaQuoteLeft><span style={{ fontSize: "2.5rem",marginLeft:"5px" }}></span>
                          VACH is an enterprise-grade AI-powered contact center solution designed to enhance customer satisfaction and optimize business operations. It ensures efficient workload management, multi-channel support, and real-time insights to improve service quality and response times.
                          .</p>
                    </Col>
                </Row>
            </Container>
            <VachFeatures />
            <BenefitVach />
                  <VideoShowcase title="Revolutionizing Customer Experience: VACH - AI-Powered Contact Center Solution" description="Discover how VACH (Voice-based Analysis & Customer Heuristics) is transforming customer service operations with its unified workforce optimization, advanced analytics, and AI-driven automation." videoUrl="https://www.youtube.com/watch?v=3eZbe9XyD0I"/>
            
            {/* About Section */}
            {/* <Container id="about" className="py-5 about-section bg-light m-5">
                <Row className="align-items-center">
                    <VideoSection />
                </Row>
            </Container> */}
            {/* CTA Section */}
             <ContactSection/>
            <Footer />
        </>
    );
};

export default VachLandingPage;
