import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import MyComponent from './components/About_Us/text';

// import PhoneSignin from './components/Career/PhoneAuth';
// import JobApplicationForm from './components/Career/ApplyJobForm';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />

    {/* <PhoneSignin/> */}
    {/* <JobApplicationForm/> */}
    {/* <MyComponent/> */}
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
