// /* eslint-disable jsx-a11y/alt-text */
// import React from "react";
// import "./Partners.css";

// function Partners() {
//   return (
//     <>
//       <div className="container">
//         <div className="content">
//           {/* <div className="header">Our Partners</div> */}

//           <div className="content">
//         <h1><span style={{color:"red", fontSize:"3.8rem"}}>Our</span> Partners</h1>
//         <p style={{fontSize:"20px", marginTop:"25px"}}>
//           While we are at the forefront of and specialize in design-build, we are very familiar with a number of delivery methods and are confident we can find the process that will best help you meet your goals.
//         </p>
//       </div>
//       <div className="images">

//       <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/8867.Microsoft_5F00_Logo_2D00_for_2D00_screen.jpg" alt="Microsoft" className="imagePartners"/>
     
//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/Databricks_Logo.png" alt="Databricks" className="imagePartners"/>
     
       
//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/image (18).png" alt="AWS" className="imagePartners"/>
       
//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/image (19).png" alt="DBT" className="imagePartners"/>

//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/aerospike.png" alt="Aerospike" className="imagePartners"/>

//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/Salesforce-Partner-Logo.png" alt="Salesforce" className="imagePartners"/>

//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/nvidia-inception-program-removebg-preview.png" alt="Nvidia" className="imagePartners"/>

//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/Snowflake.png" alt="Snoflake" className="imagePartners"/>

//         <img src="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/sih logo.png" alt="SIH" className="imagePartners"/>
     
//       </div>

//           {/* <div className="post">
//             <div className="post-wrapper">
//               <div className="post-image-wrapper">
//                 <img loading="lazy" srcSet="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/MS_Startups_Celebration_Badge_Light.png" className="post-image" />
//               </div>
//               <div className="post-content">
//                 <div className="post-details">
//                   <div className="post-title">Microsoft for Startups</div>
//                   <div className="post-excerpt">
//                   Proudly partnered with Microsoft for Startups, leveraging cutting-edge technology to drive innovation and growth.
//                   </div>
//                   <div className="post-meta">
//                     <div className="post-read-time">
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div> */}
//           {/* <div className="post-alt">
//             <div className="post-wrapper">
//               <div className="post-content-alt">
//                 <div className="post-details">
//                   <div className="post-title">NVIDIA Inception Program</div>
//                   <div className="post-excerpt">
//                   Additionally, honored to be part of the NVIDIA Inception Program, harnessing the power of AI and GPU technology for transformative solutions.
//                   </div>
//                   <div className="post-meta">
//                     <div className="post-read-time">
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="post-image-wrapper-alt">
//                 <img loading="lazy" srcSet="https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/1200.jpeg" className="post-image" />
//               </div>
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Partners;


import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Partners.css";

function Partners() {
  const images = [
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/8867.Microsoft_5F00_Logo_2D00_for_2D00_screen__1_-removebg-preview.png",
      alt: "Microsoft",
    },
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/Databricks_Logo__1_-removebg-preview.png",
      alt: "Databricks",
    },
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/image (18).png",
      alt: "AWS",
    },
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/image (19).png",
      alt: "DBT",
    },
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/aerospike.png",
      alt: "Aerospike",
    },
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/Salesforce-Partner-Logo.png",
      alt: "Salesforce",
    },
    // {
    //   src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/nvidia-inception-program-removebg-preview.png",
    //   alt: "Nvidia",
    // },
    {
      src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/Snowflake.png",
      alt: "Snowflake",
    },
    // {
    //   src: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/sih logo.png",
    //   alt: "SIH",
    // },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000, // Increase the speed for smoother transitions
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust the autoplay speed for smoother transitions
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="partnercontainer">
        <div className="content">
          <div className="content">
            <h2 className="section-title">
              {/* <span className="highlight">Our</span> */}
              Our Partners
            </h2>
            <p style={{ fontSize: "14px",textAlign:"center",color:"#000" }}>
              While we are at the forefront of and specialize in design-build,
              we are very familiar with a number of delivery methods and are
              confident we can find the process that will best help you meet
              your goals.
            </p>
          </div>
          <div className="carousel-container">
            <Slider {...settings}>
              {images.map((image, index) => (
                <div key={index} className="imgslider">
                  <img src={image.src} alt={image.alt} className="imagePartners" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Partners;