import React from "react";
import { Link } from "react-router-dom";
import { HiChevronUp } from "react-icons/hi";
import "./mobile-dropdown.css"; // Import the CSS

const MobileDropdown = ({ title, items, isOpen, onToggle }) => {
  const getLinkPath = (to) => (typeof to === "string" ? to : to.pathname);

  return (
    <div className="mobile-dropdown">
      <button onClick={onToggle} className="mobile-dropdown-button" aria-expanded={isOpen}>
        <span className="dropdown-title">{title}</span>
        <HiChevronUp className={`chevron-icon ${isOpen ? "rotate-0" : "rotate-180"}`} />
      </button>

      <div className={`mobile-dropdown-content ${isOpen ? "open" : ""}`}>
        <div className="mobile-dropdown-grid">
          {items.map((item, index) => (
            <div key={index} className="mobile-dropdown-item">
              <Link to={getLinkPath(item.to)} className="mobile-dropdown-item-title">
                {item.title}
              </Link>
              {item.content && (
                <div className="mobile-dropdown-item-content">
                  {item.content.map((subItem, subIndex) => (
                    <Link key={subIndex} to={subItem.href} className="mobile-dropdown-item-link">
                      {subItem.text}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileDropdown;