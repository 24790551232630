import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const TechStackCard = ({ techStacks, image }) => {
  const [expanded, setExpanded] = useState(); // First accordion open by default

  const handleChange = (index) => (_, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  return (
    <Grid container spacing={4} marginBottom="30px">
      {/* Header and One-liner (Row 1) */}
      <Grid item xs={12} display="flex" flexDirection="column" alignItems="center">
      {/* <Grid item xs={12} md={12} display="flex"  alignItems="center"> */}
        <Typography variant="h2" fontWeight="600" marginBottom="5px" fontSize='2rem'>
          Our Tech Stack
        </Typography>
        {/* </Grid> */}
        {/* <Grid item xs={12} md={12}> */}
        <p variant="body1" color="text.secondary" marginBottom="12px" style={{padding:"10px 80px",textAlign:"center",fontSize:"15px"}}>
        We use modern and scalable technologies to build robust applications. 
        Explore our technology stack below to see how we create seamless solutions.
        </p>
        {/* </Grid> */}
      </Grid>

      {/* Accordion and Image (Row 2) */}
      <Grid item xs={12} container spacing={5} alignItems="center">
        {/* Left Side - Accordion */}
        <Grid item xs={12} md={7}>
          {techStacks.map((stack, index) => (
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
              sx={{
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                mb: 1.5,
                border: "none",
                boxShadow: expanded === index ? "0px 4px 10px rgba(0, 120, 237, 0.15)" : "none",
                transition: "0.3s ease-in-out",
                "&:hover": { boxShadow: "0px 4px 10px rgba(0, 120, 237, 0.2)" },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: expanded === index ? "white" : "black" }} />}
                sx={{
                  background: expanded === index ? "#0078ED" : "#0078ED",
                  borderRadius: "12px",
                  margin:"0px",
                  color: expanded === index ? "#fff" : "#333",
                  transition: "0.3s ease",
                  fontWeight: 600,
                  "&:hover": { backgroundColor: expanded === index ? "#0066CC" : "#0078ED" },
                }}
              >
                <Typography variant="h6" style={{ color: "white" }}>{stack.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {stack.points.map((point, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={point} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>

        {/* Right Side - Image */}
        <Grid item xs={12} md={5} display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
             padding:"0px 20px",
              borderRadius: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: "750px",
              height: "40vh", // Added height
            }}
          >
            <img
              src={image}
              alt="Tech Stack"
              style={{
                width: "100%",
                height: "100%", // Ensures it fills the box
                objectFit: "cover", // Prevents distortion
                borderRadius: "12px",
              }}
            />
          </Box>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default TechStackCard;
