"use client";

import { useState, useEffect } from "react";
import "./testimonial-landing.css";

// Dummy testimonial data
const testimonials = [
  {
    id: 1,
    quote:
      "Onelogica made our transition from Freshworks to Salesforce Sales Cloud seamless and efficient. Their expertise ensured a smooth migration while guiding us through the adoption journey. With their support, we unlocked the full potential of Salesforce, enhancing our sales operations effortlessly.",
    name: "Pankaj",
    title: "Founder & CEO - Phalcami",
    avatar:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
  {
    id: 2,
    quote:
      "Onelogica has been instrumental in our cloud adoption and AI roadmap development. Their expertise helped us navigate the cloud journey seamlessly while leveraging AI for process automation and resource optimisation. They truly function as an extended arm of our team, delivering quality solutions with complete transparency.",
    name: "Abhishek",
    title: "Founder & CEO - ITMC System",
    avatar:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
  {
    id: 3,
    quote:
      "Onelogica transformed our customer marksheet using cutting-edge AI technology within an incredibly tight timeline—something even leading companies couldn’t achieve. Their dedication, problem-solving approach, and passion for innovation are truly impressive. Onelogica’s methodology and execution set a new benchmark for excellence.",
    name: "IT head of a Leading Tech Company",
    title: "Leading IT company",
    avatar:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
  {
    id: 4,
    quote:
      "Onelogica delivered a fully customized solution that significantly enhanced both customer and internal user experiences. Their AI and cloud-powered approach streamlined our processes, enabling faster payments and real-time access to critical information. We’re impressed by their problem-solving mindset, adaptability, and dynamic team that goes above and beyond to drive innovation.",
    name: "Automobile Company Leadership Team",
    title: "Leading Automobile Company",
    avatar:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
]

export default function TestimonialLanding() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);

  useEffect(() => {
    const updateVisibleCount = () => {
      if (window.innerWidth < 600) {
        setVisibleCount(1);
      } else if (window.innerWidth < 1024) {
        setVisibleCount(2);
      } else {
        setVisibleCount(3);
      }
    };
    updateVisibleCount();
    window.addEventListener("resize", updateVisibleCount);
    return () => window.removeEventListener("resize", updateVisibleCount);
  }, []);

  const maxIndex = testimonials.length - visibleCount;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex < maxIndex ? prevIndex + 1 : 0));
    }, 3000); // Auto-scroll every 3 seconds

    return () => clearInterval(interval);
  }, [maxIndex]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : maxIndex));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < maxIndex ? prevIndex + 1 : 0));
  };
  return (
    <div className="testimonial-landing">
      <div className="testimonial-landing__header">
        <h2>What Our Clients Say</h2>
        <p>
  "Working with this team has been an absolute pleasure! Their dedication, expertise, and attention to detail transformed our business. We highly recommend their services for anyone looking for real impact!"
</p>

      </div>

      <div className="testimonial-landing__carousel-container">
        <div
          className="testimonial-landing__carousel-slider"
          style={{
            transform: `translateX(-${(currentIndex * 100) / visibleCount}%)`,
          }}
        >
          {testimonials.map((testimonial) => (
            <div className="testimonial-landing__card" key={testimonial.id}>
              <div className="testimonial-landing__quote-icon">"</div>
              <p className="testimonial-landing__text">{testimonial.quote}</p>
              <div className="testimonial-landing__author">
                {/* <img
                  src={testimonial.avatar || "/placeholder.svg"}
                  alt={`${testimonial.name}'s avatar`}
                  className="testimonial-landing__avatar"
                /> */}
                <div className="testimonial-landing__author-info">
                  <h4>{testimonial.name}</h4>
                  <p>{testimonial.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="category-section__nav">
          <button
            className="category-section__nav-arrow category-section__nav-arrow--left"
            // onClick={scrollLeft}
            onClick={handlePrev}
            disabled={currentIndex === 0}
          >
            ←
          </button>
          <button
            className="category-section__nav-arrow category-section__nav-arrow--right"
            // onClick={scrollRight}
            onClick={handleNext}
            disabled={currentIndex === maxIndex}
          >
            →
          </button>
        </div>
      </div>
   
      {/* <div className="testimonial-landing__navigation">
        <button
          className={`testimonial-landing__nav-button prev ${
            currentIndex === 0 ? "disabled" : ""
          }`}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          &lt;
        </button>
        <button
          className={`testimonial-landing__nav-button next ${
            currentIndex === maxIndex ? "disabled" : ""
          }`}
          onClick={handleNext}
          disabled={currentIndex === maxIndex}
        >
          &gt;
        </button>
      </div> */}
    </div>
  )
}
