export const partnersData = {
    salesforce: {
      name: "Salesforce CRM Implementation & Modernization",
      img:"/partner/partnersalesforce.jpg",
      sideimg:"/partner/salesforce.png",
      oneLiner:
        "Empowering enterprises with AI-Driven Salesforce CRM to optimize sales, service, and customer engagement.",
      intro:"As a trusted Salesforce Partner, we specialize in delivering AI-driven, cloud-based CRM solutions that transform businesses. Our expertise in Salesforce implementation, automation, and custom integrations helps organizations enhance customer engagement, optimize operations, and accelerate growth",   
     techStacks: [
        {
          title: "CRM Implementation",
          points: ["Salesforce Sales Cloud", "Service Cloud", "Data Cloud"],
        },
        {
          title: "Industry-Specific Clouds",
          points: [
            "Health Cloud",
            "Education Cloud",
            "Financial Services Cloud",
          ],
        },
        {
          title: "AI & Automation",
          points: ["Einstein AI", "AI-Powered Chatbots", "Generative AI Copilots"],
        },
        {
          title: "DevOps & Cloud",
          points: [
            "Salesforce DevOps (Gearset, Copado)",
            "Multi-Cloud Integrations",
          ],
        },
        {
          title: "Migrations & Modernization",
          points: [
            "Legacy CRM to Salesforce",
            "AI-Enhanced CX Strategy",
          ],
        },
      ],
      industryFocus: [
        {
          title: "Sales & Customer Service",
          points: [
            "AI-powered sales automation",
            "Lead-to-revenue acceleration",
          ],
        },
        {
          title: "Salesforce Service Cloud",
          points: [
            "Intelligent case management & AI-powered customer support",
            "AI-driven student engagement, learning analytics",
          ],
        },
        {
          title: "Salesforce Data Cloud",
          points: [
            "Unified 360° customer insights with real-time data analytics",
          ],
        },
        {
          title: "Salesforce Health Cloud",
          points: [
            " Personalized patient care",
            "AI-driven clinical workflows"
          ],
        },
        {
          title: " Salesforce Education Cloud ",
          points: [
            "AI-driven student engagement",
            "learning analytics "
          ],
        },
        {
          title: "Salesforce Financial Services Cloud ",
          points: [
            "AI-enhanced wealth management & risk analytics ",
          ],
        },
      ],
      edgePoints: [
        {
            title:"Certified Salesforce Consultants ",
            points:[
                "Boost efficiency & revenue growth "
            ]
        },
        {
            title:"AI-Driven Sales & Service Optimization",
            points:[
                "ailored solutions for BFSI, Healthcare, EdTech & more",
            ]
        },
        {
            title:"Pre-Built Accelerators for AI-Enhanced CRM ",
            points:[
                "Fast-track digital transformation  "
            ]
        },
        
      ],

   
    product: [
        // {
        //   name: "AI-Driven Salesforce CRM",
        //   image:"",
        //   description:"",
        //   brochure: "fabric-brochure-url",
        //   points: ["Boost efficiency & revenue growth", "Pre-Built Accelerators for AI-Enhanced CRM"],
        // },
        // {
        //   name: "AI-Driven Salesforce CRM",
        //   image:"",
        //   description:"",
        //   brochure: "fabric-brochure-url",
        //   // points: ["Boost efficiency & revenue growth", "Pre-Built Accelerators for AI-Enhanced CRM"],
        // },
      ],
    },
    microsoft: {
      name: "Microsoft (CSP & ISV Partner)",
      img:"/partner/microsoft.png",
      sideimg:"/partner/azure.png",
      oneLiner:
        "Transforming businesses with Azure cloud, AI, and DevOps solutions.",
        intro:"As a Microsoft Partner, we specialize in modern workplace solutions, Azure cloud services, and enterprise app development. Our expertise spans Azure, Microsoft 365, Power Platform, and AI-driven business applications. Azure Cloud & AI Services, Microsoft 365 & Power Platform Solutions, Custom App Development & Integration",
      techStacks: [
        { title: "Data Engineering", points: ["Azure Data Factory", "Synapse", "Power BI"] },
        { title: "Data Science & AI", points: ["Azure ML", "Azure OpenAI", "Cognitive Services"] },
        { title: "Generative AI", points: ["Custom GPT", "Copilots", "RAG (Retrieval-Augmented Generation)"] },
        { title: "DevOps & Cloud", points: ["Azure DevOps", "Terraform", "AKS"] },
      ],
      industryFocus: [
        {
          title: " Azure AI & ML ",
          points: [
            "AI-powered automation & predictive analytics",
          ],
        },
        {
          title: "AI-Powered CRM & ERP",
          points: [
            "AI-enhanced Dynamics 365 integrations",
          ],
        },
        {
          title: "Azure Data Lakehouse",
          points: [
            " End-to-end ETL/ELT, Data Governance, and Compliance",
          ],
        },
        {
          title: "Azure DevOps Transformation ",
          points: [
            "CI/CD automation & cloud-native solutions ",
          ],
        },
        {
          title: "Cloud Migration & FinOps ",
          points: [
            "Cost-optimized & secure cloud transitions ",
          ],
        },
      ],
      product: [
        { title: "GLAM", points: ["(Generative Language-based Analysis & Maximization)","AI-powered insights & smart search"],image:"/partner/glam.png" ,link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.glam_1?tab=Overview"},
        { title: "EAS", points: ["(Enterprise Analytics Suite)","AI-driven analytics across enterprise functions"],image:"/partner/eas.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.v-1?tab=Overview" },
        { title: "Azure AI Copilot", points: ["Domain-specific copilots for enhanced productivity"] ,image:"/partner/copilot.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.doot_1?tab=Overview"},
      ],
    },
    aws: {
      name: "AWS",
      img:"/partner/aws.jpeg",
      sideimg:"/partner/aws.png",
      oneLiner: "Optimizing cloud performance with AWS AI, DevOps, and security.",
      intro:"Leverage the power of the cloud with our AWS-certified solutions. From cloud migration to DevOps, AI/ML, and security, we help businesses scale efficiently and cost-effectively on AWS.Cloud Infrastructure & Security, AWS Lambda, EC2, S3, RDS, and more,AI/ML & Big Data Analytics on AWS",
      techStacks: [
        { title: "Data Engineering", points: ["AWS Glue", "Redshift", "Kinesis", "Lambda"] },
        { title: "Data Science & AI", points: ["AWS SageMaker", "Bedrock", "AI/ML Pipelines"] },
        { title: "Generative AI", points: ["Fine-tuned LLMs", "RAG using Amazon Kendra"] },
        { title: "DevOps & Cloud", points: ["AWS DevOps", "Terraform"] },
      ],
      industryFocus: [
        {
          title: "AWS AI & ML Adoption ",
          points: [
            "Custom LLMs, AI search, Intelligent automatio",
          ],
        },
        {
          title: " AWS Lakehouse Architecture",
          points: [
            "Modernizing data lakes & AI pipelines",
          ],
        },
        {
          title: " AI-Powered Customer 360 ",
          points: [
            "Personalization using AI & Amazon Personalize",
          ],
        },
        {
          title:"AWS DevSecOps & Cloud Governance",
          points:["Secure & automated cloud transformation "]
        }
      ],
      product: [
        { title: "AI-Powered AWS Copilot", points: ["Generative AI insights & automation"],image:"/partner/3.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.doot_1?tab=Overview"},
        { title: "AWS DataOps Suite", points: ["Scalable data pipeline & governance solutions"],image:"/partner/2.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.doot_1?tab=Overview" },
        { title: "Hybrid Cloud Migration Framework", points: ["Accelerated cloud adoption strategy"],image:"/partner/1.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.doot_1?tab=Overview" },
      ],
    },
    databricks: {
      name: "Databricks AI & Analytics",
      img:"/partner/databricks.jpg",
      sideimg:"/partner/databricks.png",
      oneLiner: "Revolutionizing data science and AI with Databricks.",
      intro:"Unlock the power of data with Databricks’ unified data analytics platform. We enable businesses to accelerate their data-driven transformation through AI-powered insights, real-time analytics, and scalable data solutions. Big Data Processing & AI/ML,Real-Time Analytics & Data Lakehouse Architecture,Seamless Integration with AWS, Azure & Google Cloud",
      techStacks: [
        { title: "Data Engineering", points: ["Delta Lake", "Apache Spark", "MLflow"] },
        { title: "Data Science & AI", points: ["Databricks Machine Learning", "Feature Store"] },
        { title: "Generative AI", points: ["RAG for Enterprise Search", "LLMOps"] },
        { title: "DevOps & Cloud", points: ["Databricks CI/CD", "ML Model Ops"] },
        {
          title: "Migrations & Implementations",
          points: [
            " On-Prem to Databricks","Lakehouse Migration",
          ],
        },
      ],
      industryFocus: [
        {
          title: "Unified Lakehouse Modernization ",
          points: [
            "Enterprise-wide data lake migration ",
          ],
        },
        {
          title: "Real-Time AI & ML",
          points: [
            " MLflow for model tracking & deployment ",
          ],
        },
        {
          title: "AI-Augmented BI & Decision Intelligence",
          points: [
            "AI-powered dashboards",
          ],
        },
        {
          title: "Data Governance & Security",
          points: [
            "Unity Catalog, RBAC for compliance ",
          ],
        },
        {
          title:" Databricks FinOps",
          points:["Federated governance & real-time data sharing "]
        }
      ],
      product: [
        { title: "Databricks AI Copilot", points: ["AI-assisted data query & insights automation"],image:"/partner/databrickai.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.version-1?tab=Overview" },
        { title: "Lakehouse Accelerator", points: ["Faster data pipeline & AI adoption on Databricks"],image:"/partner/LakehouseAccelerator.png",link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.solar-1?tab=Overview" },
        { title: "Intelligent Data Mesh", points: ["Federated governance & real-time data sharing"],image:"/partner/IntelligentDataMesh.jpg" ,link:"https://azuremarketplace.microsoft.com/en-us/marketplace/apps/factorialaiprivatelimited1685419647158.solar-1?tab=Overview"},
      ],
    },
  };
  