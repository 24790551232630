import React from 'react'
import Categery from './Categery'

import CoursesSection from './CoursesSection';
import TrainingSection  from './TrainingSection'
import HeroSection from './HeroLanding';
import Footer from '../../Footer/FooterSection';
import Partners from '../../HomePage/Partners';
import FeaturesSection from './FeaturesSection';
import TestimonialCarousel from './TestimonialLanding'

function Main() {
  return (
    <div>
<TrainingSection />
<Categery />
<HeroSection />
{/* <CoursesSection /> */}
<FeaturesSection />
<TestimonialCarousel />
<Partners />
<Footer />
          
    </div>
  )
}

export default Main