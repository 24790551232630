// import { Box, Typography } from "@mui/material";



// const SectionHeading = ({ title }) => {
//   return (
//     <Box mb={2}>
//       <Typography
//         variant="h5"
//         fontWeight="bold"
//         textAlign="center"
//         sx={{ position: "relative", display: "inline-block" }}
//       >
//         {title}
//         <Box
//           sx={{
//             width: "100%",
//             height: "4px",
//             background: "linear-gradient(45deg ,#0078ED, #030456)",
//             position: "absolute",
//             bottom: "-6px",
//             left: 0,
//           }}
//         />
//       </Typography>
//     </Box>
//   );
// };

// export default SectionHeading;
