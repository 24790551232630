import { Container, Row, Col, Card } from "react-bootstrap";
import ChurnPrediction from "../../../assest/icons/chrunpredection.png";
import revenueGrowth from "../../../assest/icons/revenueGrowth.png";
import inventoryManagement from "../../../assest/icons/inventory-management.png";
import laborOptimization from "../../../assest/icons/laborOptimization.png";
import customerRetention from '../../../assest/icons/customerretention.png';
import recommendedEngine from '../../../assest/icons/recommendationengine.png';
import './supplychain.css'

const featuresData = [
  {
    title: "Revenue Tracking",
    description: "Easily track revenue and gain insights using AI-powered analytics.",
    icon: revenueGrowth,
  },
  {
    title: "Churn Prediction",
    description: "Predict and prevent customer churn using machine learning insights.",
    icon: ChurnPrediction,
  },
  {
    title: "Recommendation Engine",
    description: "Get personalized recommendations based on your data.",
    icon: recommendedEngine,
  },
  {
    title: "Inventory Management",
    description: "Optimize inventory and reduce waste with smart analytics.",
    icon: inventoryManagement,
  },
//   {
//     title: "Customer Retention Strategies",
//     description: "Improve customer retention with AI-driven strategies.",
//     icon: customerRetention,
//   },
//   {
//     title: "Customer Retention Strategies",
//     description: "Improve customer retention with AI-driven strategies.",
//     icon: customerRetention,
//   },
];

const FeaturesSection = () => {
  return (
    <Container id="features" className="p-5 supplyfeatures-section">
      <Row className="text-left mb-4">
        <Col lg={4} className="supplyfeaturesheader">
          <h2 className="supplysection-title">Empowering Your Workflow with Retail-Supply Chain</h2>
          <p className="supplysection-subtitle">
            Optimize, Predict, and Drive Profitability with AI-Powered Insights.
          </p>
        </Col>
     

      {/* Features Grid */}
      <Col lg={8}>
      <Row className="gx-4 gy-4">
        {featuresData.map((feature, index) => (
          <Col md={6} lg={6} key={index}>
            <Card className="shadow-sm featuresupplychain-card">
              <Card.Body className="d-flex align-items-start">
                <span className="feature-icon">
                  <img src={feature.icon} alt={feature.title} />
                </span>
                <div>
                  <Card.Title className="supplycardtitle">{feature.title}</Card.Title>
                  <Card.Text className="supplycardtext">{feature.description}</Card.Text>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturesSection;
