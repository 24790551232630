import React from "react";
import "./HeroSection.css"; 

const HeroSection = ({ title, subtitle, backgroundImage }) => {
  return (
    <section
      className="partnerhero-section"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="partnerhero-content">
        <h2>
          <span className="highlight">{title.split(" ")[0]}</span> {title.split(" ").slice(1).join(" ")}
        </h2>
        <p>{subtitle}</p>
      </div>
    </section>
  );
};

export default HeroSection;
