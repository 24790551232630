import { useState, useEffect } from "react";
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon, Grid, Card, CardContent } from "@mui/material";
import { motion } from "framer-motion";
import { AutoAwesome, Build, TrendingUp, ThumbUp } from "@mui/icons-material";

const steps = [
  { title: "Research & Strategy", icon: <AutoAwesome />, description: "We begin by comprehensively understanding your requirements and objectives.", image: "/industry/research.jpg" },
  { title: "Design & Prototype", icon: <Build />, description: "Creating visually appealing and functional prototypes before development.", image: "/industry/design.jpg" },
  { title: "Development & Testing", icon: <TrendingUp />, description: "Bringing ideas to life with robust development and thorough testing.", image: "/industry/development.jpg" },
  { title: "Deployment & Support", icon: <ThumbUp />, description: "Ensuring smooth deployment and ongoing support for continuous improvement.", image: "/industry/deployment.jpg" },
];

const HowWeWork = () => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ width: "100%", maxWidth: "1200px", margin: "auto", p: { xs: 2, md: 4 } }}>
      <Typography variant="h4" fontWeight="bold" textAlign="center" sx={{marginBottom:"4px"}}>
        How We Work
      </Typography>
      <Typography variant="body1" textAlign="center" mb={4} sx={{fontSize:"16px",color:'#000'}}>
        Our structured process ensures efficiency, innovation, and quality in every project.
      </Typography>
      
      <Grid container spacing={4} alignItems="start">
        <Grid item xs={12} md={4}>
          <List>
            {steps.map((step, index) => (
              <ListItem
                key={index}
                button
                onClick={() => setActiveStep(index)}
                sx={{
                  backgroundColor: activeStep === index ? "#0078ED" : "transparent",
                  color: activeStep === index ? "white" : "#000",
                  borderRadius: "8px",
                  transition: "background 0.3s",
                  mb: 1,
                  '&:hover': { backgroundColor: "#0078ED", color: "white" },
                }}
              >
                <ListItemIcon sx={{ color: activeStep === index ? "white" : "#0078ED" ,fontSize:"18px"}}>
                  {step.icon}
                </ListItemIcon>
                <ListItemText primary={step.title} primaryTypographyProps={{ fontWeight:600,fontSize:"1.3rem" }} />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ borderRadius: "12px", boxShadow:"none",background:"#f0f6f7" ,p: 3, textAlign: "left" }}>
            <motion.img
              key={steps[activeStep].image}
              src={steps[activeStep].image}
              alt={steps[activeStep].title}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              style={{ width: "100%", height: "300px", objectFit: "cover", borderRadius: "12px" }}
            />
            <CardContent>
              <Typography variant="h5" fontWeight="bold" sx={{ color: "#0078ED", mb: 1 }}>
                {steps[activeStep].title}
              </Typography>
              <Typography variant="body1" sx={{ color: "#333" }}>
                {steps[activeStep].description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HowWeWork;