import React from "react";
import { useParams } from "react-router-dom";
import { partnersData } from "./PartnerData";
import TechStackCard from "./TechStackCard";
import "./partnership.css";
import ProductLayout from "./ProductLayout";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../Footer/FooterSection";
import RequestButton from "../ProductandSolution/common/RequestButton";
import HeroSection from "./HeroSection";
import ExpertiseCarousel from "./ExpertiseCarousel";
import ContactSection from "../ProductandSolution/common/ContactSection";


const PartnerPage = () => {
  const { partnerId } = useParams();
  const partner = partnersData[partnerId];

  if (!partner) {
    return <h2 className="text-center">Partner not found</h2>;
  }

  return (
    <div className="partner-container">
      <HeroSection
        title={partner.name}
        subtitle={partner.oneLiner}
        backgroundImage={partner.img}
      />
      {/* <section className="hero-section" style={{ backgroundImage: `url(${partner.bgImage})` }}>
        <h1>{partner.name}</h1>
      </section> */}

      <section className="one-liner">
        <p>{partner.intro}</p>
      </section>

      <section className="tech-stack">
        {/* <h2 className="text-center">Tech Stacks</h2> */}
        <Container>
         
                <TechStackCard techStacks={partner.techStacks} image={partner.sideimg} />
            
        </Container>
      </section>
     
          
        <section className="expertiseSection" style={{ backgroundImage: "url(/partner/partnerbgimg.png)",backgroundRepeat:"no-repeat",backgroundPosition:"center",backgroundSize:"cover", padding: "20px" }}>
          <h2 className="text-center text-white" style={{fontSize:"1.75", fontWeight:"600" ,marginTop:"15px"}}> Our Expertise</h2>
         
              {/* <ExpertiseCarousel expertiseData={partner} /> */}
              <ExpertiseCarousel expertiseData={partner} />
           
            {/* {partner.industryFocus.map((stack, index) => (
              <Col key={index} xs={12} md={6} lg={4} className="d-flex align-items-stretch">
                <ExpertiseCarousel title={stack.title} points={stack.points} />
              </Col>
            ))} */}
         
        </section>

        {partner.product?.length > 0?<>
      <ProductLayout product={partner.product} />
      </>:("")}
      <ContactSection/>
      <Footer />
    </div>
  );
};

export default PartnerPage;
