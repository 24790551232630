import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./contactForm.css"
import PhoneInput from "react-phone-input-2";
import instance from "../../axios-config";

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name should not contain numbers or special characters")
    .min(3, "Name must be at least 3 characters")
    .max(50, "Name cannot exceed 50 characters")
    .required("Required"),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@gmail\.com$/, "Email must be a Gmail address")
    .required("Required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .test(
      "isValidMobile",
      "Invalid mobile number",
      (value) => {
        // Ensure value exists and is at least 10 digits (basic check)
        return value && value.replace(/\D/g, "").length >= 10;
      }
    ),
  company: Yup.string().required("Required"),
  time: Yup.date().required("Required").nullable(),
  heard: Yup.string().required("Required"),
});
;


const PopupForm = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSuccess(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          background: "linear-gradient(90deg, rgb(106, 17, 203), rgb(37, 117, 252))",
          textTransform: "capitalize",
          boxShadow: "none",
          borderRadius: "20px",
          color: "white",
          // padding: "15px",
          // fontWeight: "600",
          fontSize: "18px",
          letterSpacing: "1px",
          marginTop:"1.5rem"
        }}
        onClick={() => setOpen(true)}
      >
        Get in Touch
         {/* <ArrowForwardIcon fontSize="small" /> */}
      </Button>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "calc(100% - 20px)", // Adjust height automatically
            backdropFilter: "blur(10px)", // Blurred background for translucency
            backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white

          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Dark semi-transparent overlay
            backdropFilter: "blur(5px)", // Adds blur effect
          },
        }}
        open={open} onClose={handleClose}>
        {!success && <DialogTitle style={{ paddingBottom: "0px" }}>Contact Us</DialogTitle>}
        <DialogContent>
          <Box sx={{ width: 400, textAlign: "center" }}>
            {success ? (
              <Box sx={{ textAlign: "center", p: 2 }}>
                <CheckCircleIcon sx={{ fontSize: 50, color: "green" }} />
                <Typography variant="h6" sx={{ mt: 2 }}>
                  We appreciate your interest!
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Stay tuned — we’ll reach out soon.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  sx={{ mt: 2 }}
                >
                  Close
                </Button>
              </Box>
            ) : (
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  mobile: "",
                  company: "",
                  designation: "",
                  time: null,
                  heard: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                  setLoading(true);
                  try {
                    // const response = await instance.post("/submitclientdata-form", values);
                    const response = await fetch(
                      "https://onelogicawebsiteserver.azurewebsites.net/submitclientdata-form",
                      {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(values),
                      }
                    );

                    if (response.ok) {
                      setSuccess(true);
                      resetForm();
                    } else {
                      alert("Something went wrong! Please try again.");
                    }
                  } catch (error) {
                    console.error("Error:", error);
                    alert("Network error! Please check your connection.");
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                {({ handleChange, values, setFieldValue }) => (
                  <Form>
                    {[
                      { name: "name", label: "Your Name" },
                      { name: "email", label: "E-mail" },
                      { name: "company", label: "Company Name" },
                      { name: "designation", label: "Designation", required: false },
                    ].map(({ name, label, required = true }) => (
                      <div key={name}>
                        <TextField
                          fullWidth
                          size="small"
                          margin="normal"
                          name={name}
                          label={
                            <span>
                              {label}
                              {required && <span style={{ color: "red" }}> *</span>}
                            </span>
                          }
                          onChange={handleChange}
                          value={values[name]}
                        />
                        <ErrorMessage
                          name={name}
                          component="div"
                          style={{ color: "red", fontSize: "12px", textAlign: "left" }}
                        />
                      </div>
                    ))}

                    {/* Mobile Number with Country Code */}
                    <div style={{ textAlign: "left", marginTop: "16px", marginBottom: "10px" }} className="mobileInput">
                      {/* <label style={{ fontSize: "14px", fontWeight: "bold", display: "block" }}>
                        Mobile No. <span style={{ color: "red" }}>*</span>
                      </label> */}
                      <PhoneInput
                        country={"in"} // Default to India
                        value={values.mobile}
                        inputClass="form-control"
                        placeholder="Enter phone number"
                        enableSearch={true}
                        countryCodeEditable={false}
                        onChange={(phone) => setFieldValue("mobile", phone)}
                        inputStyle={{ width: "100%", height: "40px" }}
                      />
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        style={{ color: "red", fontSize: "12px", textAlign: "left" }}
                      />
                    </div>
                    {/* How Did You Hear About Us Field */}
                    <FormControl fullWidth margin="normal" size="small" id="demo-simple-select-label">
                      <InputLabel>How did you hear about us?</InputLabel>
                      <Select
                        className="custom-select"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="heard"
                        label="How did you hear about us?"
                        value={values.heard}
                        onChange={handleChange}
                        MenuProps={{
                          sx: {
                            "&& .MuiPopover-paper": {
                              backgroundColor: "#ffffffc2",
                            backdropFilter: "blur(10px)",
                            },
                          },
                        }}
                        sx={{
                          textAlign: "left",
                        
                          "& .MuiSelect-select": {
                            textAlign: "left",
                          },
                        }}
                      >
                        <MenuItem value="">Select an option</MenuItem>
                        <MenuItem value="Google">Google</MenuItem>
                        <MenuItem value="Social Media">Social Media</MenuItem>
                        <MenuItem value="Referral">Referral</MenuItem>
                        <MenuItem value="Advertisement">Advertisement</MenuItem>
                        <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                        <MenuItem value="Website">Wesite</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      name="heard"
                      component="div"
                      style={{ color: "red", fontSize: "12px", textAlign: "left" }}
                    />


                    {/* Preferred Time Field using react-datepicker */}
                    <div style={{ marginTop: "16px", textAlign: "left",background:"#0000000f" }}>
                      {/* <label style={{ fontSize: "14px", fontWeight: "bold", display: "block" }}>
                        Preferred time to call you back <span style={{ color: "red" }}>*</span>
                      </label> */}
                      <DatePicker
                        selected={values.time}
                        onChange={(date) => setFieldValue("time", date)}
                        showTimeSelect
                        dateFormat="Pp"
                        minDate={new Date()} // Prevent past date selection
                        className="date-picker"
                        placeholderText=" Preferred time to call you back "
                        style={{
                          width: "100%",
                          padding: "10px",
                          borderRadius: "5px",
                          border: "1px solid #b0afaf",
                          fontSize: "18px",
                          background: "red"
                        }}
                      />
                      <ErrorMessage
                        name="time"
                        component="div"
                        style={{ color: "red", fontSize: "12px", textAlign: "left" }}
                      />
                    </div>



                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      sx={{ mt: 2, background: "#0078ED" }}
                      disabled={loading}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </Form>
                )}
              </Formik>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PopupForm;
