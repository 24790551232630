import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../../Footer/FooterSection';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from '@material-ui/core';
import { BsLightningCharge, BsShieldLock, BsGraphUp, BsBoxSeam } from "react-icons/bs";
import "./glam.css";
import { Helmet } from 'react-helmet-async';
import { Done, QuestionAnswer, Translate } from '@material-ui/icons';
import { Insights, QueryStats, Summarize } from '@mui/icons-material';
import governencecomplance from "../../../assest/icons/governencecomplance.png";
import envmatrics from "../../../assest/icons/envmatrics.png";
import socialImpact from '../../../assest/icons/socialimpact.png';
import sustainReport from '../../../assest/icons/sustainreport.png';
import { FaQuoteLeft } from 'react-icons/fa';
import BenefitsCarousel from './Esgbenefits';
import RequestButton from '../common/RequestButton';
import ESGFeatures from './EsgFeature';
import VideoShowcase from '../video/video-showcase';
import ContactSection from '../common/ContactSection';

const EsgLandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Product | SupplyChain</title>
                <meta name="description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="keywords" content="AI, analytics, document translation, summarization, insights, Q&A, GLAM" />
                <meta name="robots" content="index, follow" />
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="OneLogica Team" />

                {/* Open Graph */}
                <meta property="og:title" content="Product | EAS" />
                <meta property="og:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta property="og:image" content="URL_of_image.jpg" />
                <meta property="og:url" content="https://www.onelogica.com/product/eas" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="OneLogica" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Product | SupplyChain" />
                <meta name="twitter:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="twitter:image" content="URL_of_image.jpg" />
                <meta name="twitter:site" content="@OneLogica" />

                <link rel="canonical" href="https://www.onelogica.com/product/supplychain" />
            </Helmet>

            {/* Hero Section */}
            <Container fluid className="hero-section text-white  product-container" style={{
                // backgroundColor: "rgb(51 64 80)",
                // background: "#101c3d",
                backgroundImage: `url(../industry/esgbg.png)`,
                // filter: "brightness(75%)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                marginTop: "3rem",
                height: "95vh"
                // background: "linear-gradient(45deg, rgb(47 61 77), rgb(53 73 95))", paddingBottom: "0px "
            }}>
                <Row className="align-items-center  text-left p-5">
                    <Col md={8} className="text-left p-5" >
                        <h6 className="display-5" style={{ fontWeight: "400", color: " white" }}>ESG</h6>
                        <h5 >Driving Growth and Compliance Through ESG Integration</h5>
                        <RequestButton href="/product/support">Request Demo</RequestButton>
                    </Col>

                </Row>
            </Container>
            <Container fluid className="hero-section  text-white  product-container" style={{ background: "linear-gradient(45deg, rgb(67 94 20), rgb(107 138 100))" }}>
                <Row>
                    <Col md={5} className="text-center" style={{ padding: "50px" }}>
                        <img
                            src="../newimages/esgimage.jpg"
                            alt="Glam Illustration"
                            className="img-fluid"
                            style={{ opacity: "0.8", borderRadius: "35px", width: "55vw", height: "55" }}
                        />
                    </Col>
                    <Col md={7} className="d-flex  text-left p-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                        {/* <h6 className="display-5" style={{ fontWeight: "400", color: " #d19e94" }}>Generative Language based Analytics and Maximization</h6> */}
                        <p className="lead" style={{ fontSize:"18px" }}><FaQuoteLeft style={{ fontSize: "3rem",marginRight:"5px" }}></FaQuoteLeft><span style={{ fontSize: "2.5rem" }}></span>Onelogica’s Environmental, Social, and Governance
                            (ESG) Solutions enable organizations to achieve
                            sustainability goals, enhance social impact, and
                            adhere to governance standards through actionable
                            data and analytics. Our tools empower businesses to
                            integrate ESG principles into their core strategies,
                            fostering growth while meeting regulatory and
                            stakeholder expectations.</p>
                    </Col>
                </Row>
            </Container>

            {/* Features Section */}
            <ESGFeatures />
            {/* Advantages Section */}
            <BenefitsCarousel />
            <VideoShowcase title="Achieve sustainability goals, enhance social impact, and ensure governance compliance with Onelogica" description="Onelogica’s Environmental, Social, and Governance (ESG) Solutions enable organizations to achieve sustainability goals, enhance social impact, and adhere to governance standards through actionable data and analytics. Our tools empower businesses to integrate ESG principles into their core strategies, fostering growth while meeting regulatory and stakeholder expectations." videoUrl="https://www.youtube.com/watch?v=UKUmjrZyPd4" />

            <ContactSection />

            <Footer />
        </>
    );
};

export default EsgLandingPage;
