// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expertise-carousel {
    padding: 1rem;
    text-align: center;
  }
  
  .slick-prev, .slick-next {
    color: #000; /* Change color of arrows */
    z-index: 10;
  }
  
  .slick-prev::before, .slick-next::before {
    font-size: 24px;
    color: #000;
  }
  

  .expertise-card {
    transition: transform 0.3s ease-in-out;
  }
  
  .expertise-card:hover {
    transform: translateY(-5px);
  }
  
  .expertise-list {
    flex-grow: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Partnership/expertise.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,WAAW,EAAE,2BAA2B;IACxC,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;;EAGA;IACE,sCAAsC;EACxC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,YAAY;EACd","sourcesContent":[".expertise-carousel {\r\n    padding: 1rem;\r\n    text-align: center;\r\n  }\r\n  \r\n  .slick-prev, .slick-next {\r\n    color: #000; /* Change color of arrows */\r\n    z-index: 10;\r\n  }\r\n  \r\n  .slick-prev::before, .slick-next::before {\r\n    font-size: 24px;\r\n    color: #000;\r\n  }\r\n  \r\n\r\n  .expertise-card {\r\n    transition: transform 0.3s ease-in-out;\r\n  }\r\n  \r\n  .expertise-card:hover {\r\n    transform: translateY(-5px);\r\n  }\r\n  \r\n  .expertise-list {\r\n    flex-grow: 1;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
