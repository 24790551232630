import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./smartSolution.css"; // Ensure the correct filename

const solutions = [
  { title: "Customer Support Automation", description: "Reduces support team overload by automating FAQs." },
  { title: "Business Process Streamlining", description: "Handles routine tasks, improving efficiency." },
  { title: "Data Retrieval Efficiency", description: "Accelerates access to critical data for customer support and decision-making." },
  { title: "Lead Engagement", description: "Engages and qualifies website visitors, providing sales insights." },
  { title: "Internal Support", description: "Facilitates HR tasks and improves employee productivity." },
];

const SmartSolutions = () => {
  return (
    <Container fluid className="py-5 smart-solutions-section">
      <Row className="justify-content-center align-items-center">
        {/* Left Section - Heading & Image */}
        <Col md={5} className="text-center p-3">
          <h3 className="fw-bold wow-heading">Smart Solutions for Every Need</h3>
          <img 
            src="../newimages/smartsolutionimg.jpeg" 
            className="img-fluid smart-solutions-img" 
            alt="Smart Solutions"
          />
        </Col>

        {/* Right Section - Cards */}
        <Col md={7}>
        <Row className="g-4 justify-content-center align-items-stretch equal-height p-3">
 {/* Ensure spacing between cards */}
            {solutions.map((solution, index) => (
              <Col md={4} key={index}> {/* Adjust column size for responsiveness */}
                <Card className="solution-card">
                  <div className="icon-container">
                    <FaCheckCircle className="solution-icon" />
                  </div>
                  <Card.Body>
                    <h5 className="fw-bold text-primary text-center">{solution.title}</h5>
                    <p className="text-light text-center" style={{fontSize:"14px"}}>{solution.description}</p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SmartSolutions;
