import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CustomNavbar.css';
import CallMadeIcon from '@material-ui/icons/CallMade';

const CustomNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showPartnershipsDropdown, setShowPartnershipsDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}
      variant="dark"
      fixed="top"
    >
      <Container fluid>
        <Navbar.Brand href="/" className="ms-auto">
          <Image src="../images/logo1.png" alt="Logo" className="logo-image" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* Products & Solutions Dropdown */}

            <NavDropdown
              title="Products"
              className="custom-dropdown"
            // show={showProductsDropdown}
            // onMouseEnter={() => setShowProductsDropdown(true)}
            // onMouseLeave={() => setShowProductsDropdown(false)}
            >
              <div className='product-intro'>
                We provide smart application using cloud and leading edge technologies
              </div>
              <div className="dropdown-fullwidth">
                <div className="dropdown-row">
                  {/* Individual Product Cards */}
                  <div className="product-card">
                    {/* <img src="https://via.placeholder.com/100" alt="Product 1" /> */}
                    <p className="product-card-title">
                      GLAM
                      <span ><CallMadeIcon className="redirect-arrow" fontSize='small'/></span>
                    </p>
                    <div className="product-card-content">
                      <a>Document Analysis tool</a>
                      <a>AI-powered styling solutions for modern businesses.</a>
                    </div>
                  </div>
                  <div className="product-card">
                    {/* <img src="https://via.placeholder.com/100" alt="Product 2" /> */}
                    <p className="product-card-title">Vach
                    <span ><CallMadeIcon className="redirect-arrow" fontSize='small'/></span>
                    </p>
                    <div className="product-card-content">
                      <a>Voice & calls</a>
                      <a>Real Time Reports and dashboards</a>
                    </div>
                  </div>
                  <div className="product-card">
                    {/* <img src="https://via.placeholder.com/100" alt="Product 3" /> */}
                    <p className="product-card-title">Eas
                    <span ><CallMadeIcon className="redirect-arrow" fontSize='small'/></span>
                    </p>
                    <div className="product-card-content">
                      <a>Streamline enterprise analytics with our robust solutions.</a>
                    </div>
                  </div>
                  <div className="product-card">
                    {/* <img src="https://via.placeholder.com/100" alt="Product 4" /> */}
                    <p className="product-card-title">Doot
                    <span ><CallMadeIcon className="redirect-arrow" fontSize='small'/></span>
                    </p>
                    <div className="product-card-content">
                      <a>Efficient collaboration and productivity tools for teams.</a>
                    </div>
                  </div>
                  <div className="product-card">
                    {/* <img src="https://via.placeholder.com/100" alt="Product 4" /> */}
                    <p className="product-card-title">Isam
                    <span ><CallMadeIcon className="redirect-arrow" fontSize='small'/></span>
                    </p>
                    <div className="product-card-content">
                      <a>Efficient collaboration and productivity tools for teams.</a>
                    </div>
                  </div>
                </div>
              </div>
            </NavDropdown>


            {/* Existing Navigation Links */}
            {/* <Nav.Link href="/productandsolutions" className="nav-link">
              Product & Solutions
            </Nav.Link> */}

            {/* Industry Dropdown */}
            <NavDropdown title="Industry" className="custom-dropdown">
              <NavDropdown.Item href="/bfsi" className="dropdown-item">
                BFSI
              </NavDropdown.Item>
              <NavDropdown.Item href="/energy" className="dropdown-item">
                Energy
              </NavDropdown.Item>
              <NavDropdown.Item href="/manufacturing" className="dropdown-item">
                Manufacturing
              </NavDropdown.Item>
              <NavDropdown.Item href="/retail" className="dropdown-item">
                Retail
              </NavDropdown.Item>
              <NavDropdown.Item href="/healthcare" className="dropdown-item">
                Health Care
              </NavDropdown.Item>
              <NavDropdown.Item href="/automotive" className="dropdown-item">
                Automotive
              </NavDropdown.Item>
              <NavDropdown.Item href="/telecommunications" className="dropdown-item">
                Telecommunications
              </NavDropdown.Item>
            </NavDropdown>

            {/* Services Dropdown */}
            <NavDropdown
              title="Services"
              className="custom-dropdown"
              show={showServicesDropdown}
              onMouseEnter={() => setShowServicesDropdown(true)}
              onMouseLeave={() => setShowServicesDropdown(false)}
            >
              <NavDropdown.Item href="/strategy" className="dropdown-item">
                Strategy & Advisory
              </NavDropdown.Item>
              <NavDropdown.Item href="/dataengineering" className="dropdown-item">
                Engineer Your Data
              </NavDropdown.Item>
              <NavDropdown.Item href="/ai" className="dropdown-item">
                Differentiate with AI/ML
              </NavDropdown.Item>
              <NavDropdown.Item href="/insights" className="dropdown-item">
                Operationalize Insights
              </NavDropdown.Item>
            </NavDropdown>

            {/* Partnerships Dropdown */}
            <NavDropdown
              title="Partnerships"
              className="custom-dropdown"
              show={showPartnershipsDropdown}
              onMouseEnter={() => setShowPartnershipsDropdown(true)}
              onMouseLeave={() => setShowPartnershipsDropdown(false)}
            >
              <NavDropdown.Item href="/microsoft" className="dropdown-item">
                Microsoft
              </NavDropdown.Item>
              <NavDropdown.Item href="/googlecloud" className="dropdown-item">
                Google Cloud
              </NavDropdown.Item>
              <NavDropdown.Item href="/aws" className="dropdown-item">
                AWS
              </NavDropdown.Item>
              <NavDropdown.Item href="/databricks" className="dropdown-item">
                Databricks
              </NavDropdown.Item>
              <NavDropdown.Item href="/snowflake" className="dropdown-item">
                Snowflake
              </NavDropdown.Item>
            </NavDropdown>

            {/* Additional Links */}
            <Nav.Link href="/resources" className="nav-link">Resources</Nav.Link>
            <Nav.Link href="/about" className="nav-link">About</Nav.Link>
            <Nav.Link href="/career" className="nav-link">Career</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
