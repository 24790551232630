// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomNavbar.css */

/* Styling the Navbar */
.custom-navbar {
  background-color: #101c3d; /* Change background color */
  padding: 10px 0; /* Adjust padding */
}

.ms-auto{
  margin: 0% !important;
}

/* Styling the Navbar Brand */
.navbar-brand img {
  width: 180px; /* Adjust logo image size */
}
.me-auto{
  margin-left: auto !important;
  margin-right: 10px !important;
}

.nav-link {
  color: #fff; /* Set the color of the links */
  padding: 10px 15px; /* Adjust the padding */
  transition: color 0.3s ease; /* Add a smooth color transition */
}

.nav-link:hover {
  color: #ff4000; /* Change the color on hover */
}

.custom-dropdown {
  color: #fff; /* Set the color of the dropdown */
}

.dropdown-item {
  color: #333; /* Set the color of dropdown items */
  transition: background-color 0.3s ease; /* Add a smooth background color transition */
}

.dropdown-item:hover {
  background-color: #eee; /* Change the background color on hover */
  color: #333; /* Change the text color on hover */
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/CustomNavbar.css"],"names":[],"mappings":"AAAA,qBAAqB;;AAErB,uBAAuB;AACvB;EACE,yBAAyB,EAAE,4BAA4B;EACvD,eAAe,EAAE,mBAAmB;AACtC;;AAEA;EACE,qBAAqB;AACvB;;AAEA,6BAA6B;AAC7B;EACE,YAAY,EAAE,2BAA2B;AAC3C;AACA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,WAAW,EAAE,+BAA+B;EAC5C,kBAAkB,EAAE,uBAAuB;EAC3C,2BAA2B,EAAE,kCAAkC;AACjE;;AAEA;EACE,cAAc,EAAE,8BAA8B;AAChD;;AAEA;EACE,WAAW,EAAE,kCAAkC;AACjD;;AAEA;EACE,WAAW,EAAE,oCAAoC;EACjD,sCAAsC,EAAE,6CAA6C;AACvF;;AAEA;EACE,sBAAsB,EAAE,yCAAyC;EACjE,WAAW,EAAE,mCAAmC;AAClD","sourcesContent":["/* CustomNavbar.css */\r\n\r\n/* Styling the Navbar */\r\n.custom-navbar {\r\n  background-color: #101c3d; /* Change background color */\r\n  padding: 10px 0; /* Adjust padding */\r\n}\r\n\r\n.ms-auto{\r\n  margin: 0% !important;\r\n}\r\n\r\n/* Styling the Navbar Brand */\r\n.navbar-brand img {\r\n  width: 180px; /* Adjust logo image size */\r\n}\r\n.me-auto{\r\n  margin-left: auto !important;\r\n  margin-right: 10px !important;\r\n}\r\n\r\n.nav-link {\r\n  color: #fff; /* Set the color of the links */\r\n  padding: 10px 15px; /* Adjust the padding */\r\n  transition: color 0.3s ease; /* Add a smooth color transition */\r\n}\r\n\r\n.nav-link:hover {\r\n  color: #ff4000; /* Change the color on hover */\r\n}\r\n\r\n.custom-dropdown {\r\n  color: #fff; /* Set the color of the dropdown */\r\n}\r\n\r\n.dropdown-item {\r\n  color: #333; /* Set the color of dropdown items */\r\n  transition: background-color 0.3s ease; /* Add a smooth background color transition */\r\n}\r\n\r\n.dropdown-item:hover {\r\n  background-color: #eee; /* Change the background color on hover */\r\n  color: #333; /* Change the text color on hover */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
