import React from 'react';
import IndustryPage from "./IndustryPage";
import {
  People, // Workforce Management
  SyncAlt, // Supply Chain Optimization
  Factory, // Production Optimization
  Settings, // Process Automation
  HealthAndSafety, // Safety Management
  BarChart, // Demand Forecasting
  Inventory, // Inventory Management
  LocalShipping, // Delivery Prediction
  Warehouse, // Warehouse Optimization
  Visibility, // Real-Time Monitoring
  Science, // Raw Material Optimization
  Bolt, // Energy Management
  TrendingUp, // Production Forecasting
  AutoGraph, // Process Optimization
} from "@mui/icons-material";


const Manufacturing = () => {
  const headingText = "Manufacturing";
  const introText = `"The global manufacturing industry, worth $39.5 trillion in 2022, is poised for growth fueled by increasing demand, technology advancements, and government initiatives. Challenges include supply chain disruptions, geopolitical risks, and changing consumer preferences"`;
  const paragraphText = `One significant impact on the manufacturing industry today is the Industry 4.0, which refers to the integration of advanced technologies such as the Internet of Things (IoT), artificial intelligence (AI), and automation into manufacturing processes. Industry 4.0 has revolutionized the way goods are produced, leading to increased efficiency, improved quality, and reduced costs. It has also enabled manufacturers to collect and analyze vast amounts of data to optimize processes and make better decisions. The COVID-19 pandemic has accelerated the adoption of Industry 4.0 technologies in the manufacturing industry, and this trend is expected to continue in the coming years.`;
  const backgroundImage = "../images/manufacturing_bg.jpg";
  const sectionImage = "../industry/manufacturing.jpg";
  const carouselImages = [
    "../industry/insdustryproductimg.jpg",
    "../industry/distributionandlogistics.jpg",
    "../industry/manufacturingprocess.jpg",
  ];

  const gridItems = [
    {
      title: "Industrial (Product)",
      description:
        "We help you Shift outlook, shift approach, shift mindsets, shift gears.",
      additionalImage: "../videos/Industrial_gif.gif",
      img:"../industry/insdustryproductimg.jpg",
      useCases: [
        { title: "Workforce Management", icon: <People /> },
        { title: "Supply Chain Optimization", icon: <SyncAlt /> },
        { title: "Production Optimization", icon: <Factory /> },
        { title: "Process Automation", icon: <Settings /> },
        { title: "Safety Management", icon: <HealthAndSafety /> },
      ],
    },
    {
      title: "Distribution & Logistics",
      description:
        "Build agile, digital supply chains for better growth and profitability.",
      additionalImage: "../videos/Distribution_gif.gif",
      img:"../industry/distributionandlogistics.jpg",
      useCases: [
        { title: "Demand Forecasting", icon: <BarChart /> },
        { title: "Inventory Management", icon: <Inventory /> },
        { title: "Delivery Prediction", icon: <LocalShipping /> },
        { title: "Warehouse Optimization", icon: <Warehouse /> },
        { title: "Real-Time Monitoring", icon: <Visibility /> },
      ],
    },
    {
      title: "Process",
      description:
        "AI development involves collecting, processing data to train models, deploying them, and monitoring in production.",
      additionalImage: "../videos/Process_gif.gif",
      img:"../industry/manufacturingprocess.jpg",
      useCases: [
        { title: "Raw Material Optimization", icon: <Science /> },
        { title: "Safety Management", icon: <HealthAndSafety /> },
        { title: "Production Forecasting", icon: <TrendingUp /> },
        { title: "Energy Management", icon: <Bolt /> },
        { title: "Process Optimization", icon: <AutoGraph /> },
      ],
    },
  ];
  

  return (
    <IndustryPage
      headingText={headingText}
      introText={introText}
      paragraphText={paragraphText}
      backgroundImage={backgroundImage}
      sectionImage={sectionImage}
      carouselImages={carouselImages}
      gridItems={gridItems}
    />
  );
};

export default Manufacturing;


