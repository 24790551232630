import React, { useEffect, useRef } from "react";
import { FaCheckCircle, FaQuoteRight } from "react-icons/fa";
import gsap from "gsap";
import "./vachFeatures.css";
import { Col, Row, Container } from "react-bootstrap";
import { BsQuote } from "react-icons/bs";

const features = [
  "AI-Powered Automation",
  "Seamless Multi-Channel Integration",
  "Advanced Analytics & Insights",
  "Customizable User Interface",
  "Smart Call Routing",
  "Enhanced Customer Engagement",
  "Real-time Reporting & Metrics",
  "24/7 Virtual Assistance",
];

const VachFeatures = () => {
  const featureRefs = useRef([]);

  useEffect(() => {
    gsap.fromTo(
      featureRefs.current,
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, duration: 1, stagger: 0.2, ease: "power3.out" }
    );
  }, []);

  return (
    <div className="vach-container">
      <Container>
        <h2>Why Choose <span>VACH</span>?</h2>
        <Row className="vach-content">
          <Col xs={12} md={6} className="text-section">
            <ul className="features-list">
              {features.map((feature, index) => (
                <li key={index} ref={(el) => (featureRefs.current[index] = el)}>
                  <FaCheckCircle className="iconvachfeature" /> {feature}
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={12} md={6} className="image-section">
            <img
              src="../newimages/vachimage.jpg"
              alt="VACH Features"
            />
            <h3>
              <BsQuote style={{ color: "red" }} />
              A comprehensive contact center solution includes a variety of features designed to optimize performance and deliver exceptional customer service
              <FaQuoteRight style={{ color: "red", marginLeft: "5px" }} fontSize="large" />
            </h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VachFeatures;
