import React from "react";
import {
  FaClock, FaCheckCircle, FaUsers, FaPiggyBank,
  FaEye, FaKey, FaCalendarCheck, FaCog, FaCommentDots,
  FaChartLine, FaLayerGroup
} from "react-icons/fa";
import "./benefitvach.css";

const benefits = [
  { icon: <FaCog />, title: "Increased Efficiency" },
  { icon: <FaCheckCircle />, title: "Improved Customer Satisfaction" },
  { icon: <FaPiggyBank />, title: "Cost Savings" },
  { icon: <FaUsers />, title: "Employee Empowerment" },
  { icon: <FaKey />, title: "Data Retention & Accessibility" },
  { icon: <FaEye />, title: "Proactive Issue Management" },
  { icon: <FaCalendarCheck />, title: "Regulatory Compliance" },
  { icon: <FaClock />, title: "Enhanced Employee Productivity" },
];

const valueAdditions = [
  { icon: <FaEye />, title: "Proactive Issue Management & Reduced Customer Wait Time" },
  { icon: <FaCommentDots />, title: "Enhanced Customer Insight" },
  { icon: <FaClock />, title: "Improved Operational Efficiency" },
  { icon: <FaUsers />, title: "Employee Empowerment" },
  // { icon: <FaLayerGroup />, title: "Scalability and Flexibility" },
];

const BenefitVach = () => {
  return (
    <div className="containervachbenifit" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
     
       {/* Value Add Section */}
       <section className="value-add">
        <div className="value-add-container">
          {/* Left Side - Title */}
          <div className="value-add-text">
            <h2>Value <span style={{color:"#9333ea"}}>Add</span></h2>
            <p>Discover the unique benefits that set us apart and provide unmatched value.</p>
          </div>

          {/* Right Side - Cards */}
          <div className="value-add-cards">
            {valueAdditions.map((item, index) => (
              <div key={index} className="card">
                <div className="iconvachvalueadd">{item.icon}</div>
                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Benefits Section */}
      <section className="benefitsvach">
        <h2 style={{fontWeight:600,fontSize:"1.75rem",marginBottom:"30px"}}><span> Our Benefits </span></h2>
        <div className="grid">
          {benefits.map((item, index) => (
            <div key={index} className="card">
              <div className="iconvach">{item.icon}</div>
              <p>{item.title}</p>
            </div>
          ))}
        </div>
      </section>

    

    </div>
  );
};

export default BenefitVach;
