"use client"

import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  FaArrowLeft,
  FaBuilding,
  FaCalendarAlt,
  FaClock,
  FaChartBar,
  FaCheck,
  FaImage,
} from "react-icons/fa";
import "./blogDetails.css";
import PopupForm from "../../HomePage/ContactForm";
import Footer from "../../Footer/FooterSection";
import RequestButton from "../../ProductandSolution/common/RequestButton";

// Data array with dynamic slug fields
const blogPosts = [
  {
    id: 1,
    title: "Data Analytics for Retail Growth",
    client: "Retail Corporation",
    duration: "8 months",
    date: "March 2024",
    impact: "30% increase in sales",
    description:
      "This case study explores how AI-powered analytics helps retailers gain deeper customer insights by unifying data sources across their organization. By leveraging Microsoft data solutions, businesses improved customer engagement, optimized store operations, and enhanced security, leading to increased sales and customer retention.",
    image:
      "https://www.institutedata.com/wp-content/uploads/2023/08/Data-privacy-and-security-concerns.png",
    challenge:
      "Retailers struggle with data fragmentation, ineffective marketing campaigns, and poor inventory management, leading to missed sales opportunities and customer dissatisfaction.",
    solution:
      "We implemented AI-powered analytics solutions that integrate real-time data, improve inventory forecasting, and personalize customer interactions, resulting in increased customer satisfaction and revenue.",
    results: [
      "30% increase in overall sales",
      "20% reduction in inventory costs",
      "Enhanced customer engagement with personalized offers",
    ],
    testimonial: {
      quote:
        "The analytics solutions transformed our business operations, allowing us to make data-driven decisions that significantly boosted sales and customer engagement.",
      author: "John Doe",
      position: "CEO, Retail Corporation",
    },
    technologies: [
      "AI Analytics",
      "Big Data",
      "Machine Learning",
      "Azure Cloud",
    ],
    slug: "data-analytics-retail-growth",
  },
  {
    id: 2,
    title: "Revolutionizing Retail with Data Analytics",
    client: "E-Commerce Giant",
    duration: "6 months",
    date: "January 2024",
    impact: "25% growth in customer retention",
    description:
      "This case study highlights how leveraging data analytics can boost revenue growth by enhancing customer experience, optimizing inventory management, and improving marketing strategies. By using AI-powered insights, retailers can streamline workflows, personalize customer engagement, and drive sales.",
    image:
      "https://i0.wp.com/blog.nashtechglobal.com/wp-content/uploads/2024/01/1-28.jpg?fit=1024%2C536&ssl=1",
    challenge:
      "Traditional retail marketing strategies often fail to engage modern consumers effectively, leading to low retention rates and inefficient targeting.",
    solution:
      "We leveraged AI-powered insights to personalize marketing campaigns, predict customer behavior, and optimize product recommendations, leading to higher conversions.",
    results: [
      "25% increase in customer retention",
      "Higher efficiency in targeted marketing",
      "Reduction in operational costs",
    ],
    testimonial: {
      quote:
        "The data analytics transformation helped us optimize our marketing and inventory management strategies, increasing revenue and improving customer satisfaction.",
      author: "Jane Smith",
      position: "CMO, E-Commerce Giant",
    },
    technologies: [
      "Predictive Analytics",
      "Data Science",
      "AI-driven Marketing",
    ],
    slug: "retail-data-analytics-revolution",
  },
  {
    id: 3,
    title: "AI and Data Analytics in Retail",
    client: "Tech Retail Solutions",
    duration: "9 months",
    date: "February 2024",
    impact: "40% improvement in operational efficiency",
    description:
      "This case study explores how AI and data analytics drive profitability and growth in retail. By leveraging Microsoft's data solutions, retailers gain a unified data foundation, real-time insights, and personalized customer experiences.",
    image:
      "https://www.immerse.education/wp-content/uploads/2022/11/Website-Images.jpg",
    challenge:
      "Retail businesses often lack real-time insights into customer behavior, leading to inefficient stocking and poor marketing decisions.",
    solution:
      "We implemented AI-powered analytics to track customer patterns, optimize inventory levels, and enhance sales forecasting, resulting in significant cost savings.",
    results: [
      "40% improvement in operational efficiency",
      "Better decision-making with real-time insights",
      "Reduction in stock shortages and overstocking issues",
    ],
    testimonial: {
      quote:
        "AI-driven analytics provided deep insights into customer behavior, allowing us to optimize operations and drive efficiency across multiple store locations.",
      author: "Robert Wilson",
      position: "COO, Tech Retail Solutions",
    },
    technologies: ["AI", "Big Data", "Retail Optimization"],
    slug: "ai-data-analytics-retail",
  },
];

export default function BlogDetails() {
  const navigate = useNavigate();
  const { title } = useParams();
  // Find the blog post that matches the title parameter from the URL
  const blogPost = blogPosts.find((post) => post.slug === title);

  if (!blogPost) {
    return <div className="error-message">Blog post not found!</div>;
  }

  return (
    <>
    <div className="blog-details-container">
      <div style={{ marginBottom: "40px", width: "100%" }}>
        {/* Back button */}
        <div className="blog-back-link-container">
          <a
            href="#"
            className="blog-back-link"
            onClick={() => navigate("/resource/blogs")}
          >
            <FaArrowLeft className="blog-icon-small" />
            <span>Back to Blogs</span>
          </a>
        </div>

        {/* Hero section */}
        <div className="blog-hero-section">
          <div className="blog-hero-content">
            <div className="blog-hero-icon-container">
              <FaImage className="blog-hero-icon" />
            </div>
            <h1 className="blog-hero-title">{blogPost.title}</h1>
          </div>
        </div>
      </div>

      <div style={{ maxWidth: "56rem" }}>
        {/* Key metrics */}
        <div className="blog-metrics-section">
          <div className="blog-metrics-grid">
            <div className="blog-metric-item">
              <FaBuilding className="blog-metric-icon" />
              <p className="blog-metric-label">Client</p>
              <p className="blog-metric-value">{blogPost.client}</p>
            </div>
            <div className="blog-metric-item">
              <FaCalendarAlt className="blog-metric-icon" />
              <p className="blog-metric-label">Date</p>
              <p className="blog-metric-value">{blogPost.date}</p>
            </div>
            <div className="blog-metric-item">
              <FaClock className="blog-metric-icon" />
              <p className="blog-metric-label">Duration</p>
              <p className="blog-metric-value">{blogPost.duration}</p>
            </div>
            <div className="blog-metric-item">
              <FaChartBar className="blog-metric-icon" />
              <p className="blog-metric-label">Impact</p>
              <p className="blog-metric-value">{blogPost.impact}</p>
            </div>
          </div>
        </div>

        {/* Content sections */}
        <div className="blog-content-section">
          <h2 className="blog-section-title">Overview</h2>
          <p className="blog-section-text">{blogPost.description}</p>
        </div>

        <div className="blog-content-section">
          <h2 className="blog-section-title">The Challenge</h2>
          <p className="blog-section-text">{blogPost.challenge}</p>
        </div>

        <div className="blog-content-section">
          <h2 className="blog-section-title">Our Solution</h2>
          <p className="blog-section-text">{blogPost.solution}</p>
        </div>

        <div className="blog-content-section">
          <h2 className="blog-section-title">Results</h2>
          <div className="blog-results-list">
            {blogPost.results.map((result, index) => (
              <div key={index} className="blog-result-item">
                <FaCheck className="blog-check-icon" />
                <p className="blog-result-text">{result}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="blog-content-section">
          <h2 className="blog-section-title">Technologies Used</h2>
          <div className="blog-technologies-list">
            {blogPost.technologies.map((tech, index) => (
              <span key={index} className="blog-technology-badge">
                {tech}
              </span>
            ))}
          </div>
        </div>

        {/* CTA section */}
        <div className="blog-cta-section">
          <h2 className="blog-cta-title">Ready to transform your business?</h2>
          <p className="blog-cta-text">
            Let's discuss how our innovative solutions can help you achieve similar results.
          </p>
          <div>
          <RequestButton href="/product/support">Contact Us</RequestButton>
            {/* <PopupForm /> */}
          </div>
        </div>
      </div>
    </div>
      <Footer />
      </>
  );
}
