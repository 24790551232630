import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../../Footer/FooterSection';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from '@material-ui/core';
import { BsLightningCharge, BsShieldLock, BsGraphUp, BsBoxSeam } from "react-icons/bs";
import "../glam/glam.css";
import { Helmet } from 'react-helmet-async';
import { QuestionAnswer, Translate } from '@material-ui/icons';
import { Insights, Summarize } from '@mui/icons-material';
import VideoSection from '../common/VideoCOmponent';
import DootFeatures from './FeaturesSection';
import SmartSolutions from './SmartSolution';
import Benefits from './BenefitsDoot';
import RequestButton from '../common/RequestButton';
import VideoShowcase from '../video/video-showcase';
import ContactSection from '../common/ContactSection';

const DootLandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Product | DOOT</title>
                <meta name="description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="keywords" content="AI, analytics, document translation, summarization, insights, Q&A, GLAM" />
                <meta name="robots" content="index, follow" />
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="OneLogica Team" />

                {/* Open Graph */}
                <meta property="og:title" content="Product | DOOT" />
                <meta property="og:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta property="og:image" content="URL_of_image.jpg" />
                <meta property="og:url" content="https://www.onelogica.com/product/doot" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="OneLogica" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Product | DOOT" />
                <meta name="twitter:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="twitter:image" content="URL_of_image.jpg" />
                <meta name="twitter:site" content="@OneLogica" />

                <link rel="canonical" href="https://www.onelogica.com/product/doot" />
            </Helmet>

            {/* Hero Section */}
            <Container fluid className="hero-section industry-intro-section  text-white  product-container" style={{
                // backgroundColor: "rgb(51 64 80)",
                // background: "#101c3d",
                backgroundImage: `url(../newimages/dootbg.webp)`,
                filter: "brightness(75%)",
                backgroundSize: "cover",
                backgroundPosition: "right",
                backgroundRepeat: "no-repeat",
                marginTop: "3rem",
                height: "95vh"
                // background: "linear-gradient(45deg, rgb(47 61 77), rgb(53 73 95))", paddingBottom: "0px "
            }}>
                <Row className="align-items-center" style={{width:"100%"}}>
                    <Col md={5}></Col>
                    <Col md={7} className="text-right " style={{ textAlign: "center",padding:"40px" }}>
                        <h6 className="display-5" style={{  color: "white",textAlign:"center" }}>The Gen AI-Powered Chatbot for
                            Smarter Business Operations</h6>
                        <h1 className="display-4" style={{ fontWeight: "600", color: "white",textAlign:"center" }}>DOOT</h1>
                        {/* <p className="lead">GLAM (Generative Language based Analytics and Maximization). It is a cutting-edge platform that leverages Azure's AI capabilities to transform how you interact with your documents. Whether you need translations, summaries, insights, or Q&A, GLAM has got you covered.</p> */}

                       <RequestButton  href="/product/support">Request Demo</RequestButton>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="hero-section  text-white  product-container" style={{ background: "url(../newimages/dootimage.jpg)", backgroundPosition: "left", backgroundSize: "cover", }}>
                <Row>
                    <Col md={12} className="text-left p-5" style={{ background:"#0000007d",}} >
                        {/* <h6 className="display-5" style={{ fontWeight: "400", color: " #d19e94" }}>Generative Language based Analytics and Maximization</h6> */}
                        <p className="lead" style={{    color: "rgb(236 237 243)", textAlign: "left",fontSize:"18px" }}>An advanced, AI-powered chatbot designed to transform
                            business interactions and automate essential processes across
                            industries. With natural language processing, quick information
                            retrieval, and process automation, Doot integrates seamlessly
                            into sectors like healthcare, finance, and retail. Its 24/7
                            multilingual support ensures global reach, while adaptive
                            insights boost efficiency, cut costs, and enhance customer
                            satisfaction.</p>
                    </Col>
                </Row>
            </Container>
            <DootFeatures />

            {/* Features Section */}
            <SmartSolutions />
            <Benefits />
              <VideoShowcase title="Transform Business Operations with AI-Powered Automation ChatBot" description="Doot is a Generative AI-powered enterprise chatbot designed to revolutionize how businesses manage customer interactions and internal processes. With industry-agnostic capabilities, Doot delivers intelligent automation, dynamic conversations, and actionable insights—helping enterprises scale efficiently and improve user satisfaction." videoUrl="https://youtu.be/5FWC7ZputU0"/>

            {/* About Section */}
            {/* <Container id="about" className="py-5 about-section bg-light m-5">
                <Row className="align-items-center">
                    <VideoSection />
                </Row>
            </Container> */}
            {/* CTA Section */}
            <ContactSection/>
            <Footer />
        </>
    );
};

export default DootLandingPage;
