import React from "react";
import Grid from "@mui/material/Grid";
import FeatureCard from "./FeatureCard";
import "./FeaturesSection.css";

const features = [
  {
    image:
      "https://www.techslang.com/wp-content/uploads/2021/04/Untitled-1-3-e1619692179562.jpg",
    title: "Real-Time Collaboration",
    description:
      "Boost teamwork with real-time collaboration tools that enable seamless communication, instant file sharing, and synchronized updates. Whether working remotely or in-office, stay connected and innovate faster.",
  },
  {
    image:
      "https://guptadeepak.com/content/images/size/w2000/2024/06/future-of-website-building-AI-automated.webp",
    title: "AI-Powered Analytics",
    description:
      "Leverage state-of-the-art artificial intelligence to process vast amounts of data, identify key trends, and generate predictive insights. Make informed decisions with precision and gain a competitive edge in your industry.",
  },
  {
    image:
      "https://media.istockphoto.com/id/1434116614/photo/teamwork-diversity-and-sales-manager-planning-branding-ideas-with-a-creative-designer-on-a.jpg?s=612x612&w=0&k=20&c=0qDHz0EDKEgxqcRP7V-YWaGv9nrjKDXG5lz8svrlbcQ=",
    title: "Secure Data Storage",
    description:
      "Protect your sensitive information with our robust cloud storage solutions. Featuring end-to-end encryption, automated backups, and strict access controls, your data remains safeguarded against unauthorized access and cyber threats.",
  },
  {
    image:
      "https://hpassets.smartlook.com/wp-content/uploads/sites/2/2023/11/27085311/responsive-and-relevant_cover.jpg",
    title: "Cross-Platform Access",
    description:
      "Stay productive wherever you go with seamless access to your files and projects across multiple devices. Whether on a laptop, tablet, or smartphone, experience a unified and responsive platform optimized for your workflow.",
  },
];

const FeaturesSection = () => {
  return (
    <section className="features-section">
      <h2 className="features-section__title">Explore Our Features</h2>
      <p className="features-section__subtitle">
        Dynamically leverage cutting-edge technology to enhance productivity,
        security, and collaboration across your entire organization.
      </p>

      {/* MUI Grid for responsive layout */}
      <Grid container spacing={2} justifyContent="center" maxWidth="1200px">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <FeatureCard feature={feature} />
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default FeaturesSection;
