"use client";

import React, { useState, useRef } from "react";
import YouTube from "react-youtube";
import "./video-showcase.css";

const VideoShowcase = ({ title, description, videoUrl }) => {
  const [videoEnded, setVideoEnded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const playerRef = useRef(null);

  // Helper to extract the YouTube video ID from the URL.
  const getYoutubeVideoId = (url) => {
    if (!url) return null;
    const regex = /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/))([^?&]+)/;
    const match = url.match(regex);
    return match && match[1] ? match[1] : null;
  };

  const videoId = getYoutubeVideoId(videoUrl);

  // YouTube player options set to 100% for responsiveness.
  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0, // Autoplay is disabled
      controls: 1,
      rel: 0,
      modestbranding: 1,
      showinfo: 0,
      enablejsapi: 1,
    },
  };
  const onReady = (event) => {
    // Store the player instance for later use
    playerRef.current = event.target;
  };

  const onEnd = () => {
    setVideoEnded(true);
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (playerRef.current) {
      if (isPlaying) {
        playerRef.current.pauseVideo();
      } else {
        playerRef.current.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleRestart = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0);
      playerRef.current.playVideo();
      setVideoEnded(false);
      setIsPlaying(true);
    }
  };

  const handleGetInTouch = () => {
    window.location.href = "/product/support";
  };
  

  return (
    <section className="video-showcase-section">
      <div className="video-showcase-container">
        <div className="video-info">
          <h2 className="video-title">{title}</h2>
          <p className="video-description">{description}</p>
  
        </div>

        <div className="video-player-container">
          {videoEnded && (
            <div className="video-end-overlay animate-fadein">
              <div className="video-end-content">
            
                <div className="video-end-buttons">
         
                  <button className="primary-button get-in-touch-button" style={{borderRadius:'8px'}} onClick={handleGetInTouch}>
                  Know More
                  </button>
                </div>
              </div>
            </div>
          )}
          {videoId ? (
            <YouTube
              videoId={videoId}
              opts={opts}
              onReady={onReady}
              onEnd={onEnd}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              className="video-player"
              id ="videooo"
            />
          ) : (
            <div className="video-error">
              <p>
                Video URL is invalid or missing. Please provide a valid YouTube URL.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

VideoShowcase.defaultProps = {
  title: "Revolutionizing Customer Experience: VACH - AI-Powered Contact Center Solution",
  description:
    "Discover how VACH (Voice-based Analysis & Customer Heuristics) is transforming customer service operations with its unified workforce optimization, advanced analytics, and AI-driven automation.",
  videoUrl: "https://youtu.be/9FH-3r9h_D8?si=riAKMnFfljWAknNN",
};

export default VideoShowcase;
