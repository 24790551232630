// App.js
import React, { Suspense, lazy,useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CustomNavbar from './components/Navbar/CustomNavbar';
// import HomePage from './components/HomePage/HomePage';
// import ProductAndSolution from './components/ProductandSolution/ProductAndSolution';
import Technology from './components/Technology/Technology';
import Resources from './components/Resources/Resources';
import About from './components/About_Us/About';
import Career from './components/Career/Career';
import BFSI from './components/Industry/BFSI';
import Energy from './components/Industry/Energy';
import Manufacturing from './components/Industry/Manufacturing';
import Retail from './components/Industry/Retail';
import HealthCare from './components/Industry/HealthCare';
import Automotive from './components/Industry/Automotive';
import Telecommunications from './components/Industry/Telecommunication';
import TermsAndConditionsPage from './components/Footer/Terms&Condition';
import PrivacyPolicyPage from './components/Footer/Privacy';
import InternsPage from './components/Career/InternsPage';
import FullTimePage from './components/Career/FullTime';
import RoleDetails from './components/Career/RoleDetails';
import DynamicApplicationForm from './components/Career/ApplyJobForm';
import JobDetails from './components/Career/JobDetails';
import Support from './components/SupportForm/Support';
import GlamLandingPage from './components/ProductandSolution/glam/glam';
import NavigationBar from './components/Navbar/Navbar';
import PartnershipPage from './components/Partnership/Partnership';
import SupportRequestPage from './components/ProductandSolution/SupportForm';
import ContactUs from "./components/SupportForm/contact-us"
import VachLandingPage from "./components/ProductandSolution/vach/Vach"
import BlogPage from "./components/Resources/blogs/Blogs"
import BlogDetails from './components/Resources/blogs/BlogDetails';
import CaseStudiesPage from './components/Resources/casestudies/CaseStudies';
import CaseStudyDetail from './components/Resources/casestudies/CaseStudyDetail'; // Import the CaseStudyDetail component
import TrainingPage from './components/Resources/TrainingSection/Main';
import { HelmetProvider } from 'react-helmet-async';
import SupplyChainLandingPage from './components/ProductandSolution/glam/SupplyChain';
import EsgLandingPage from './components/ProductandSolution/glam/Esg';
import DootLandingPage from './components/ProductandSolution/doot/Doot';
import EasLandingPage from './components/ProductandSolution/eas/Eas';
import AboutUs from './components/About_Us/AboutUs';
import PartnerPage from './components/Partnership/PartnershipPage';
import "bootstrap/dist/css/bootstrap.min.css";

const HomePage = lazy(() => import('./components/HomePage/HomePage'));
const ProductAndSolution = lazy(() => import('./components/ProductandSolution/ProductAndSolution'));
const helmetContext = {};

function App() {
  const [showSplash, setShowSplash] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const sessionKey = sessionStorage.getItem("seenSplash");

    if (!sessionKey) {
      setShowSplash(true);
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setShowSplash(false);
          sessionStorage.setItem("seenSplash", "true");
        }, 1000); // Wait for fade-out animation
      }, 3000); // Show splash for 3 seconds
    }
  }, []);


  return (
    
    <HelmetProvider context={helmetContext}>
      <div className="App">
      {showSplash ? (
          <div className={`splash-screen ${fadeOut ? "fade-out" : ""}`}>
            <div className="splash-content">
              <img src="../images/logo1.png" alt="Onelogica Logo" className="logo-image"  />
              {/* <h1 className="splash-text">Onelogica</h1> */}
            </div>
          </div>
        ) : (
        <Router>
          {/* <CustomNavbar/> */}
          <NavigationBar />
          <Routes className="routes">
            <Route path="/" element={<HomePage />} />
            <Route path="/productandsolutions" element={<ProductAndSolution />} />

            {/* Industry */}
            <Route path="/bfsi" element={<BFSI />} />
            <Route path="/energy" element={<Energy />} />
            <Route path="/manufacturing" element={<Manufacturing />} />
            <Route path="/retail" element={<Retail />} />
            <Route path="/healthcare" element={<HealthCare />} />
            <Route path="/automotive" element={<Automotive />} />
            <Route path="/telecommunications" element={<Telecommunications />} />
            {/* Industry End */}

            <Route path="/technology" element={<Technology />} />
            <Route path="/resources" element={<Resources />} />
            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/career" element={<Career />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/internspage" element={<InternsPage />} />
            <Route path="/fulltimepage" element={<FullTimePage />} />
            <Route path="/roledetails" element={<RoleDetails />} />
            <Route path="/applyjobform/:jobId" element={<DynamicApplicationForm />} />
            <Route path="/job/:id" element={<JobDetails />} />
            <Route path="/productandsolutions/support" element={<Support />} />
            <Route path="/product/glam" element={<GlamLandingPage />} />
            <Route path="/product/eas" element={<EasLandingPage />} />
            <Route path="/product/esg" element={<EsgLandingPage />} />
            <Route path="/product/doot" element={<DootLandingPage/>} />
            <Route path="/product/vach" element={<VachLandingPage/>} />
            <Route path="/product/supply-chain" element={<SupplyChainLandingPage />} />
            <Route path="/partnership" element={<PartnershipPage />} />
            <Route path="/product/support" element={<ContactUs />} />
            <Route path="/resource/blogs" element={<BlogPage />} />
            <Route path="/blog/:title" element={<BlogDetails />} />
            <Route path="/resource/casestudies" element={<CaseStudiesPage />} />
            <Route path="/resource/training" element={<TrainingPage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/case-studies/:slug" element={<CaseStudyDetail />} />
            <Route path="/partner/:partnerId" element={<PartnerPage/>}/>
            <Route path="*" element={<div>Page Not Found</div>} />


          </Routes>
        </Router>
      )}
      </div>
    </HelmetProvider>
  );
}

export default App;

