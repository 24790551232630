import { FaEye, FaTruck, FaChartLine, FaExclamationTriangle } from "react-icons/fa";
import "./supplychain.css"; // Custom styles

export default function SupplychainControlOver() {
  return (
    <section className="supplychain-bg py-2">
      {/* Section Heading */}
      <div className="continercontrolchain text-center">
        <h2 className="display-4 fw-bold text-white">Supply Chain Control Tower</h2>
        <p className="lead text-light">
          Gain insights into real-time supply chain operations with advanced analytics.
        </p>
      </div>

      {/* Features Grid */}
      <div className="container mt-4 p-5">
        <div className="row g-4">
          {/* Feature 1 */}
          <div className="col-md-6">
          <div className="supplyfeature-card d-flex align-items-center p-4">
              <FaEye className="iconchaintower" />
              <p className="mb-0">Real-Time Visibility</p>
            </div>
          </div>

          {/* Feature 2 */}
          <div className="col-md-6">
            <div className="supplyfeature-card d-flex align-items-center p-4">
              <FaTruck className="iconchaintower" />
              <p className="mb-0">Supplier & Transport Analytics</p>
            </div>
          </div>

          {/* Feature 3 */}
          <div className="col-md-6">
            <div className="supplyfeature-card d-flex align-items-center p-4">
              <FaChartLine className="iconchaintower" />
              <p className="mb-0">Demand Forecasting</p>
            </div>
          </div>

          {/* Feature 4 */}
          <div className="col-md-6">
            <div className="supplyfeature-card d-flex align-items-center p-4">
              <FaExclamationTriangle className="iconchaintower" />
              <p className="mb-0">Risk Prediction & Scenario Planning</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
