import React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import { Business, Lightbulb, AutoAwesome } from "@mui/icons-material";
import "./expertise.css";

const expertiseIcons = {
  business: <Business sx={{ fontSize: 40, color: "#4A90E2" }} />, 
  innovation: <Lightbulb sx={{ fontSize: 40, color: "#FFD700" }} />, 
  automation: <AutoAwesome sx={{ fontSize: 40, color: "#32CD32" }} />,
};

const ExpertiseCard = ({ title, points, icon }) => {
  return (
    <Card
      className="expertise-card"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%", // Ensures equal height
        minHeight: "250px", // Sets a minimum height for consistency
        borderRadius: 3,
        boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255, 0.16)",
        backdropFilter: "blur(10px)",
        border: "1px solid rgba(255, 255, 255, 0.3)",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mb: 2 }}>
          {expertiseIcons[icon]}
        </Box>
        <Typography variant="h6" fontWeight="bold" sx={{ color: "blue" }}>
          {title}
        </Typography>
        <ul className="expertise-list" style={{ padding: 0, listStyle: "none", color: "blue", marginTop: "10px", flexGrow: 1 }}>
          {points.map((point, index) => (
            <li key={index} style={{ marginBottom: "8px", color: "black", }}><span style={{color:"#030456"}}>✔ </span>{point}</li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default ExpertiseCard;
