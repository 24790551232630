// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for Contact Us page */
.contact-us-container{
    overflow-x: hidden;
    margin-top: 67px !important;
  }
  
  .hero-section-contactus {
    position: relative;
  }
  
  .hero-content {
    max-width: 600px;
  }
  
  .icon-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .card-body-contact{
    background-color: #f1f1f4;
    height: 100%;
    border-radius: 8px;
  } 
  .hover-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .hover-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
  }
  
  .form-floating > label {
    padding-left: 1rem;
  }
  
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    padding: 1rem 0.75rem;
  }
  
  .form-floating > textarea.form-control {
    height: auto;
  }
  
  @media (max-width: 768px) {
    .hero-content {
      padding: 2rem 1rem !important;
    }
  
    .card-body {
      padding: 2rem !important;
    }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/SupportForm/contact-us.css"],"names":[],"mappings":"AAAA,sCAAsC;AACtC;IACI,kBAAkB;IAClB,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;EAChB;EACA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;EACA;IACE,qDAAqD;EACvD;;EAEA;IACE,2BAA2B;IAC3B,qDAAqD;EACvD;;EAEA;IACE,kBAAkB;EACpB;;EAEA;;IAEE,0BAA0B;IAC1B,qBAAqB;EACvB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE;MACE,6BAA6B;IAC/B;;IAEA;MACE,wBAAwB;IAC1B;EACF","sourcesContent":["/* Custom styles for Contact Us page */\r\n.contact-us-container{\r\n    overflow-x: hidden;\r\n    margin-top: 67px !important;\r\n  }\r\n  \r\n  .hero-section-contactus {\r\n    position: relative;\r\n  }\r\n  \r\n  .hero-content {\r\n    max-width: 600px;\r\n  }\r\n  \r\n  .icon-circle {\r\n    width: 70px;\r\n    height: 70px;\r\n    border-radius: 50%;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin: 0 auto;\r\n  }\r\n  .card-body-contact{\r\n    background-color: #f1f1f4;\r\n    height: 100%;\r\n    border-radius: 8px;\r\n  } \r\n  .hover-card {\r\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\r\n  }\r\n  \r\n  .hover-card:hover {\r\n    transform: translateY(-5px);\r\n    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;\r\n  }\r\n  \r\n  .form-floating > label {\r\n    padding-left: 1rem;\r\n  }\r\n  \r\n  .form-floating > .form-control,\r\n  .form-floating > .form-select {\r\n    height: calc(3.5rem + 2px);\r\n    padding: 1rem 0.75rem;\r\n  }\r\n  \r\n  .form-floating > textarea.form-control {\r\n    height: auto;\r\n  }\r\n  \r\n  @media (max-width: 768px) {\r\n    .hero-content {\r\n      padding: 2rem 1rem !important;\r\n    }\r\n  \r\n    .card-body {\r\n      padding: 2rem !important;\r\n    }\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
