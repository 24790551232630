// BFSIPage.js

import React from "react";
import IndustryPage from "./IndustryPage";
import {
  Group, Security, Build, Campaign, CreditCard,
} from "@mui/icons-material";

const BFSIPage = () => {
  // Specific details for BFSI
  const headingText = "BFSI";
  const introText = `"Empower your BFSI organization with data-driven insights and improve decision-making with our advanced data science solutions."`;
  const paragraphText = `The BFSI market has demonstrated significant growth in recent years and is expected to continue expanding in the near future. In 2022, the market was valued approximately at 24.5 trillion dollars and is projected to approach 30 trillion dollars by 2025. This growth can be attributed to a variety of factors, including the increasing adoption of digital technologies, the rise of open banking regulations, and the growing demand for personalized financial products and services.`;

  const backgroundImage = "../images/bfsi.jpg";

  const sectionImage = "../industry/bfsiimg.jpg";
 
  const carouselImages = [
    "../industry/4.png",
    "../industry/5.png",
    "../industry/6.png",
  ];

  const gridItems = [
    {
      title: "Banking",
      description: "We aid banks with smart solutions for banking, enhanced fraud detection, and automated customer services.",
      additionalImage: "../videos/Banking_Use_Cased.gif",
      img:"../industry/4.png",
      useCases: [
        { title: "Customer Segmentation", icon: <Group /> },
        { title: "Risk Management", icon: <Security /> },
        { title: "Predictive Maintenance", icon: <Build /> },
        { title: "Marketing Campaign", icon: <Campaign /> },
        { title: "Credit Risk Analysis", icon: <CreditCard /> },
      ],
    },
    {
      title: "Capital Market",
      description: "We focus on ensuring sustainable, long-term growth for lasting capital gains in an increasingly digital future.",
      additionalImage: "../videos/CapitalMarket.gif",
      img:"../industry/5.png",
      useCases: [
        { title: "Performance Analysis", icon: <Group /> },
        { title: "Algorithmic Trading", icon: <Security /> },
        { title: "Predictive Maintenance", icon: <Build /> },
        { title: "Sentiment Analysis", icon: <Campaign /> },
        { title: "Chatbot and AI Driven Customer Service ", icon: <CreditCard /> },
      ],
    },
    {
      title: "Insurance",
      description: "We Discover how we help redefine insurance gains by redefining businesses through digitalization.",
      additionalImage: "../videos/InsuranceGif.gif",
      img:"../industry/6.png",
      useCases: [
        { title: "Financial Analysis", icon: <Group /> },
        { title: "Algorithmic Trading", icon: <Security /> },
        { title: "Pricing Optimization", icon: <Build /> },
        { title: "Fraud Detectiond", icon: <Campaign /> },
        { title: "Claims Management ", icon: <CreditCard /> },
      ],
    },
    // Add more grid items as required
  ];

  return (
    <IndustryPage 
      headingText={headingText}
      introText={introText}
      backgroundImage={backgroundImage}
      sectionImage={sectionImage}
      paragraphText={paragraphText}
      carouselImages={carouselImages}
      gridItems={gridItems}
    />
    
  );
};

export default BFSIPage;
