import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./productBenefit.css";

const benefits = [
 { id: 1, title: "Fueling Your Creativity", description: "Leverage GLAM’s advanced features to unlock fresh ideas and push boundaries. Turn data into action and drive impactful outcomes.", link: "#" },
  { id:2, title: "Lightning-Fast Performance", description: "GLAM accelerates your workflows by processing data and delivering results in record time. Whether it’s translations or summaries, save time and focus on what matters most.", link: "#" },
  { id:3, title: "Seamless Collaboration", description: " Collaborate with teammates in real-time. Share insights, edit documents, and achieve more together with GLAM’s intuitive tools.", link: "#" },
  { id: 4, title: "Uncompromising Security", description: "GLAM ensures the highest level of security, keeping your sensitive information encrypted and safe from unauthorized access.", link: "#" },
  { id: 5, title: "Scalable and Reliable", description: "GLAM is deployed on leading cloud platforms, ensuring a reliable, scalable, and high-performance environment to grow your operations. Whether you're handling small datasets or massive enterprise-scale projects, GLAM adapts to your needs seamlessly.", link: "#" },
  { id: 6, title: "Customization", description: "GLAM offers flexible customization options to tailor the platform to your unique business needs. From UI enhancements to workflow optimizations, you can configure GLAM to align perfectly with your operational requirements.", link: "#" },
];

const ProductBenefits = () => {
  return (
    <div className="product-benefits-section">
      {/* Background Overlay */}
      <div className="bg-overlay"></div>

      <Container className="text-center text-white position-relative" style={{ display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"100%" }}>
        <h2 style={{fontSize:"1.75rem",fontWeight:600}} className="fw-bold">Why GLAM is Your Ultimate Choice for Smarter Workflows</h2>
        <p>Discover the unique benefits that set GLAM apart from the rest.</p>

        <Row className="row-cols-1 row-cols-md-3 g-4 mt-3">
          {benefits.map((benefit) => (
            <Col key={benefit.id}>
              <Card className="glambenefit-card h-100 shadow">
                <Card.Body>
                  <div className="benefit-number">{benefit.id}</div>
                  <Card.Title  style={{textAlign:"center",fontWeight:600,fontSize:"1.3rem"}}>{benefit.title}</Card.Title>
                  <Card.Text style={{fontSize:"14px",color:"#000"}}>{benefit.description}</Card.Text>
                  <a href={benefit.link} className="learn-more">LEARN MORE</a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ProductBenefits;
