// DropdownMenu.js
import React from 'react';
import { Link } from 'react-router-dom';

const DropdownMenu = React.forwardRef(({ videoSrc, cards, onClose, isOpen }, ref) => {
  
  return (
    <div ref={ref} className={`sub-menu product-submenu ${isOpen ? 'open' : ''}`}>
      {/* Video introduction section */}
      <div className="product-intro">
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            width: '100%',
            height: '12vh',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
      {/* Dropdown content with cards */}
      <div className="dropdown-fullwidth">
        <div className="dropdown-row">
          {cards.map((card, index) => (
            <Link key={index} to={card.to} onClick={onClose}>
              <div className="product-card">
                <p className="product-card-title">{card.title}</p>
                <div className="product-card-content">
                  {card.content.map((item, idx) => (
                    <a key={idx} href={item.href}>{item.text}</a>
                  ))}
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});

export default DropdownMenu;
