import React, { useState } from "react";
import { FaChartPie, FaUmbrella, FaChartLine, FaMedal, FaCogs, FaPuzzlePiece } from "react-icons/fa";
import "./benefitEas.css";

const benefitsList = [
  { icon: <FaChartPie />, title: "End-to-End Visibility", description: "Provides real-time insights into business operations." },
  { icon: <FaUmbrella />, title: "Risk Mitigation", description: "Helps identify and minimize potential risks." },
  { icon: <FaChartLine />, title: "Growth Enablement", description: "Accelerates business expansion with smart analytics." },
  { icon: <FaMedal />, title: "Predictive Capabilities", description: "Uses AI to predict market trends and demands." },
  { icon: <FaCogs />, title: "Seamless Integration", description: "Easily integrates with your existing tech stack." },
  { icon: <FaPuzzlePiece />, title: "Customizable Solutions", description: "Tailored to fit your unique business needs." },
];

const EasBenefit = () => {
  return (
    <div className="benefits-container">
      <div>
        <h2 className="benefitheader">Benefits of EAS</h2>
      </div>
      <div  className="benefitsContainer">
        {benefitsList.map((item, index) => (
          <div key={index} className="flip-card">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <div className="benefit-icon">{item.icon}</div>
                <h2>{item.title}</h2>
              </div>
              <div className="flip-card-back">
                <p>{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EasBenefit;
