import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../../Footer/FooterSection';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from '@material-ui/core';
import { BsLightningCharge, BsShieldLock, BsGraphUp, BsBoxSeam } from "react-icons/bs";
import "./glam.css";
import { Helmet } from 'react-helmet-async';
import { QuestionAnswer, Translate } from '@material-ui/icons';
import { Insights, QueryStats, Summarize } from '@mui/icons-material';
import ChurnPrediction from "../../../assest/icons/chrunpredection.png";
import revenueGrowth from "../../../assest/icons/revenueGrowth.png";
import inventoryManagement from "../../../assest/icons/inventory-management.png";
import laborOptimization from "../../../assest/icons/laborOptimization.png";
import customerRetention from '../../../assest/icons/customerretention.png';
import recommendedEngine from '../../../assest/icons/recommendationengine.png';
import SupplychainAdvantagesSection from './BenefitSupplychain';
import SupplychainControlOver from './SupplychainContralOver';
import RequestButton from '../common/RequestButton';
import FeaturesSection from './SupplychainFeature';
import VideoShowcase from '../video/video-showcase';
import ContactSection from '../common/ContactSection';

const SupplyChainLandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Product | SupplyChain</title>
                <meta name="description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="keywords" content="AI, analytics, document translation, summarization, insights, Q&A, GLAM" />
                <meta name="robots" content="index, follow" />
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="OneLogica Team" />

                {/* Open Graph */}
                <meta property="og:title" content="Product | EAS" />
                <meta property="og:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta property="og:image" content="URL_of_image.jpg" />
                <meta property="og:url" content="https://www.onelogica.com/product/eas" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="OneLogica" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Product | SupplyChain" />
                <meta name="twitter:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="twitter:image" content="URL_of_image.jpg" />
                <meta name="twitter:site" content="@OneLogica" />

                <link rel="canonical" href="https://www.onelogica.com/product/supplychain" />
            </Helmet>

            {/* Hero Section */}
            <Container fluid className="hero-section   text-white  product-container" style={{
                // backgroundColor: "rgb(51 64 80)",
                // background: "#101c3d",
                backgroundImage: `url(../newimages/supplychian.jpg)`,
                // filter: "brightness(50%)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                marginTop: "3rem",
                height: "95vh",

                // background: "linear-gradient(45deg, rgb(47 61 77), rgb(53 73 95))", paddingBottom: "0px "
            }}>
                <Row className="align-items-center p-5">
                    <Col md={10} className="text-left p-5" >
                        <h6 className="display-5" style={{ fontWeight: "400", color: "white" }}>Retail-Supply Chain Intelligence Suite</h6>
                        <RequestButton href="/product/support">Request Demo</RequestButton>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="hero-section  text-white  product-container" style={{ background: "linear-gradient(45deg,rgb(3, 4, 86), rgb(0, 120, 237))" }}>
                <Row>
                    <Col md={12} className="text-left p-5" >
                        {/* <h6 className="display-5" style={{ fontWeight: "400", color: " #d19e94" }}>Generative Language based Analytics and Maximization</h6> */}
                        <p className="lead" style={{fontSize:"18px",textAlign:"left"}}>Onelogica presents the Retail Supply Chain Intelligence Suite – a cutting edge, big data-driven solution designed to transform retail operations. This
                            end-to-end platform integrates Generative AI, Medallion Architecture, and
                            cloud-based analytics to deliver actionable insights, enhancing profitability,
                            operational efficiency, and customer satisfaction.</p>
                    </Col>
                </Row>
            </Container>

            {/* Features Section */}
            <FeaturesSection />
            {/* Advantages Section */}
            <SupplychainControlOver />
            <SupplychainAdvantagesSection />
            <VideoShowcase title="AI-powered suite for optimizing retail supply chains, enhancing efficiency, and profitability." description="The Retail Supply Chain Intelligence Suite by Onelogica leverages AI-driven analytics to streamline retail supply chains, boost efficiency, and drive profitability. It provides real-time visibility, predictive insights, and solutions for inventory management, demand forecasting, and enhancing customer satisfaction." videoUrl="https://www.youtube.com/watch?v=0TXfTsMLkDc"/>
            {/* About Section */}
            {/* <Container id="about" className="py-5 about-section bg-light mb-5">
                <Row className="align-items-center">
                    <Col md={6} className="text-center">
                        <img
                            src="https://via.placeholder.com/500"
                            alt="About Glam"
                            className="img-fluid rounded shadow"
                        />
                    </Col>
                </Row>
            </Container> */}

           <ContactSection/>
            <Footer />
        </>
    );
};

export default SupplyChainLandingPage;
