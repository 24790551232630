import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaBolt, FaShieldAlt, FaChartLine, FaSmile, FaChartBar } from 'react-icons/fa';
import './supplychain.css';
import { icon } from '@fortawesome/fontawesome-svg-core';


const advantages = [
  {
    title: 'Faster Time to Market',
    description: [
      'Experience rapid deployment with our pre-built, adaptable solution.',
      'Enable your business to realize benefits swiftly.',
    ],
    icon: <FaBolt size={30} />,
    iconColor: '#007BFF',  // Bright Blue
    background: '#A2C6FF', // Light Blue
  },
  {
    title: 'Risk Mitigation',
    description: [
      'Anticipate and address potential risks within the supply chain.',
      'Ensure smooth operations by mitigating disruptions before they occur.',
    ],
    icon: <FaShieldAlt size={30} />,
    iconColor: '#0056B3',  // Dark Blue
    background: '#6CA6FF', // Soft Light Blue
  },
  {
    title: 'Comprehensive Insights',
    description: [
      'Gain a 360-degree view of the retail supply chain from procurement to delivery.',
      'Make informed decisions across operations for better efficiency.',
    ],
    icon: <FaChartLine size={30} />,
    iconColor: '#003D73',  // Deep Blue
    background: '#A4D0FF', // Very Light Blue
  },
  {
    title: 'Customer Satisfaction',
    description: [
      'Enhance customer experiences with personalized product recommendations.',
      'Ensure efficient deliveries and proactive service improvements.',
    ],
    icon: <FaSmile size={30} />,
    iconColor: '#003366',  // Dark Navy Blue
    background: '#7BB8FF', // Pastel Light Blue
  },
  {
    title: 'Revenue Growth',
    description: [
      'Leverage advanced analytics to enhance sales strategies.',
      'Increase profitability and drive sustainable revenue growth.',
    ],
    icon: <FaChartBar size={30} />,
    iconColor: '#1E3A8A',  // Midnight Blue
    background: '#C5D9FF', // Soft Blue-Gray
  },
];



const SupplyChainAdvantagesSection = () => {
  return (
    <Container fluid className="brand-architecture py-5" >
      <Row className="text-center mb-5">
        <Col>
          <h2 className="section-title">Benefits of Retail-Supply Chain Intelligence Suite</h2>
          <p className="section-subtitle">
            This slide showcases the importance of defined Retail-Supply Chain Intelligence Suite.
          </p>
        </Col>
      </Row>
      <Row className="justify-content-center" >
        <Col md={8} lg={8} className='p-5' >
          {advantages.map((benefit, index) => (
            <Card
              key={index}
              className="benefit-card mb-4"
              style={{ marginLeft: index % 2 !== 0 ? '50px' : '0' }} // Apply left margin to alternate cards
            >
              <Card.Body className="d-flex align-items-start p-0">
                <div>
                  <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                    <div className="benefit-icon-container" style={{ background: benefit.iconColor }}>{benefit.icon}</div>
                    <div style={{ display: "flex", width: "100%", height: "90%", backgroundColor: benefit.background, alignContent: "center", alignItems: "center", justifyContent: "left" }}>
                      <Card.Title className="benefit-title" style={{ backgroundColor: benefit.color,margin:"5px",fontWeight:"500" }}>{benefit.title}</Card.Title>
                    </div>
                  </div>
                  <ul className="benefit-list">
                    {benefit.description.map((desc, idx) => (
                      <li key={idx}>{desc}</li>
                    ))}
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ))}

        </Col>
        <Col md={4} lg={4} className="image-container">
          <div className="circle-wrapper">
            <img src="../newimages/Untitled design (6).png" alt="Meeting" className="circular-image" />
            <div className="overlay-shape shape1"></div>
          </div>
          <div className="circle-wrapper"  >
            <img src="../newimages/Untitled design (7).png" alt="Discussion" className="circular-image" style={{ marginLeft: "5rem" }} />
            <div className="overlay-shape shape2"></div>
          </div>
          <div className="circle-wrapper">
            <img src="../newimages/Untitled design (8).png" alt="Teamwork" className="circular-image" />
            <div className="overlay-shape shape3"></div>
          </div>
        </Col>

      </Row>
    </Container>
  );
};

export default SupplyChainAdvantagesSection;
