// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* caseStudies.css */
.header-banner {
    background-color: #333;
    padding: 50px 0;
    margin-bottom: 50px;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
  }
  
  .hero-description {
    font-size: 1.2rem;
  }
  
  .case-study-card {
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .case-study-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .case-study-category {
    font-size: 1.1rem;
    color: #007bff;
  }
  
  .case-study-description {
    font-size: 1rem;
    color: #555;
  }
/*   
  .read-more-btn {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
  }
   */
  .read-more-btn:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Resources/casestudies/caseStudies.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,sBAAsB;IACtB,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;AACF;;;;;;;;;IASI;EACF;IACE,yBAAyB;EAC3B","sourcesContent":["/* caseStudies.css */\r\n.header-banner {\r\n    background-color: #333;\r\n    padding: 50px 0;\r\n    margin-bottom: 50px;\r\n  }\r\n  \r\n  .hero-title {\r\n    font-size: 3rem;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .hero-description {\r\n    font-size: 1.2rem;\r\n  }\r\n  \r\n  .case-study-card {\r\n    border: 1px solid #ddd;\r\n    border-radius: 8px;\r\n  }\r\n  \r\n  .case-study-title {\r\n    font-size: 1.5rem;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .case-study-category {\r\n    font-size: 1.1rem;\r\n    color: #007bff;\r\n  }\r\n  \r\n  .case-study-description {\r\n    font-size: 1rem;\r\n    color: #555;\r\n  }\r\n/*   \r\n  .read-more-btn {\r\n    background-color: #007bff;\r\n    border: none;\r\n    color: white;\r\n    padding: 10px 20px;\r\n    font-size: 1rem;\r\n    border-radius: 5px;\r\n  }\r\n   */\r\n  .read-more-btn:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
