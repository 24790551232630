import "bootstrap/dist/css/bootstrap.min.css"
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaComments } from "react-icons/fa";
import PopupForm from "../HomePage/ContactForm";
import Footer from "../Footer/Footer";
// import SupportRequestPage from "../ProductAndSolution/SupportForm";
import './contact-us.css';
import SupportRequestPage from '.././ProductandSolution/SupportForm';
const ContactUs = () => {
  return (
    <div className="contact-us-container">
      {/* Hero Section with Split Design */}
      <section className="hero-section-contactus">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div
              className="col-lg-6 d-flex flex-column justify-content-center p-5"
              style={{ backgroundColor: "#f8f9fa" }}
            >
              <div className="hero-content px-md-5 py-5">
                <h1 className="display-4 fw-bold mb-4" style={{textAlign:'left'}}>Get in Touch</h1>
                <p className="lead mb-4" style={{textAlign:'left'}}>
                  Want to get in touch? We'd love to hear from you. Here's how you can reach us...
                </p>
                <div className="d-flex gap-3 mt-3">
             <PopupForm/>
                </div>
              </div>
            </div>
            <div className="col-lg-6 p-0">
              <img
                       src="../images/contactusnewimg.jpg"

                alt="Contact Us"
                className="img-fluid w-100 h-100"
                style={{ objectFit: "cover", minHeight: "500px" }}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Contact Cards Section */}
      <section className="contact-cards py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-md-6">
              <div className="card h-100 border-0 shadow-sm hover-card">
                <div className="card-body-contact text-center p-5">
                  <div className="icon-wrapper mb-4">
                    <div className="icon-circle bg-light d-inline-flex align-items-center justify-content-center">
                      <FaPhone size={28} className="text-primary" />
                    </div>
                  </div>
                  <h3 className="card-title mb-3">Talk to Sales</h3>
                  <p className="card-text mb-4">
                  Interested? Call our sales team today!
                  </p>
                  <div className="contact-info-contactus mt-4">
                    <p className="fw-bold mb-1">+91-9953793025</p>
                    {/* <p className="text-muted small">Monday-Friday, 9AM-5PM EST</p> */}
                  </div>
                  <button className="btn btn-outline-primary mt-3 px-4"
                  onClick={() => window.location.href = "tel:+919953793025"}>Call Us</button>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card h-100 border-0 shadow-sm hover-card">
              <div className="card-body-contact text-center p-5">
                  <div className="icon-wrapper mb-4">
                    <div className="icon-circle bg-light d-inline-flex align-items-center justify-content-center">
                      <FaEnvelope size={28} className="text-primary" />
                    </div>
                  </div>
                  <h3 className="card-title mb-3">Talk to Sales</h3>
                  <p className="card-text mb-4">
                  Need help? Our support team is here for you.
                  </p>
                  <div className="contact-info-contactus mt-4">
                    <p className="fw-bold mb-1" onClick={() => window.location.href = "mailto:sales@onelogica.com"}>sales@onelogica.com</p>
                    {/* <p className="text-muted small">Monday-Friday, 9AM-5PM EST</p> */}
                  </div>
                  <button className="btn btn-outline-primary mt-3 px-4">Mail Us</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



 


      {/* Uncomment these if needed */}
      <SupportRequestPage/>
      {/* <Footer/> */}
    </div>
  )
}

export default ContactUs
