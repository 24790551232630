// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-filter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .category-button {
    padding: 0.5rem 1rem;
    border: 1px solid #e2e8f0;
    background-color: white;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .category-button:hover {
    background-color: #f7fafc;
  }
  
  .category-button.active {
    background-color: #0a1172;
    color: white;
    border-color: #0a1172;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/Resources/blogs/CategoryFilter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,2BAA2B;IAC3B,WAAW;IACX,qBAAqB;EACvB;;EAEA;IACE,oBAAoB;IACpB,yBAAyB;IACzB,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;EACvB","sourcesContent":[".category-filter {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: flex-start;\r\n    gap: 0.5rem;\r\n    margin-bottom: 1.5rem;\r\n  }\r\n  \r\n  .category-button {\r\n    padding: 0.5rem 1rem;\r\n    border: 1px solid #e2e8f0;\r\n    background-color: white;\r\n    border-radius: 0.25rem;\r\n    font-size: 0.875rem;\r\n    cursor: pointer;\r\n    transition: all 0.2s ease;\r\n  }\r\n  \r\n  .category-button:hover {\r\n    background-color: #f7fafc;\r\n  }\r\n  \r\n  .category-button.active {\r\n    background-color: #0a1172;\r\n    color: white;\r\n    border-color: #0a1172;\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
