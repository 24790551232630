import React, { useRef, useEffect } from "react";
import "./Category.css";
import { FaLaptopCode, FaPencilRuler, FaBullhorn } from "react-icons/fa";

const Category = () => {
  const scrollRef = useRef(null);

  // Array of category data
  const categories = [
    {
      id: 1,
      title: "Web Development",
      description:
        "Master modern web technologies and frameworks through interactive lessons, hands-on projects, and expert guidance. Build responsive websites and dynamic applications with real-world scenarios.",
      icon: <FaLaptopCode className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 2,
      title: "UI/UX Design",
      description:
        "Learn user experience and interface design principles with practical projects, real-world case studies, and expert mentorship. Develop intuitive and visually appealing digital products.",
      icon: <FaPencilRuler className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 3,
      title: "Digital Marketing",
      description:
        "Explore SEO, social media marketing, and content strategies with industry-focused courses. Gain hands-on experience in executing digital campaigns and optimizing brand growth.",
      icon: <FaBullhorn className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 4,
      title: "Cloud Computing",
      description:
        "Gain in-depth expertise in cloud infrastructure, deployment strategies, and security best practices. Learn how to build, scale, and maintain applications on cloud platforms efficiently.",
      icon: <FaLaptopCode className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 5,
      title: "AI & ML",
      description:
        "Dive into the world of artificial intelligence and machine learning. Explore algorithms, deep learning models, and real-world applications to develop intelligent and data-driven solutions.",
      icon: <FaPencilRuler className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 6,
      title: "Microsoft Favrick",
      description:
        "Discover modern development frameworks and enterprise-level solutions powered by Microsoft Favrick. Learn how to build scalable and efficient software for various business needs.",
      icon: <FaLaptopCode className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 7,
      title: "DevOps",
      description:
        "Master DevOps methodologies to streamline development workflows, automate deployments, and improve collaboration. Enhance CI/CD pipelines for faster and more reliable software delivery.",
      icon: <FaPencilRuler className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 8,
      title: "Full Stack",
      description:
        "Become a proficient full-stack developer by mastering front-end and back-end technologies. Learn how to create, manage, and deploy scalable and efficient web applications.",
      icon: <FaLaptopCode className="category-section__icon" />,
      link: "/product/support",
    },
    {
      id: 9,
      title: "Generative AI",
      description:
        "Explore Generative AI and cutting-edge machine learning models that create innovative solutions. Learn how AI generates text, images, and other creative content with real-world applications.",
      icon: <FaBullhorn className="category-section__icon" />,
      link: "/product/support",
    },
  ];
  
  // Manual scroll left
  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -409, behavior: "smooth" });
    }
  };

  // Manual scroll right
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 409, behavior: "smooth" });
    }
  };

  // Auto-scroll every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (!scrollRef.current) return;

      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      // Check if we're near the end
      if (scrollLeft + clientWidth >= scrollWidth - 5) {
        // If at or near the end, jump back to start
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      } else {
        // Otherwise, scroll right by 409px
        scrollRef.current.scrollBy({ left: 409, behavior: "smooth" });
      }
    }, 3000);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="category-section">
      <div className="category-section__container">
        {/* Header */}
        <div className="category-section__header">
          <h2 className="category-section__title">Most Popular Categories</h2>
          <p className="category-section__subtitle">
            Explore our comprehensive education to empower learners of all ages:
            discover interactive lessons, expert guidance, and personalized
            learning pathways today. Unlock your potential with us!
          </p>
        </div>

        {/* Cards Wrapper with Scroll Reference */}
        <div className="category-section__cards-wrapper" ref={scrollRef}>
          <div className="category-section__cards">
            {categories.map((category) => (
              <div key={category.id} className="category-section__card">
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <div className="category-section__icon-container">
                    {category.icon}
                  </div>
                  <h3>{category.title}</h3>
                </div>
                <p>{category.description}</p>
                <a href={category.link} className="category-section__link">
                  Read More
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Navigation Arrows */}
        <div className="category-section__nav">
          <button
            className="category-section__nav-arrow category-section__nav-arrow--left"
            onClick={scrollLeft}
          >
            ←
          </button>
          <button
            className="category-section__nav-arrow category-section__nav-arrow--right"
            onClick={scrollRight}
          >
            →
          </button>
        </div>
      </div>
    </section>
  );
};

export default Category;
