import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaBrain, FaCloud, FaIndustry } from "react-icons/fa";
import "./AboutUs.css";
import Footer from "../Footer/FooterSection";
import Partners from "../HomePage/Partners";
import TestimonialLanding from "../Resources/TrainingSection/TestimonialLanding";
import SectionHeading from "../ProductandSolution/common/HeadingSection";

const whyChooseUsData = [
  {
    icon: <FaBrain className="choose-icon" />,
    title: "AI-First Approach",
    text: "Integrating Generative AI & Intelligent Automation across solutions.",
  },
  {
    icon: <FaCloud className="choose-icon" />,
    title: "Cloud & Data Expertise",
    text: "Seamless integration across Azure, AWS, Databricks, and Salesforce.",
  },
  {
    icon: <FaIndustry className="choose-icon" />,
    title: "Industry-Focused Solutions",
    text: "Tailored AI-powered products for BFSI, Healthcare, Retail, and Manufacturing.",
  },
  {
    icon: <FaIndustry className="choose-icon" />,
    title: "Faster AI Adoption",
    text: "Pre-built accelerators to speed up AI, CRM, and Data Modernization.",
  },
  {
    icon: <FaIndustry className="choose-icon" />,
    title: "Global Delivery Model",
    text: "A highly skilled team with expertise in AI Strategy, Cloud Migration, and Digital Transformation ",
  },

];

const AboutUs = () => {
  return (
    <div className="about-us">
      {/* Hero Section */}
      <section className="hero-section">
        <Container>
          <Row className=" align-items-center justify-content-center ">
            <Col md={6}>
              <h1 className="hero-title">
                About Us
              </h1>
            </Col>
            <Col md={6}>
              <div className="hero-image-placeholder"></div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Who We Are Section */}
      <section className="who-we-are-section">
        <Container style={{ maxWidth: "90%" }}>
          <Row className="align-items-center py-3">
            <Col md={6} className="p-3">
              <div className="who-we-are-image-placeholder">
                <img src="/aboutus/whyonelogica.jpg" style={{ height: "100%", width: "100%", borderRadius: "15px" }}></img>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <h2>Who We Are?</h2>
              <p>
                Onelogica is a next-generation AI & Data solutions company,
                specializing in Generative AI, Data Engineering, Cloud
                Transformation, and AI-Driven CRM Implementations. As a trusted
                partner for Microsoft, Databricks, Salesforce, AWS, Snowflake,
                DBT, and Aerospike, we help enterprises accelerate digital
                transformation, unlock AI-driven insights, and optimize business
                operations.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mission and Vision Section */}
      <section className="mission-vision-section">
  <Container style={{ maxWidth: "90%" }}>
    <Row className="align-items-center py-3">
      <Col md={6} className="p-3 mt-4 mb-4">
        <h2>Our Mission</h2>
        <p style={{color:"#000",fontSize:"15px"}}>
          To redefine the way businesses and individuals interact with
          technology, ensuring AI & Tech Innovation become an effortless
          part of life. Our mission is to empower enterprises to
          democratize AI adoption and enable enterprises to scale with
          next-gen AI solutions that drive automation, intelligence, and
          unlock new digital growth opportunities.
        </p>
        <h2>Our Vision</h2>
        <p style={{color:"#000",fontSize:"15px"}}>
          To create a world where AI, Data, and Cloud innovation drive
          limitless possibilities. With an agile product vision, we
          foresee and embrace technology shifts faster than anyone else.
        </p>
      </Col>
      <Col md={6} sm={12} className="aboutimage-container px-3 mt-4 mb-4">
        <div className="image-wrapper">
          <img
            src="/aboutus/vision.png"
            alt="AI Innovation"
            className="image1"
          />
          <img
            src="/aboutus/mission.png"
            alt="Digital Growth"
            className="image2"
          />
        </div>
      </Col>
    </Row>
  </Container>
</section>


      {/* Why Choose Us Section */}
      {/* Why Choose Us Section */}
      {/* Why Choose Us Section */}
      <section className="why-choose-us-section">
        <Container>
          <Row>
            <Col md={12}>
            {/* <SectionHeading title="Why Onelogica?"/> */}
              <h2 className="text-center">Why Onelogica?</h2>
            </Col>
          </Row>

          {[...Array(Math.ceil(whyChooseUsData.length / 3))].map((_, rowIndex) => {
            const rowItems = whyChooseUsData.slice(rowIndex * 3, rowIndex * 3 + 3);

            return (
              <Row key={rowIndex} className="justify-content-center">
                {rowItems.map((item, index) => (
                  <Col
                    key={index}
                    md={4}
                    className="d-flex justify-content-center align-items-stretch mb-5"
                  >
                    <Card className="why-card">
                      <Card.Body>
                        {item.icon}
                        <Card.Title style={{textAlign:"center"}}>{item.title}</Card.Title>
                        <p style={{color:"#000",fontSize:"14px"}}> {item.text}</p>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            );
          })}
        </Container>
      </section>



      {/* Footer Section */}
      <TestimonialLanding />
      <div style={{marginBottom:"25px"}}>
      <Partners />
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
