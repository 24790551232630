import React, { useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import Footer from "../Footer/Footer";
import { Dialog, DialogContent, Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SupportRequestPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    ticketName: "",
    ticketDescription: "",
    ticketPriority: "Low",
    file: null,
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // Dialog state

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error on change
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      file,
    }));
  };

  // Validate the form before submission
  const validateForm = () => {
    let newErrors = {};
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!formData.ticketName.trim()) {
      newErrors.ticketName = "Name is required.";
    }

    if (!formData.ticketDescription.trim()) {
      newErrors.ticketDescription = "Description is required.";
    }

    if (!formData.ticketPriority) {
      newErrors.ticketPriority = "Please select a priority.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop if validation fails

    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("ticketName", formData.ticketName);
    formDataToSend.append("ticketDescription", formData.ticketDescription);
    formDataToSend.append("ticketPriority", formData.ticketPriority);
    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    try {
      const response = await fetch("https://onelogicawebsiteserver.azurewebsites.net/submitclientdata-form-newdata", {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        setOpenDialog(true); // Open the dialog

        setFormData({
          email: "",
          ticketName: "",
          ticketDescription: "",
          ticketPriority: "Low",
          file: null,
        });

        // Automatically close the dialog after 3 seconds
        setTimeout(() => {
          setOpenDialog(false);
        }, 3000);
      } else {
        throw new Error("Failed to submit the request.");
      }
    } catch (error) {
      alert("Error submitting the form. Please try again.");
    }
  };

  return (
    <>
      <div className="text-left" style={{ background: "#f6f3f3", marginTop: "4rem", padding: "4rem" }}>
        <Row style={{ maxWidth: "1200px", margin: "auto" }}>
          <Col md={6} className="text-left">
            <h2 className="fw-bold" style={{ textAlign: "left" }}>We're Here to Help</h2>
            <p style={{ textAlign: "left" }}>
              If you're experiencing any issues, please fill out the form. Our support team will get back to you soon.
            </p>
          </Col>

          <Col md={6} style={{ borderRadius: "12px", padding: "2rem", background: "white" }}>
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formTicketName" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Name *"
                  name="ticketName"
                  value={formData.ticketName}
                  onChange={handleChange}
                  isInvalid={!!errors.ticketName}
                />
                <Form.Control.Feedback type="invalid">{errors.ticketName}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="email"
                  placeholder="Email *"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formTicketDescription" className="mb-3">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Describe issue"
                  name="ticketDescription"
                  value={formData.ticketDescription}
                  onChange={handleChange}
                  isInvalid={!!errors.ticketDescription}
                />
                <Form.Control.Feedback type="invalid">{errors.ticketDescription}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formTicketPriority" className="mb-3">
                <Form.Select
                  name="ticketPriority"
                  value={formData.ticketPriority}
                  onChange={handleChange}
                  isInvalid={!!errors.ticketPriority}
                >
                  <option disabled value="">Select priority</option>
                  <option value="Low">Low</option>
                  <option value="Medium">Medium</option>
                  <option value="High">High</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.ticketPriority}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Control type="file" name="file" onChange={handleFileChange} />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-auto mt-3" style={{ borderRadius: "20px" }}>
                Submit Request
              </Button>
            </Form>
          </Col>
        </Row>
      </div>

      <Footer />

      {/* Success Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <Box sx={{ width: 400, textAlign: "center", p: 3 }}>
            <CheckCircleIcon sx={{ fontSize: 50, color: "green" }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              We appreciate your interest!
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Stay tuned — we’ll reach out soon.
            </Typography>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(false)}
              sx={{ mt: 2 }}
            >
              Close
            </Button> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SupportRequestPage;
