// import React, { useState, useRef, useEffect } from 'react';
// import { Navbar, Nav, Container } from 'react-bootstrap';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';
// import './CustomNavbar.css';
// import DropdownMenu from './DropdownMenu'


// // Dropdown data definition
// const productCards = [
//   { to: '/product/glam', title: 'GLAM', content: [
//     { href: '/product/glam', text: 'Real-Time Knowledge Extraction' },
//     { href: '/product/glam', text: 'AI-Powered Information Retrieval' },
//   ]},
//   { to: '/product/vach', title: 'VACH', content: [
//     { href: '/product/vach', text: 'Seamless Omnichannel Experience' },
//     { href: '/product/vach', text: 'AI-Powered Contact Center' },
//   ]},
//   { to: '/product/eas', title: 'EAS', content: [
//     { href: '/product/eas', text: '360° Business Insights' },
//     { href: '/product/eas', text: 'AI-Driven Intelligence' },
//   ]},
//   { to: '/product/doot', title: 'DOOT', content: [
//     { href: '/product/doot', text: 'Gen AI-Powered Enterprise Chatbot' },
//     { href: '/product/doot', text: 'Seamless NLP Interaction' },
//   ]},
//   { to: '/product/supply-chain', title: 'Retail-Supply Chain', content: [
//     { href: '/product/supply-chain', text: 'Actionable Business Insights' },
//     { href: '/product/supply-chain', text: 'Enhanced Profitability & Efficiency' },
//   ]},
//   { to: '/product/esg', title: 'ESG', content: [
//     { href: '/product/esg', text: 'Sustainability & Compliance Tracking' },
//     { href: '/product/esg', text: 'Responsible & Ethical Business Practice' },
//   ]},
// ];

// const industryCards = [
//   { to: '/bfsi', title: 'BFSI', content: [
//     { href: '/bfsi', text: 'Automated Risk Management' },
//     { href: '/bfsi', text: 'Regulatory Compliance' },
//   ]},
//   { to: '/energy', title: 'Energy', content: [
//     { href: '/energy', text: 'Predictive Maintenance' },
//     { href: '/energy', text: 'Smart Grid Management' },
//   ]},
//   { to: '/manufacturing', title: 'Manufacturing', content: [
//     { href: '/manufacturing', text: 'IoT-Driven Automation' },
//     { href: '/manufacturing', text: 'Supply Chain Optimization' },
//   ]},
//   { to: '/retail', title: 'Retail', content: [
//     { href: '/retail', text: 'Personalized Customer Experience' },
//     { href: '/retail', text: 'Inventory Management Optimization' },
//   ]},
// ];

// const partnershipCards = [
//   { to: { pathname: '/partner/microsoft', state: { partner: 'Azure' } }, title: 'Azure', content: [
//     { href: '/partner/microsoft', text: 'Enterprise AI & Cloud Powerhouse' },
//     { href: '/partner/microsoft', text: 'Agentic AI Innovation' },
//   ]},
//   { to: '/partner/databricks', title: 'Databricks', content: [
//     { href: '/partner/databricks', text: 'Lakehouse Leader & AI-Powered Analytics' },
//     { href: '/partner/databricks', text: 'Unified Data & Machine Learning Platform' },
//   ]},
//   { to: '/partner/salesforce', title: 'Salesforce', content: [
//     { href: '/partner/salesforce', text: 'AI-Driven CRM & Customer 360' },
//     { href: '/partner/salesforce', text: 'Comprehensive App Ecosystem' },
//   ]},
//   { to: '/partner/aws', title: 'AWS', content: [
//     { href: '/partner/aws', text: 'Cloud of Choice & Scalable Innovation' },
//     { href: '/partner/aws', text: 'Gen AI & ML Industry Leader' },
//   ]},
// ];

// const resourceCards = [
//   { to: '/resource/training', title: 'Training', content: [
//     { href: '/resource/training', text: 'Expert-led learning' },
//     { href: '/resource/training', text: 'Skill enhancement' },
//   ]},
//   { to: '/resource/caseStudies', title: 'Case Studies', content: [
//     { href: '/resource/caseStudies', text: 'Real-world insights' },
//     { href: '/resource/caseStudies', text: 'Proven success' },
//   ]},
//   { to: '/resource/blogs', title: 'Blogs', content: [
//     { href: '/resource/blogs', text: 'Industry trends' },
//     { href: '/resource/blogs', text: 'Tech innovations' },
//   ]},
// ];

// // Note: 'service' dropdown is included in the original code but not in nav items, so it's added here for completeness
// const serviceCards = [
//   { to: '/partnership', title: 'Strategy & Advisory', content: [
//     { href: '/product/glam', text: 'Cloud Platform & Services' },
//     { href: '#', text: 'Integration with Microsoft Products' },
//   ]},
//   { to: '/vach', title: 'Engineer Your Data', content: [
//     { href: '/vach', text: 'Voice & calls' },
//     { href: '#', text: 'Real Time Reports and dashboards' },
//   ]},
//   { to: '/doot', title: 'Differentiate with AI/ML', content: [
//     { href: '/doot', text: 'Unified Data Analytics' },
//     { href: '#', text: 'Collaboration & Notebooks' },
//   ]},
//   { to: '/isam', title: 'Operationalize Insights', content: [
//     { href: '/isam', text: 'Customer Relationship Management (CRM)' },
//     { href: '#', text: 'Comprehensive App Ecosystem' },
//   ]},
// ];

// const dropdownData = {
//   product: { videoSrc: '../videos/product.mp4', cards: productCards },
//   industry: { videoSrc: '../videos/ailaptopindustry.mp4', cards: industryCards },
//   Partnership: { videoSrc: '../videos/partnership.mp4', cards: partnershipCards },
//   training: { videoSrc: '../videos/resourcevideo.mp4', cards: resourceCards },
//   service: { videoSrc: '../videos/servicevideo.mp4', cards: serviceCards },
// };

// // NavigationBar Component: Manages the main navigation and dropdown state
// const NavigationBar = () => {
//   const [expanded, setExpanded] = useState(false);
//   const [activeDropdown, setActiveDropdown] = useState(null);
//   const dropdownRef = useRef(null);

//   // Handle navbar toggle for mobile view
//   const handleToggle = () => {
//     setExpanded(!expanded);
//     if (expanded) setActiveDropdown(null); // Close dropdown when collapsing navbar
//   };

//   // Toggle dropdown visibility
//   const toggleDropdown = (dropdown) => {
//     setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
//   };

//   // Close dropdown on click outside or scroll
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setActiveDropdown(null);
//       }
//     };
//     const handleScroll = () => setActiveDropdown(null);

//     document.addEventListener('mousedown', handleClickOutside);
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       {/* SEO Helmet */}
//       <Helmet>
//         <title>Onelogica</title>
//         <meta name="description" content="onelogica.com" />
//         <link rel="canonical" href="https://www.onelogica.com/" />
//       </Helmet>

//       <Navbar
//         expand="lg"
//         className="custom-navbar"
//         fixed="top"
//         variant="dark"
//         expanded={expanded}
//       >
//         <Container fluid className="d-flex align-items-center">
//           {/* Logo */}
//           <Navbar.Brand href="/" className="position-relative">
//             <img src="../images/logo1.png" alt="Logo" className="logo-image" />
//           </Navbar.Brand>

//           {/* Navbar Toggler */}
//           <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} />

//           {/* Navbar Items */}
//           <Navbar.Collapse id="navbar-nav">
//             <Nav className="me-auto align-items-center">
//               <Nav.Item
//                 className="nav-item"
//                 onClick={() => toggleDropdown('product')}
//                 style={{ cursor: 'pointer', position: 'relative' }}
//               >
//                 <span className="nav-link">
//                   Product <ExpandMoreIcon />
//                 </span>
//               </Nav.Item>
//               <Nav.Item
//                 className="nav-item"
//                 onClick={() => toggleDropdown('industry')}
//                 style={{ cursor: 'pointer', position: 'relative' }}
//               >
//                 <span className="nav-link">
//                   Industry <ExpandMoreIcon />
//                 </span>
//               </Nav.Item>
//               <Nav.Item
//                 className="nav-item"
//                 onClick={() => toggleDropdown('Partnership')}
//                 style={{ cursor: 'pointer', position: 'relative' }}
//               >
//                 <span className="nav-link">
//                   Partnership <ExpandMoreIcon />
//                 </span>
//               </Nav.Item>
//               <Nav.Item
//                 className="nav-item"
//                 onClick={() => toggleDropdown('training')}
//                 style={{ cursor: 'pointer', position: 'relative' }}
//               >
//                 <span className="nav-link">
//                   Resource <ExpandMoreIcon />
//                 </span>
//               </Nav.Item>
           
//               <Nav.Link href="/career" className="text-white">
//                 Career
//               </Nav.Link>
//               <Nav.Link href="/aboutus" className="text-white">
//                 About
//               </Nav.Link>
//             </Nav>

//             {/* Render DropdownMenu when a dropdown is active */}
//             {activeDropdown && (
//               <DropdownMenu
//                 ref={dropdownRef}
//                 videoSrc={dropdownData[activeDropdown].videoSrc}
//                 cards={dropdownData[activeDropdown].cards}
//                 onClose={() => setActiveDropdown(null)}
//                 isOpen={true}
//               />
//             )}
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>
//     </>
//   );
// };

// export default NavigationBar;


"use client"

import { useState, useRef, useEffect } from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async"
import "./CustomNavbar.css"
import DropdownMenu from "./DropdownMenu"
import MobileDropdown from "./MobileNavigation"
import { IoMenu, IoClose } from "react-icons/io5"; // Import menu & close icons
// Dropdown data definition
const productCards = [
  {
    to: "/product/glam",
    title: "GLAM",
    content: [
      { href: "/product/glam", text: "Real-Time Knowledge Extraction" },
      { href: "/product/glam", text: "AI-Powered Information Retrieval" },
    ],
  },
  {
    to: "/product/vach",
    title: "VACH",
    content: [
      { href: "/product/vach", text: "Seamless Omnichannel Experience" },
      { href: "/product/vach", text: "AI-Powered Contact Center" },
    ],
  },
  {
    to: "/product/eas",
    title: "EAS",
    content: [
      { href: "/product/eas", text: "360° Business Insights" },
      { href: "/product/eas", text: "AI-Driven Intelligence" },
    ],
  },
  {
    to: "/product/doot",
    title: "DOOT",
    content: [
      { href: "/product/doot", text: "Gen AI-Powered Enterprise Chatbot" },
      { href: "/product/doot", text: "Seamless NLP Interaction" },
    ],
  },
  {
    to: "/product/supply-chain",
    title: "Retail-Supply Chain",
    content: [
      { href: "/product/supply-chain", text: "Actionable Business Insights" },
      { href: "/product/supply-chain", text: "Enhanced Profitability & Efficiency" },
    ],
  },
  {
    to: "/product/esg",
    title: "ESG",
    content: [
      { href: "/product/esg", text: "Sustainability & Compliance Tracking" },
      { href: "/product/esg", text: "Responsible & Ethical Business Practice" },
    ],
  },
]

const industryCards = [
  {
    to: "/bfsi",
    title: "BFSI",
    content: [
      { href: "/bfsi", text: "Automated Risk Management" },
      { href: "/bfsi", text: "Regulatory Compliance" },
    ],
  },
  {
    to: "/energy",
    title: "Energy",
    content: [
      { href: "/energy", text: "Predictive Maintenance" },
      { href: "/energy", text: "Smart Grid Management" },
    ],
  },
  {
    to: "/manufacturing",
    title: "Manufacturing",
    content: [
      { href: "/manufacturing", text: "IoT-Driven Automation" },
      { href: "/manufacturing", text: "Supply Chain Optimization" },
    ],
  },
  {
    to: "/retail",
    title: "Retail",
    content: [
      { href: "/retail", text: "Personalized Customer Experience" },
      { href: "/retail", text: "Inventory Management Optimization" },
    ],
  },
]

const partnershipCards = [
  {
    to: { pathname: "/partner/microsoft", state: { partner: "Azure" } },
    title: "Azure",
    content: [
      { href: "/partner/microsoft", text: "Enterprise AI & Cloud Powerhouse" },
      { href: "/partner/microsoft", text: "Agentic AI Innovation" },
    ],
  },
  {
    to: "/partner/databricks",
    title: "Databricks",
    content: [
      { href: "/partner/databricks", text: "Lakehouse Leader & AI-Powered Analytics" },
      { href: "/partner/databricks", text: "Unified Data & Machine Learning Platform" },
    ],
  },
  {
    to: "/partner/salesforce",
    title: "Salesforce",
    content: [
      { href: "/partner/salesforce", text: "AI-Driven CRM & Customer 360" },
      { href: "/partner/salesforce", text: "Comprehensive App Ecosystem" },
    ],
  },
  {
    to: "/partner/aws",
    title: "AWS",
    content: [
      { href: "/partner/aws", text: "Cloud of Choice & Scalable Innovation" },
      { href: "/partner/aws", text: "Gen AI & ML Industry Leader" },
    ],
  },
]

const resourceCards = [
  {
    to: "/resource/training",
    title: "Training",
    content: [
      { href: "/resource/training", text: "Expert-led learning" },
      { href: "/resource/training", text: "Skill enhancement" },
    ],
  },
  {
    to: "/resource/caseStudies",
    title: "Case Studies",
    content: [
      { href: "/resource/caseStudies", text: "Real-world insights" },
      { href: "/resource/caseStudies", text: "Proven success" },
    ],
  },
  {
    to: "/resource/blogs",
    title: "Blogs",
    content: [
      { href: "/resource/blogs", text: "Industry trends" },
      { href: "/resource/blogs", text: "Tech innovations" },
    ],
  },
]

const dropdownData = {
  product: { videoSrc: "../videos/product.mp4", cards: productCards },
  industry: { videoSrc: "../videos/ailaptopindustry.mp4", cards: industryCards },
  Partnership: { videoSrc: "../videos/partnership.mp4", cards: partnershipCards },
  training: { videoSrc: "../videos/resourcevideo.mp4", cards: resourceCards },
}

// NavigationBar Component: Manages the main navigation and dropdown state
const NavigationBar = () => {
  const [expanded, setExpanded] = useState(false)
  const [activeDropdown, setActiveDropdown] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000)
  const dropdownRef = useRef(null)
    // New state for controlling mobile dropdowns
    const [openMobileDropdown, setOpenMobileDropdown] = useState(null);
  const location = useLocation(); // Track route changes
  // Check if mobile on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Handle navbar toggle for mobile/desktop
  const handleToggle = () => {
    setExpanded(!expanded);
    if (expanded) {
      setActiveDropdown(null); // Close desktop dropdown
      setOpenMobileDropdown(null); // Close mobile dropdowns
    }
  };
  // Close navbar on route change
  useEffect(() => {
    setExpanded(false);
    setActiveDropdown(null);
    setOpenMobileDropdown(null);
  }, [location])

  // Toggle dropdown visibility
  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown))
  }
  // Toggle mobile dropdown visibility
  const toggleMobileDropdown = (dropdown) => {
    setOpenMobileDropdown((prev) => (prev === dropdown ? null : dropdown));
  };
  // Close dropdown on click outside or scroll
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null)
      }
    }
    const handleScroll = () => setActiveDropdown(null)

    document.addEventListener("mousedown", handleClickOutside)
    window.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {/* SEO Helmet */}
      <Helmet>
        <title>Onelogica</title>
        <meta name="description" content="onelogica.com" />
        <link rel="canonical" href="https://www.onelogica.com/" />
      </Helmet>

      <Navbar expand="lg" className="custom-navbar" fixed="top" variant="dark" expanded={expanded}>
        <Container fluid className="d-flex align-items-center">
          {/* Logo */}
          <Navbar.Brand href="/" className="position-relative">
            <img src="../images/logo1.png" alt="Logo" className="logo-image" />
          </Navbar.Brand>

          {/* Navbar Toggler */}
          {/* <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} /> */}
           {/* Animated Toggle Button */}
           <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} className="custom-toggler">
            {expanded ? <IoClose size={30} /> : <IoMenu size={30} />}
          </Navbar.Toggle>

          {/* Navbar Items */}
          <Navbar.Collapse id="navbar-nav">
            {!isMobile ? (
              // Desktop Navigation
              <Nav className="me-auto align-items-center">
                <Nav.Item
                  className="nav-item"
                  onClick={() => toggleDropdown("product")}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <span className="nav-link">
                    Product <ExpandMoreIcon />
                  </span>
                </Nav.Item>
                <Nav.Item
                  className="nav-item"
                  onClick={() => toggleDropdown("industry")}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <span className="nav-link">
                    Industry <ExpandMoreIcon />
                  </span>
                </Nav.Item>
                <Nav.Item
                  className="nav-item"
                  onClick={() => toggleDropdown("Partnership")}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <span className="nav-link">
                    Partnership <ExpandMoreIcon />
                  </span>
                </Nav.Item>
                <Nav.Item
                  className="nav-item"
                  onClick={() => toggleDropdown("training")}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <span className="nav-link">
                    Resource <ExpandMoreIcon />
                  </span>
                </Nav.Item>

                <Nav.Link href="/career" className="text-white">
                  Career
                </Nav.Link>
                <Nav.Link href="/aboutus" className="text-white">
                  About
                </Nav.Link>
              </Nav>
            ) : (
              // Mobile Navigation with Yellow.ai style dropdowns
             <div className="w-100">
                <MobileDropdown
                  title="Product"
                  items={productCards}
                  isOpen={openMobileDropdown === "Product"}
                  onToggle={() => toggleMobileDropdown("Product")}
                />
                <MobileDropdown
                  title="Industry"
                  items={industryCards}
                  isOpen={openMobileDropdown === "Industry"}
                  onToggle={() => toggleMobileDropdown("Industry")}
                />
                <MobileDropdown
                  title="Partnership"
                  items={partnershipCards}
                  isOpen={openMobileDropdown === "Partnership"}
                  onToggle={() => toggleMobileDropdown("Partnership")}
                />
                <MobileDropdown
                  title="Resource"
                  items={resourceCards}
                  isOpen={openMobileDropdown === "Resource"}
                  onToggle={() => toggleMobileDropdown("Resource")}
                />

                <div className="mobile-dropdown">
                  <Link to="/career" className="mobile-dropdown-button">
                    <span>Career</span>
                  </Link>
                </div>

                <div className="mobile-dropdown">
                  <Link to="/aboutus" className="mobile-dropdown-button">
                    <span>About</span>
                  </Link>
                </div>
              </div>
            )}

            {/* Render DropdownMenu when a dropdown is active (desktop only) */}
            {!isMobile && activeDropdown && (
              <DropdownMenu
                ref={dropdownRef}
                videoSrc={dropdownData[activeDropdown].videoSrc}
                cards={dropdownData[activeDropdown].cards}
                onClose={() => setActiveDropdown(null)}
                isOpen={true}
              />
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default NavigationBar

