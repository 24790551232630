import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CaseStudiesSection from './CaseStudiesSection'
import './caseStudies.css'; // Add custom CSS for case studies if needed
import Footer from "../../Footer/FooterSection";

const CaseStudiesPage = () => {
  const navigate = useNavigate();

  // Dummy case studies data
  const caseStudies = [
    {
      id: 1,
      title: "AI-Driven Healthcare Solutions",
      description: "This case study explores the application of AI in the healthcare industry, focusing on predictive analytics and automated diagnosis systems.",
      category: "AI",
      image: "https://via.placeholder.com/300x200?text=AI+Healthcare",
    },
    {
      id: 2,
      title: "Cloud Infrastructure Transformation",
      description: "This case study examines how cloud technologies were used to transform the IT infrastructure of a large corporation, improving scalability and cost-efficiency.",
      category: "Cloud",
      image: "https://via.placeholder.com/300x200?text=Cloud+Infrastructure",
    },
    {
      id: 3,
      title: "E-commerce Development & Optimization",
      description: "This case study delves into the development and optimization of an e-commerce platform that dramatically improved user experience and conversion rates.",
      category: "Development",
      image: "https://via.placeholder.com/300x200?text=E-commerce+Development",
    },
    {
      id: 4,
      title: "Data Analytics for Business Growth",
      description: "This case study highlights how data analytics was used to drive business growth by optimizing marketing strategies and improving customer insights.",
      category: "Data Analytics",
      image: "https://via.placeholder.com/300x200?text=Data+Analytics",
    },
    // Add more case studies as needed
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const caseStudiesPerPage = 4;

  // Paginate case studies
  const indexOfLastCaseStudy = currentPage * caseStudiesPerPage;
  const indexOfFirstCaseStudy = indexOfLastCaseStudy - caseStudiesPerPage;
  const currentCaseStudies = caseStudies.slice(indexOfFirstCaseStudy, indexOfLastCaseStudy);

  const totalPages = Math.ceil(caseStudies.length / caseStudiesPerPage);

  return (
    <div>
      {/* Hero Section */}
      <div
        className="header-banner text-white text-center d-flex flex-column align-items-center justify-content-center"
        style={{ flexDirection: "column" }}
      >
        <div>
          <h1 className="hero-title">Our Case Studies</h1>
          <p className="hero-description">
            Discover how our innovative solutions have helped businesses across various industries.
          </p>
        </div>
      </div>

<CaseStudiesSection />
<Footer />
    </div>
  );
};

export default CaseStudiesPage;
