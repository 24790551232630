import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../../Footer/FooterSection';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from '@material-ui/core';
import { BsLightningCharge, BsShieldLock, BsGraphUp, BsBoxSeam } from "react-icons/bs";
import "../glam/glam.css";
import { Helmet } from 'react-helmet-async';
import { QuestionAnswer, Translate } from '@material-ui/icons';
import { Insights, Summarize } from '@mui/icons-material';
import VideoSection from '../common/VideoCOmponent';
import EasBenefit from './EasBenefit';
import ComprehensiveAnalytics from './EasAnalytics';
import FeaturesSection from './FeatureSection';
import RequestButton from '../common/RequestButton';
import VideoShowcase from '../video/video-showcase';
import ContactSection from '../common/ContactSection';


const EasLandingPage = () => {
    return (
        <>
            <Helmet>
                <title>Product | EAS</title>
                <meta name="description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="keywords" content="AI, analytics, document translation, summarization, insights, Q&A, GLAM" />
                <meta name="robots" content="index, follow" />
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content="OneLogica Team" />

                {/* Open Graph */}
                <meta property="og:title" content="Product | EAS" />
                <meta property="og:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta property="og:image" content="URL_of_image.jpg" />
                <meta property="og:url" content="https://www.onelogica.com/product/eas" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="OneLogica" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Product | EAS" />
                <meta name="twitter:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
                <meta name="twitter:image" content="URL_of_image.jpg" />
                <meta name="twitter:site" content="@OneLogica" />

                <link rel="canonical" href="https://www.onelogica.com/product/eas" />
            </Helmet>

            {/* Hero Section */}
            <Container fluid className="hero-section   text-white  product-container" style={{
                backgroundImage: `url(../newimages/easbg.png)`,
                // filter: "brightness(75%)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                marginTop: "3rem",
                height: "95vh"
            }}>
                <Row className="align-items-center justify-content-center" style={{width:"100%"}}>
                    <Col md={12} className="text-left p-5"  >
                    {/* <h6 className="display-5" style={{ fontWeight: "400", color: "white",alignItems:'center' }}>
                            EAS
                        </h6> */}
                        <h6 className="display-5" style={{ fontWeight: "400", color: "white",alignItems:'center' }}>Enterprise
                            Analytics Suite  (EAS)
                        </h6>
                     <RequestButton href="/product/support">Request Demo</RequestButton>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="hero-section  text-white  product-container" style={{ background: "linear-gradient(45deg,rgb(3, 4, 86), rgb(0, 120, 237))" }}>
                <Row>
                    <Col md={12} className="text-left p-5" >
                        {/* <h6 className="display-5" style={{ fontWeight: "400", color: " #d19e94" }}>Generative Language based Analytics and Maximization</h6> */}
                        <p className="lead" style={{ fontSize: "18px", }}>EAS by Onelogica empowers
                            organizations with cutting-edge
                            solutions, providing 360-degree
                            visibility into Finance, Sales, IT, HR,
                            and Marketing. This plug-and-play
                            solution seamlessly integrates with
                            your enterprise data, leveraging
                            advanced Data Engineering and Data
                            Science techniques to pinpoint
                            challenges, risks, and growth
                            opportunities. together!
                        </p>
                    </Col>
                </Row>
            </Container>
            <EasBenefit/>
            <ComprehensiveAnalytics/>
            <FeaturesSection/>
             <VideoShowcase title="EAS delivers AI-powered insights to solve challenges and maximize enterprise data potential" description="Enterprise Analytics Suite (EAS) by Onelogica is an advanced AI and machine learning-powered platform, designed to offer deep insights across all core business areas, such as Finance, Sales, IT, HR, and Marketing." videoUrl="https://www.youtube.com/watch?v=UKUmjrZyPd4"/>
             <ContactSection/>
            <Footer />
        </>
    );
};

export default EasLandingPage;
