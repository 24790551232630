import React from "react";
import "./FeatureCard.css";

const FeatureCard = ({ feature }) => {
  return (
    <div className="feature-card">
      <div className="feature-card__image">
        <img src={feature.image || "/placeholder.svg"} alt={feature.title} />
      </div>

      <div className="feature-card__content">
        <h3 className="feature-card__title">{feature.title}</h3>
        <p className="feature-card__description">{feature.description}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
