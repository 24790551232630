import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./videosection.css";

const VideoSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 300);
  }, []);

  return (
    <section className="video-section">
      <div className="container">
        <div className="row align-items-center">
          {/* Text Section */}
          <div className={`col-lg-6 text-content ${isVisible ? "fade-in" : ""}`}>
            <div className="text-box">
              <h2 className="title">Explore Our Product & Solutions</h2>
              <p className="description">
                Watch our detailed video to learn how our solutions can help your business grow efficiently.
              </p>
            </div>
          </div>

          {/* Video Section */}
          <div className="col-lg-6 position-relative video-container">
            <div className="video-wrapper">
              <iframe
                src="https://www.youtube.com/embed/9FH-3r9h_D8"
                title="Product & Solution Video"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
