import React from 'react';
import IndustryPage from "./IndustryPage";
import {
  Web, // Website & UX Analysis
  PersonSearch, // Customer Lifetime Value Analysis
  Share, // Social Media Analysis
  ThumbUp, // Personalized Recommendations
  Timeline, // Customer Journey Analysis
  Groups, // Customer Segmentation
  Gavel, // Risk Management
  Build, // Predictive Maintenance
  Campaign, // Marketing Campaign
  MonetizationOn, // Credit Risk Analysis
} from "@mui/icons-material";

const Retail = () => {
  const headingText = "Retail and E-commerce";
  const introText = `"The retail and e-commerce industry are composed of businesses engaged in the sale of goods and services through physical stores or online platforms. It encompasses a wide range of businesses, from large multinational retailers to small independent sellers."`;
  const paragraphText = `
  In 2022, the global retail industry is valued at $26 trillion, with e-commerce at $4.5 trillion. Challenges include competition, changing consumer behavior, supply chain disruptions, and rising costs. To ensure fairness and protect consumers, the industry adheres to standards like data privacy, online payment, product safety, and intellectual property protection. Adherence to these standards is crucial for consumer trust and industry growth. Despite challenges, the retail and e-commerce sector is steadily growing, expected to reach $6.4 trillion in global e-commerce sales by 2024. The industry remains adaptable, evolving to meet consumer needs, leveraging new technologies, and overcoming challenges like supply chain disruptions, cybersecurity threats, and regulatory pressures.`;
  const backgroundImage = "../images/retail_bg.jpg";
  const sectionImage = "../industry/retailndaecommerce.jpg";
  const carouselImages = [
    "../industry/ecommerceplatform.jpg",
      "../industry/cpg.jpg",
      "../industry/supplychainoptimzation.jpg",
  ];

  const gridItems = [
    {
      title: "E-commerce Platforms",
      description:
        "Enhance your online presence and user experience with cutting-edge e-commerce platform solutions.",
      additionalImage: "../videos/Ecom_gif.gif",
      img:"../industry/ecommerceplatform.jpg",
      useCases: [
        { title: "Website and User Experience Analysis", icon: <Web /> },
        { title: "Customer Lifetime Value Analysis", icon: <PersonSearch /> },
        { title: "Social Media Analysis", icon: <Share /> },
        { title: "Personalized Recommendations", icon: <ThumbUp /> },
        { title: "Customer Journey Analysis", icon: <Timeline /> },
      ],
    },
    {
      title: "CPG (Consumer Packaged Goods)",
      description:
        "CPG refers to consumer packaged goods that are sold regularly to consumers and designed for frequent use.",
      additionalImage: "../videos/CPG_gif.gif",
      img: "../industry/cpg.jpg",
      useCases: [
        { title: "Customer Segmentation", icon: <Groups /> },
        { title: "Risk Management", icon: <Gavel /> },
        { title: "Predictive Maintenance", icon: <Build /> },
        { title: "Marketing Campaign", icon: <Campaign /> },
        { title: "Credit Risk Analysis", icon: <MonetizationOn /> },
      ],
    },
    {
      title: "Supply Chain Optimization",
      description:
        "Optimize your supply chain processes to ensure efficiency and meet the demands of the digital marketplace.",
      additionalImage: "../videos/SupplyChain_gif.gif",
      img:"../industry/supplychainoptimzation.jpg",
      useCases: [
        { title: "Customer Segmentation", icon: <Groups /> },
        { title: "Risk Management", icon: <Gavel /> },
        { title: "Predictive Maintenance", icon: <Build /> },
        { title: "Marketing Campaign", icon: <Campaign /> },
        { title: "Credit Risk Analysis", icon: <MonetizationOn /> },
      ],
    },
  ];
  

  return (
    <IndustryPage
      headingText={headingText}
      introText={introText}
      paragraphText={paragraphText}
      backgroundImage={backgroundImage}
      sectionImage={sectionImage}
      carouselImages={carouselImages}
      gridItems={gridItems}
    />
  );
};

export default Retail;



