// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker {
    width: 100% !important;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    outline: none;
    background: #ffffffc2;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
  }
  
  .date-picker:focus {
    border-color: #0078ED;
    box-shadow: 0 0 3px rgba(0, 120, 237, 0.5);
  }
  
  .react-datepicker-wrapper {
    width: 100%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    height: 40px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #b0afaf;
    font-size: 17px;
    font-family: "Roboto", sans-serif;
    outline: none;
    background: none;
  }
  
  .react-datepicker__input-container input:focus {
    border-color: #0078ED;
    box-shadow: 0 0 3px rgba(0, 120, 237, 0.5);
  }
  
  .mobileInput .react-tel-input .form-control{
    height:40px !important;
    border: 1px solid #b0afaf;
  }`, "",{"version":3,"sources":["webpack://./src/components/HomePage/contactForm.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,eAAe;IACf,iCAAiC;IACjC,aAAa;IACb,qBAAqB;IACrB,mCAA2B;YAA3B,2BAA2B;EAC7B;;EAEA;IACE,qBAAqB;IACrB,0CAA0C;EAC5C;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,iCAAiC;IACjC,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,0CAA0C;EAC5C;;EAEA;IACE,sBAAsB;IACtB,yBAAyB;EAC3B","sourcesContent":[".date-picker {\r\n    width: 100% !important;\r\n    height: 40px;\r\n    padding: 10px;\r\n    border-radius: 4px;\r\n    border: 1px solid #ccc;\r\n    font-size: 12px;\r\n    font-family: \"Roboto\", sans-serif;\r\n    outline: none;\r\n    background: #ffffffc2;\r\n    backdrop-filter: blur(10px);\r\n  }\r\n  \r\n  .date-picker:focus {\r\n    border-color: #0078ED;\r\n    box-shadow: 0 0 3px rgba(0, 120, 237, 0.5);\r\n  }\r\n  \r\n  .react-datepicker-wrapper {\r\n    width: 100%;\r\n  }\r\n  \r\n  .react-datepicker__input-container input {\r\n    width: 100%;\r\n    height: 40px;\r\n    padding: 10px;\r\n    border-radius: 4px;\r\n    border: 1px solid #b0afaf;\r\n    font-size: 17px;\r\n    font-family: \"Roboto\", sans-serif;\r\n    outline: none;\r\n    background: none;\r\n  }\r\n  \r\n  .react-datepicker__input-container input:focus {\r\n    border-color: #0078ED;\r\n    box-shadow: 0 0 3px rgba(0, 120, 237, 0.5);\r\n  }\r\n  \r\n  .mobileInput .react-tel-input .form-control{\r\n    height:40px !important;\r\n    border: 1px solid #b0afaf;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
