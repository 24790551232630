// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-section {
    background: linear-gradient(45deg, #a05353b0, #568ac37a);
  }
  
  .contact-img {
    width: 100%;
    /* max-width: 500px; */
    /* height: 70vh;
    width: 45vw; */
    border-radius: 14px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .contact-text {
    background-color:#ffffff47;
    color: #000000;
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    border: 3px solid transparent;
    /* border-image: linear-gradient(135deg, rgb(121, 113, 224), #feb47b) 1 */
    border-radius: 14px;
    text-align: left;
  }
  
  .contact-btn {
    background-color: #0078ED;
    color: white;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    transition: 0.3s ease-in-out;
  }
  
  .contact-btn:hover {
    background-color: #005bbd;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .contact-text {
      padding: 1.5rem;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProductandSolution/common/contactsection.css"],"names":[],"mappings":"AAAA;IACI,wDAAwD;EAC1D;;EAEA;IACE,WAAW;IACX,sBAAsB;IACtB;kBACc;IACd,mBAAmB;IACnB,yCAAyC;EAC3C;;EAEA;IACE,0BAA0B;IAC1B,cAAc;IACd,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,6BAA6B;IAC7B,yEAAyE;IACzE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,4BAA4B;EAC9B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA,2BAA2B;EAC3B;IACE;MACE,eAAe;IACjB;EACF","sourcesContent":[".contact-section {\r\n    background: linear-gradient(45deg, #a05353b0, #568ac37a);\r\n  }\r\n  \r\n  .contact-img {\r\n    width: 100%;\r\n    /* max-width: 500px; */\r\n    /* height: 70vh;\r\n    width: 45vw; */\r\n    border-radius: 14px;\r\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);\r\n  }\r\n  \r\n  .contact-text {\r\n    background-color:#ffffff47;\r\n    color: #000000;\r\n    padding: 2rem;\r\n    width: 100%;\r\n    max-width: 500px;\r\n    border: 3px solid transparent;\r\n    /* border-image: linear-gradient(135deg, rgb(121, 113, 224), #feb47b) 1 */\r\n    border-radius: 14px;\r\n    text-align: left;\r\n  }\r\n  \r\n  .contact-btn {\r\n    background-color: #0078ED;\r\n    color: white;\r\n    font-weight: bold;\r\n    padding: 10px 20px;\r\n    border-radius: 8px;\r\n    text-decoration: none;\r\n    transition: 0.3s ease-in-out;\r\n  }\r\n  \r\n  .contact-btn:hover {\r\n    background-color: #005bbd;\r\n  }\r\n  \r\n  /* Responsive Adjustments */\r\n  @media (max-width: 768px) {\r\n    .contact-text {\r\n      padding: 1.5rem;\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
