import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from '@material-ui/core';

const RequestButton = ({ href, children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const baseStyle = {
    borderRadius: '20px',
    background: isHovered
      ? 'linear-gradient(90deg, #ff7e5f, #feb47b)' // Gradient on hover
      : 'linear-gradient(90deg, #6a11cb, #2575fc)', // Initial gradient
    color: '#fff',
    border: 'none',
    fontWeight: 500,
    transition: 'background 0.5s ease',
    marginTop: '1.5rem',
    padding: '10px 18px',
  };

  return (
    <Link href={href} passHref>
      <Button
        variant="light"
        size="md"
        style={baseStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...props}
      >
        {children}
      </Button>
    </Link>
  );
};

export default RequestButton;
