import React from 'react';
import PropertyCard from './ProcessCard';

// Image paths for our sample properties
const propertyImages = [
  'https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  'https://images.unsplash.com/photo-1560449752-3fd74f6a3ffc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
  'https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
];

const PropertiesSection = () => {
  // Sample property data
  const properties = [
    {
      image: propertyImages[0],
      price: '$2,950,000',
      location: 'Modern Penthouse, LA',
      beds: 3,
      baths: 2.5,
      sqft: 2250
    },
    {
      image: propertyImages[1],
      price: '$2,500,000',
      location: 'Downtown Manhattan',
      beds: 3,
      baths: 2.5,
      sqft: 2200
    },
    {
      image: propertyImages[2],
      price: '$1,850,000',
      location: 'Beverly Hills',
      beds: 4,
      baths: 3.5,
      sqft: 3100
    }
  ];

  return (
    <section className="properties-section py-5">
      <div className="container">
        <div className="row mb-5">
          <div className="col-12 text-center">
            <h2 className="section-title">Featured <span>Properties</span></h2>
          </div>
        </div>
        <div className="row">
          {properties.map((property, index) => (
            <div key={index} className="col-md-4">
              <PropertyCard 
                image={property.image} 
                price={property.price} 
                location={property.location} 
                beds={property.beds} 
                baths={property.baths} 
                sqft={property.sqft} 
              />
            </div>
          ))}
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <div className="contact-section">
              <h3 className="mb-3">Get in Touch</h3>
              <p>Ready to start your journey towards your dream home? Contact us today.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertiesSection;