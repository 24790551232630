"use client"

import { useState } from "react"
import "./CaseStudiesSection.css"
import DataAnalytics from '../../../assest/icons/Data Analytic.png'
// Dummy case studies data
const caseStudies = [
  {
    id: 1,
    title: "Data Analytics for Retail Growth",
    tag: "Data Analytics",
    description:
      "This case study explores how AI-powered analytics helps retailers enhance customer insights by unifying data sources across their business. With Microsoft data solutions, companies improved engagement, optimized store operations, and strengthened security, leading to increased sales and customer retention.",
    image: "https://www.institutedata.com/wp-content/uploads/2023/08/Data-privacy-and-security-concerns.png",
    slug: "data-analytics-retail-growth",
  },
  {
    id: 2,
    title: "Revolutionizing Retail with Data Analytics",
    tag: "Data Analytics",
    description:
      "This case study highlights how advanced data analytics boosts revenue growth by improving customer experience, optimizing inventory, and refining marketing strategies. AI-driven insights streamline workflows, enhance engagement, and support data-driven decision-making for seamless retail operations.",
    image: "https://i0.wp.com/blog.nashtechglobal.com/wp-content/uploads/2024/01/1-28.jpg?fit=1024%2C536&ssl=1",
    slug: "retail-data-analytics-revolution",
  },
  {
    id: 3,
    title: "AI and Data Analytics in Retail",
    tag: "AI & Data Analytics",
    description:
      "This case study explores how AI and data analytics drive retail growth by providing a unified data foundation, real-time insights, and personalized customer experiences. AI-powered strategies optimize e-commerce, automate workflows, enhance search, and drive innovation across retail operations.",
    image: "https://www.immerse.education/wp-content/uploads/2022/11/Website-Images.jpg",
    slug: "ai-data-analytics-retail",
  }
];

function CaseStudiesSection() {
  const [currentPage, setCurrentPage] = useState(1)
  const casesPerPage = 4
  const totalPages = Math.ceil(caseStudies.length / casesPerPage)

  const indexOfLastCase = currentPage * casesPerPage
  const indexOfFirstCase = indexOfLastCase - casesPerPage
  const currentCases = caseStudies.slice(indexOfFirstCase, indexOfLastCase)

  const nextPage = () => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))
  const prevPage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))

  return (
    <section className="case-studies-section">
      <h2 className="case-studies-title">Proven Success Stories</h2>
      <p className="case-studies-subtitle">
      Explore how our strategic solutions have driven growth and innovation across industries.
      </p>

      <div className="case-studies-grid">
        {currentCases.map((caseStudy) => (
          <div key={caseStudy.id} className="case-study-card">
            <div className="case-study-image-container">
              <span className="case-study-tag">{caseStudy.tag}</span>
              <div className="placeholder-image">
                <img src={caseStudy.image} alt="Placeholder" className="placeholder-icon" />
              </div>
            </div>
            <div className="case-study-content">
              <h3 className="case-study-title">{caseStudy.title}</h3>
              <p className="case-study-description-in">{caseStudy.description}</p>
              <a href={`/case-studies/${caseStudy.slug}`} className="read-more-btn">
                Read More
              
              </a>
            </div>
          </div>
        ))}
      </div>

      <div className="pagination">
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="pagination-btn prev-btn"
          aria-label="Previous page"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M15 18l-6-6 6-6"></path>
          </svg>
        </button>

        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentPage(index + 1)}
            className={`pagination-btn page-number ${currentPage === index + 1 ? "active" : ""}`}
            aria-label={`Page ${index + 1}`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="pagination-btn next-btn"
          aria-label="Next page"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M9 18l6-6-6-6"></path>
          </svg>
        </button>
      </div>
    </section>
  )
}

export default CaseStudiesSection

