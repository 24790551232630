// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-section {
    text-align: center;
    padding: 50px 20px;
    display: flex;
    align-items: center;
  }
  
  .features-section__title {
    font-size: 2rem;
    color: #1a1a3d;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .features-section__subtitle {
    font-size: 1rem;
    color: #000;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .features-section__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Resources/TrainingSection/FeaturesSection.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,uBAAuB;IACvB,iBAAiB;IACjB,cAAc;EAChB","sourcesContent":[".features-section {\r\n    text-align: center;\r\n    padding: 50px 20px;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .features-section__title {\r\n    font-size: 2rem;\r\n    color: #1a1a3d;\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .features-section__subtitle {\r\n    font-size: 1rem;\r\n    color: #000;\r\n    margin-bottom: 30px;\r\n    max-width: 600px;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n  }\r\n  \r\n  .features-section__grid {\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));\r\n    gap: 20px;\r\n    justify-content: center;\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
