import React from "react";
import Slider from "react-slick";
import ExpertiseCard from "./ExpertiseCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./expertise.css"; 

const ExpertiseCarousel = ({ expertiseData }) => {
  const settings = {
    arrow:false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 cards at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Show 2 cards on medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 card on small screens
        },
      },
    ],
  };

  return (
    <div className="expertise-carousel">
      <Slider {...settings}>
        {expertiseData.industryFocus.map((expertise, index) => (
          <div key={index}>
            <ExpertiseCard title={expertise.title} points={expertise.points} icon={expertise.icon} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ExpertiseCarousel;
