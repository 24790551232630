import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "./benefit.css"; // Updated stylish CSS

const benefits = [
  { icon: "/newimages/operitionalefficency.jpg", title: "Operational Efficiency", description: "Automation minimizes workloads, enhancing efficiency." },
  { icon: "/newimages/fasterdecisionmakingDoot.jpg", title: "Faster Decision-Making", description: "Instant data retrieval supports quicker decisions." },
  { icon: "/newimages/costSavingdoot.jpg", title: "Cost Savings", description: "Reduces the need for extensive support teams." },
  { icon: "/newimages/dootscalibility.jpg", title: "Scalability", description: "Adapts to growing business demands seamlessly." },
  { icon: "/newimages/customerexperience.jpg", title: "Enhanced Customer Experience", description: "Delivers accurate responses, improving satisfaction." },
  { icon: "/newimages/datadriven.jpg", title: "Data-Driven Insights", description: "Provides actionable insights for optimization." },
];

const Benefits = () => {
  const cardRefs = useRef([]);

  useEffect(() => {
    cardRefs.current.forEach((card, index) => {
      gsap.fromTo(
        card,
        { y: 50, opacity: 0 },
        { y: 0, opacity: 1, duration: 1, delay: index * 0.2, ease: "power3.out" }
      );
    });
  }, []);

  return (
    <div className="benefits-containerdoot">
      <h2 className="benefits-title">Why Choose DOOT?</h2>
      <div className="benefits-griddoot">
        {benefits.map((benefit, index) => (
          <div className="benefit-card" ref={(el) => (cardRefs.current[index] = el)} key={index}>
            <div className="benefit-inner">
              {/* Front Side (Image & Title) */}
              {/* <div style={{border:"1px solid red"}}> */}
              <div className="benefit-front">  
                <img src={benefit.icon} alt={benefit.title} className="benefit-image" />
                <h5 style={{color:"black"}}>{benefit.title}</h5>
              </div>
              {/* </div> */}
              
              {/* Back Side (Description) */}
              <div className="benefit-back">
                <p style={{fontSize:"18px"}}>{benefit.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
