// import React, { useState, useEffect } from "react";
// import "./ProductLayout.css";
// import RequestButton from "../ProductandSolution/common/RequestButton";
// import { Divider } from "@mui/material";

// const ProductLayout = ({ product }) => {
//   const [activeIndex, setActiveIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % product.length);
//     }, 5000); // Change product every 5 seconds

//     return () => clearInterval(interval);
//   }, [product.length]);

//   return (
//     <div className="partnerproduct-container">
//       {/* Gradient Line Before Heading */}
//       <div className="heading-with-gradient">
//         <h2 className="partnerproduct-title">Our Accelerators</h2>
//         <div className="gradient-line"></div>
//       </div>

//       <div className="partnerproduct-layout">
//         {/* Sidebar */}
//         <div className="sidebar">
//           {product.map((item, index) => (
//             <div
//               key={index}
//               className={`sidebar-item ${index === activeIndex ? "active" : ""}`}
//               onClick={() => setActiveIndex(index)}
//             >
//               <h3 className="sidebar-title">{item.title}</h3>
//             </div>
//           ))}
//         </div>

//         {/* Gradient Divider */}
//         <div className="gradient-divider"></div>

//         {/* Main Content */}
//         <div className="main-content">
//           <img
//             src={product[activeIndex].image}
//             alt={product[activeIndex].name}
//             className="partnerproduct-image"
//             style={{ width: "80%", height: "300px", objectFit: "cover" }}
//           />
//           <p className="partnerproduct-description">{product[activeIndex].points}</p>
//           <RequestButton href={product[activeIndex].link}>Get it Now</RequestButton>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductLayout;
import React from "react";
import { Carousel, Card, Container, Row, Col } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import RequestButton from "../ProductandSolution/common/RequestButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ProductLayout.css";

const ProductLayout = ({ product }) => {
  
  // Function to split the product list into chunks of 3
  const chunkArray = (arr, size) => {
    return arr.reduce((acc, _, i) => {
      if (i % size === 0) acc.push(arr.slice(i, i + size));
      return acc;
    }, []);
  };

  const chunkedProducts = chunkArray(product, 3); // Split into groups of 3

  return (
    <Box sx={{ width: "100%", maxWidth: "1200px", margin: "auto",marginBottom:'30px', p: { xs: 2, md: 4 } }}>
      <div className="heading-with-gradient">
        <h2 className="partnerproduct-title">Our Accelerators</h2>
        <div className="gradient-line"></div>
      </div>

      <Container>
        <Carousel indicators={true} controls={true} interval={5000} className="smooth-carousel">
          {chunkedProducts.map((group, index) => (
            <Carousel.Item key={index}>
              <Row className="justify-content-center custom-row">
                {group.map((item, idx) => (
                  <Col key={idx} xs={12} sm={6} md={4} className="d-flex justify-content-center">
                    <Card className="csgbenefit-card text-center">
                      <Card.Img
                        variant="top"
                        src={item.image}
                        alt={item.title}
                        className="csgfixed-card-img img-fluid"
                        style={{ maxHeight: "300px", objectFit: "cover" }}
                      />
                      <Card.Body className="d-flex flex-column justify-content-between">
  <Typography variant="h5" fontWeight="bold" className="csgcardtitle">
    {item.title}
  </Typography>
  <Typography variant="body1" className="csgcardtext">
    {item.points}
  </Typography>
  <RequestButton href={item.link} className="request-button">Get it Now</RequestButton>
</Card.Body>

                    </Card>
                  </Col>
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </Box>
  );
};

export default ProductLayout;
