import React from 'react';

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <h1 className="hero-title">20x faster than coding.</h1>
            <p className="hero-subtitle">
              Use your native language to describe your idea, then watch
              Lovable do the rest. Creating for the web is faster and easier than
              ever before.
            </p>
            <div className="divider"></div>
            <h2 className="h2 mb-4">Prompt to edit.</h2>
            <p className="hero-subtitle">
              Forget about the overhead of frontend engineers or freelancers to
              maintain your website. Ask in text to change anything.
            </p>
            <div className="divider"></div>
            <h2 className="h2 mb-4">You own the code.</h2>
            <p className="hero-subtitle">
              Everything that Lovable builds is yours. Sync your codebase to
              Github and edit in any code editor, export or publish your app
              instantly with one click.
            </p>
          </div>
          <div className="col-lg-5">
            <div className="property-preview">
              <div className="edit-button">Edit</div>
              <div className="p-3">
                <h5 className="mb-4">Luxury Estates</h5>
                <h3 className="mb-4">Featured <span style={{ color: '#DAA520' }}>Properties</span></h3>
                
                <div className="modal-dialog" style={{ maxWidth: '90%', margin: '0 auto' }}>
                  <div className="modal-content bg-dark text-white p-3">
                    <p>Set corner radius to 30</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
