import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai"; 
import "./HeroLanding.css";

// Example image imports (replace with your own images)
import studentReading from "../../../assest/icons/studentReading.png";
import studentsGroup from "../../../assest/icons/studentsGroup.png";
import PopupForm from "../../HomePage/ContactForm";
import RequestButton from "../../ProductandSolution/common/RequestButton";

const avatar1 =
  "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww";
const avatar2 =
  "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww";
const avatar3 =
  "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww";
const avatar4 =
  "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww";

const HeroLanding = () => {
  return (
    <section className="hero-landing">
      <div className="hero-landing__container">
        {/* Left Column */}
        <div className="hero-landing__content">
          <h1 className="hero-landing__title">
            Start A Better Learning Future From Here
          </h1>
          <p className="hero-landing__description">
            Revamp your educational journey with Same Online Academy—an
            innovative platform offering comprehensive courses tailored to your
            needs. Elevate your learning experience today!
          </p>

          <ul className="hero-landing__benefits">
            <li>
              <div className="hero-landing__benefit-icon"></div>
              <div>
                <h4 style={{textAlign:'left'}}>Competitive Rates</h4>
                <p style={{textAlign:'left',fontSize:'14px',color:"#000"}}>
                  Your gateway to limitless learning opportunities. Access
                  quality education anytime.
                </p>
              </div>
            </li>
            <li>
              <div className="hero-landing__benefit-icon"></div>
              <div>
                <h4 style={{textAlign:'left'}}>Online Certificates</h4>
                <p style={{textAlign:'left',fontSize:'14px',color:"#000"}}>

                  Academy for education. Join our innovative platform and unlock
                  your full potential today.
                </p>
              </div>
            </li>
          </ul>

          {/* Popup Form */}
          <div>
          <RequestButton
        href="/product/support"
        
      >
        Get in Touch
      </RequestButton>
          </div>
        </div>

        {/* Right Column (Images) */}
        <div className="hero-landing__images">
          {/* Main student image */}
          <div className="hero-landing__main-image">
            <img
              src={studentReading || "/placeholder.svg"}
              alt="Student Reading"
            />
          </div>

          {/* Secondary group image */}
          <div className="hero-landing__group-image">
            <img
              src={studentsGroup || "/placeholder.svg"}
              alt="Students Group"
            />
          </div>

          {/* Decorative dots */}
          <div className="hero-landing__decorative-dots"></div>
        </div>
      </div>
    </section>
  );
};

export default HeroLanding;
