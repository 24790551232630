import React from "react"
import CourseCard from "./CourseCard"
import "./CoursesSection.css"

// Sample course data
const courses = [
  {
    image:
      "https://media.istockphoto.com/id/1434116614/photo/teamwork-diversity-and-sales-manager-planning-branding-ideas-with-a-creative-designer-on-a.jpg?s=612x612&w=0&k=20&c=0qDHz0EDKEgxqcRP7V-YWaGv9nrjKDXG5lz8svrlbcQ=",
    price: "49.00",
    lessons: 15,
    students: 16,
    title: "Advanced Android Kotlin Development Course",
    ratings: "4.5",
    ratingsCount: 4,
    instructor: "Floyd Miles",
    instructorImage:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
  {
    image:
      "https://media.istockphoto.com/id/1434116614/photo/teamwork-diversity-and-sales-manager-planning-branding-ideas-with-a-creative-designer-on-a.jpg?s=612x612&w=0&k=20&c=0qDHz0EDKEgxqcRP7V-YWaGv9nrjKDXG5lz8svrlbcQ=",
    price: "49.00",
    lessons: 15,
    students: 16,
    title: "Best Statistics Data Science & Business",
    ratings: "4.5",
    ratingsCount: 4,
    instructor: "Devon Lane",
    instructorImage:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
  {
    image:
      "https://media.istockphoto.com/id/1434116614/photo/teamwork-diversity-and-sales-manager-planning-branding-ideas-with-a-creative-designer-on-a.jpg?s=612x612&w=0&k=20&c=0qDHz0EDKEgxqcRP7V-YWaGv9nrjKDXG5lz8svrlbcQ=",
    price: "49.00",
    lessons: 15,
    students: 16,
    title: "Education Software and PHP & JS System",
    ratings: "4.5",
    ratingsCount: 4,
    instructor: "Darrell Steward",
    instructorImage:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
  {
    image:
      "https://media.istockphoto.com/id/1434116614/photo/teamwork-diversity-and-sales-manager-planning-branding-ideas-with-a-creative-designer-on-a.jpg?s=612x612&w=0&k=20&c=0qDHz0EDKEgxqcRP7V-YWaGv9nrjKDXG5lz8svrlbcQ=",
    price: "49.00",
    lessons: 15,
    students: 16,
    title: "Beginner Adobe Figma for UI/UX Design",
    ratings: "4.5",
    ratingsCount: 4,
    instructor: "Theresa Webb",
    instructorImage:
      "https://plus.unsplash.com/premium_photo-1689539137236-b68e436248de?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8bWFuJTIwYXZhdGFyfGVufDB8fDB8fHww",
  },
]

const CoursesSection = () => {
  return (
    <section className="courses-section">
      <h2 className="courses-section__title">Explore Popular Courses</h2>
      <p className="courses-section__subtitle">
        Dynamically network bleeding-edge platforms rather than client-based ROI. Continually foster wireless services.
      </p>

      <div className="courses-section__grid">
        {courses.map((course, index) => (
          <CourseCard key={index} course={course} />
        ))}
      </div>
    </section>
  )
}

export default CoursesSection

