import React, { useEffect, useState } from "react";
import "./TextShpere.css";
import TagCloud from "TagCloud";
// import "./Logo.css"
import { motion } from 'framer-motion';
import { Hidden } from "@mui/material";
 
const TextShpere = () => {
 
  const [isOpen, setIsOpen] = useState(false);
 
  const redirectToCareerPage = () => {
    // Set the URL of the career page
    window.location.href = '/career'; // Replace '/career' with the actual URL of your career page
  };
 
  // Animation settings for Text Cloud
  useEffect(() => {
    return () => {
      const container = ".tagcloud";
      const texts = [
        "React",
        "NodeJS",
        "Babel",
        "Jquery",
        "ES6",
        "GIT",
        "GITHUB",
        "ESLint",
        "Three.js",
        "VSCode",
        "TypeScript",
        "Python",
        "Azure DataBricks",
        "Microsoft Azure",
        "Azure CLI",
        "Open AI",
        "Linux",
        "PostgreSQL",
        "Firebase",
        "Power BI",
        "Microsoft 365",
        "Microsoft Teams",
        "Nginx",
        "BootStrap",
        "Jupiter Notebook",
        "Prompt Engineering",
        "Django",
        "DBT",
        "Bot-Framework",
        "Bot-Emulator",
        "SQL",
        "PyTorch",
        "Flask",
        "Docker",
        "Kibana",
        "Kubernetes",
        "CSS",
        "JavaScript"
      ];
      const screenWidth = window.innerWidth;
 
      let radius = 320; // Default radius
      if (screenWidth <= 768) {
        // Adjust radius for smaller screens
        radius = 165; // Change the radius value for smaller screens
      }
 
      const options = {
        radius: radius,
        maxSpeed: "normal",
        initSpeed: "normal",
        keep: true,
      };
 
      // Create a custom renderer with dynamic colors
      const tagCloud = TagCloud(container, texts, options);
      
      // Apply custom styling to tags after creation
      const cloudElements = document.querySelectorAll('.tagcloud');
      cloudElements.forEach(cloud => {
        const tags = cloud.children;
        Array.from(tags).forEach((tag, index) => {
          // Create a vibrant, dynamic color palette
          const colors = [
            'linear-gradient(45deg, #FF6B6B, #4ECDC4)', // Coral to Turquoise
            'linear-gradient(45deg, #6A5ACD, #1E90FF)', // Slate Blue to Dodger Blue
            'linear-gradient(45deg, #9932CC, #00FF7F)', // Dark Orchid to Spring Green
            'linear-gradient(45deg, #FF4500, #1E90FF)', // Orange Red to Dodger Blue
            'linear-gradient(45deg, #32CD32, #4169E1)', // Lime Green to Royal Blue
            'linear-gradient(45deg, #FF69B4, #8A2BE2)'  // Hot Pink to Blue Violet
          ];
          
          // Cycle through colors or randomly select
          const colorIndex = index % colors.length;
          tag.style.background = colors[colorIndex];
          tag.style.backgroundClip = 'text';
          tag.style.webkitBackgroundClip = 'text';
          tag.style.color = 'transparent';
          tag.style.webkitTextFillColor = 'transparent';
          tag.style.fontWeight = '700';
          tag.style.transition = 'all 0.3s ease';
          
          // Add hover effect
          tag.addEventListener('mouseenter', () => {
            tag.style.transform = 'scale(1.2)';
          });
          
          tag.addEventListener('mouseleave', () => {
            tag.style.transform = 'scale(1)';
          });
        });
      });
    };
  }, []);
 
  return (
    <motion.div 
      className="techStack"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="intro-text-lefts">
        <motion.h2 
          className="intro-headings"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          Join Us on This Journey
        </motion.h2>
        <motion.p 
          className="intro-paragraph"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.6 }}
        >
        <p style={{fontSize:"15px"}}>
          <span className="highlight">Explore our tech stack showcase </span>a testament to our dedication,
          expertise, and commitment to delivering solutions that exceed
          expectations. Dive into the world of possibilities with us and let's
          embark on this transformative journey together!
          </p>
        </motion.p>

        <motion.button 
          className="contactButton_Techs" 
          onClick={() => { setIsOpen(true); redirectToCareerPage(); }}
          whileHover={{ 
            scale: 1.05,
            boxShadow: "0 0 15px rgba(0,123,255,0.5)"
          }}
          whileTap={{ scale: 0.95 }}
        >
          Join Us
        </motion.button>
      </div>
      <Hidden mdDown>
      <motion.div 
        className="tagcloud-container"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <img src="../homepgimg/joinus.jpg" style={{borderRadius:"15px"}}></img>
        {/* <span className="tagcloud"></span> */}
      </motion.div>
      </Hidden>
    </motion.div>
  );
};
 
export default TextShpere;