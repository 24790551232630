// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/BG_2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-form {
    /* padding: 20px; */
    /* background-color: #f5f5f5; */
    /* margin-top: 70px; */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
  }`, "",{"version":3,"sources":["webpack://./src/components/SupportForm/Support.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,sBAAsB;IACtB,yDAA8C;IAC9C,sBAAsB;EACxB","sourcesContent":[".support-form {\r\n    /* padding: 20px; */\r\n    /* background-color: #f5f5f5; */\r\n    /* margin-top: 70px; */\r\n    background-image: url(/public/images/BG_2.jpg);\r\n    background-size: cover;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
