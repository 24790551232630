
import React from 'react';

const PropertyCard = ({ image, price, location, beds, baths, sqft }) => {
  return (
    <div className="feature-card mb-4">
      <img src={image} alt={location} className="feature-img" />
      <div className="p-3">
        <div className="feature-price mb-1">{price}</div>
        <div className="feature-location mb-3">{location}</div>
        <div className="feature-details">
          <span>{beds} Beds</span>
          <span>{baths} Baths</span>
          <span>{sqft.toLocaleString()} sq ft</span>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;