// EnergyPage.js

import React from "react";
import IndustryPage from "./IndustryPage";
import { EnergySavingsLeaf } from "@mui/icons-material";
import {
  Bolt, // Energy Storage
  TrendingUp, // Yield Optimization
  MonetizationOn, // Energy Trading Optimization
  AutoGraph, // Smart Grid Analytics
  Forest, // Carbon Footprint Reduction
  WaterDrop, // Water Planning
  CloudQueue, // Drought Monitoring
  Waves, // Flood Prediction
  Delete, // Waste Management
  Route, // Route Optimization
  Insights, // Analytics & Behavior Analysis
} from "@mui/icons-material";

const EnergyPage = () => {
  // Specific details for Energy
  const headingText = "Energy";
  const introText = `"Empower your energy sector with innovative solutions and sustainable technologies."`;
  const paragraphText = `The energy industry plays a crucial role in powering the world's economies. Our solutions focus on renewable energy, smart grids, and efficient resource management to meet the growing demands for clean and sustainable energy sources.`;
  const backgroundImage = "./images/energy.jpg";
  const sectionImage = "./industry/energyindustry.jpg";
  const carouselImages = [
    "../industry/1.png",
    "../industry/2.png",
    "../industry/3.png",
  ];

  const gridItems = [
    {
      title: "Renewable Energy",
      description:
        "We specialize in harnessing the power of renewable sources such as solar and wind for sustainable energy production.",
      additionalImage: "../videos/Reneable_Gif.gif",
      img:"../industry/1.png",
      useCases: [
        { title: "Energy Storage Management", icon: <Bolt /> },
        { title: "Yield Optimization", icon: <TrendingUp /> },
        { title: "Energy Trading Optimization", icon: <MonetizationOn /> },
        { title: "Smart Grid Analytics", icon: <AutoGraph /> },
        { title: "Carbon Footprint Reduction", icon: <Forest /> },
      ],
    },
    {
      title: "Water",
      description:
        "Optimize water usage, reduce water consumption through a sustainable approach.",
      additionalImage: "../videos/Water_gif.gif",
      img:"../industry/2.png",
      useCases: [
        { title: "Water Planning and Revenue Management", icon: <WaterDrop /> },
        { title: "Drought Monitoring and Management", icon: <CloudQueue /> },
        { title: "Flood Prediction Management", icon: <Waves /> },
        { title: "Smart Grid Analytics", icon: <AutoGraph /> },
        { title: "Carbon Footprint Reduction", icon: <Forest /> },
      ],
    },
    {
      title: "Waste",
      description: "Sustainable strategies, resilience, reduce carbon emissions.",
      additionalImage: "../videos/Waste_gif.gif",
      img:"../industry/3.png",
      useCases: [
        { title: "Landfill Capacity Planning", icon: <Delete /> },
        { title: "Customer Behavior Analysis", icon: <Insights /> },
        { title: "Route Optimization", icon: <Route /> },
        { title: "Waste Generation Analysis", icon: <AutoGraph /> },
        { title: "Waste Composition Analysis", icon: <Forest /> },
      ],
    },
  ];
  

  return (
    <IndustryPage
      headingText={headingText}
      introText={introText}
      paragraphText={paragraphText}
      backgroundImage={backgroundImage}
      sectionImage={sectionImage}
      carouselImages={carouselImages}
      gridItems={gridItems}
    />
  );
};

export default EnergyPage;
