// import React, { useState } from "react";
// import { useForm, Controller } from "react-hook-form";
// import {
//   TextField,
//   Button,
//   Container,
//   Grid,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   CircularProgress,
// } from "@mui/material";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import axios from "axios";

// const Support = () => {
//   const { control, handleSubmit, formState: { errors } } = useForm();
//   const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
//   const [isLoading, setLoading] = useState(false);

//   const onSubmit = async (data) => {
//     setLoading(true);
//     try {
//       // await axios.post("http://localhost:3000/productandsolutions/support", data);
//       // setLoading(false);
//       setSuccessModalVisible(true);
//     } catch (error) {
//       setLoading(false);
//       console.error("Error submitting the form:", error.message);
//     }
//   };

//   const handleModalClose = () => {
//     setSuccessModalVisible(false);
//   };

//   return (
//     <div>
//       <Container maxWidth="md" style={{ padding: "90px 40px 50px 40px" }}>
//         <h2 style={{ color: "#101c3d" }}>Support Application Form</h2>
//         <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "4%" }}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={6}>
//               <Controller
//                 name="fullName"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <TextField
//                     label="Customer Name"
//                     {...field}
//                     fullWidth
//                     required
//                     error={!!errors?.fullName}
//                     helperText={errors?.fullName ? "Customer Name is required" : ""}
//                   />
//                 )}
//                 rules={{
//                   required: "Customer Name is required",
//                   pattern: {
//                     value: /^[A-Za-z\s]+$/, // Allow only letters and spaces
//                     message: "Please enter letters only",
//                   },
//                 }}
//               />
//             </Grid>

         

//             <Grid item xs={12} md={6}>
//               <Controller
//                 name="phoneNumber"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <PhoneInput
//                     name="phoneNumber"
//                     country={"in"} // Default to India
//                     placeholder="Enter phone number"
//                     enableSearch={true}
//                     countryCodeEditable={false}
//                     {...field}
//                     required
//                   />
//                 )}
//                 rules={{
//                   required: "Phone number is required",
//                 }}
//               />
//             </Grid>

//             <Grid item xs={12} md={6}>
//               <Controller
//                 name="solutionName"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <TextField label="Solution Name" {...field} fullWidth />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Controller
//                 name="Issue Type"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <TextField label="Issue Type" {...field} fullWidth />
//                 )}
//               />
//             </Grid>

//             <Grid item xs={12}>
//               <Controller
//                 name="description"
//                 control={control}
//                 defaultValue=""
//                 render={({ field }) => (
//                   <TextField
//                     label="Enter description"
//                     {...field}
//                     fullWidth
//                     multiline
//                     rows={4}
//                   />
//                 )}
//               />
//             </Grid>

//             <Grid item xs={12}>
//               {isLoading ? (
//                 <CircularProgress color="primary" size={24} />
//               ) : (
//                 <Button type="submit" variant="contained" color="primary">
//                   Submit Application
//                 </Button>
//               )}
//             </Grid>
//           </Grid>
//         </form>
//       </Container>

//       {isSuccessModalVisible && (
//         <Dialog open={isSuccessModalVisible} onClose={handleModalClose}>
//           <DialogTitle style={{ backgroundColor: "#101c3d", color: "white" }}>
//             Form Successfully Submitted!
//           </DialogTitle>
//           <DialogContent style={{ padding: "20px", textAlign: "center" }}>
//             <p style={{ fontSize: "1.2rem", color: "#333" }}>
//               Thank you for submitting your application. We'll get back to you soon!
//             </p>
//           </DialogContent>
//           <DialogActions style={{ justifyContent: "center", padding: "15px" }}>
//             <Button onClick={handleModalClose} variant="contained" style={{ backgroundColor: "#101c3d", color: "white" }}>
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       )}
//     </div>
//   );
// };

// export default Support;

import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  Container,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";

const Support = () => {
  const { control, handleSubmit,reset, formState: { errors }, watch } = useForm();
  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // Watch all form values
  const fullName = watch('fullName');
  const phoneNumber = watch('phoneNumber');
  const productSolutionName = watch('productSolutionName');
  const issueType = watch('issueType');
  const description = watch('description');

  const isFormValid = fullName && phoneNumber?.length === 12 && productSolutionName && issueType && description;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // You can modify this to integrate with an email sending API
      await axios.post("https://onelogicawebsiteserver.azurewebsites.net/support", data);
      setLoading(false);
      setSuccessModalVisible(true);
      reset(); // Reset the form fields after successful submission
    } catch (error) {
      setLoading(false);
      console.error("Error submitting the form:", error.message);
    }
  };

  const handleModalClose = () => {
    setSuccessModalVisible(false);
  };

  return (
    <div>
      <Container maxWidth="md" style={{ padding: "90px 40px 50px 40px" }}>
        <h2 style={{ color: "#101c3d" }}>Support Application Form</h2>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "4%" }}>
          <Grid container spacing={2}>
            {/* Customer Name */}
            <Grid item xs={12} md={6}>
              <Controller
                name="fullName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Customer Name"
                    {...field}
                    fullWidth
                    required
                    error={!!errors?.fullName}
                    helperText={errors?.fullName ? "Customer Name is required" : ""}
                  />
                )}
                rules={{
                  required: "Customer Name is required",
                  pattern: {
                    value: /^[A-Za-z\s]+$/, // Allow only letters and spaces
                    message: "Please enter letters only",
                  },
                }}
              />
            </Grid>

            {/* Phone Number */}
            <Grid item xs={12} md={6}>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <PhoneInput
                    name="phoneNumber"
                    country={"in"} // Default to India
                    placeholder="Enter phone number"
                    enableSearch={true}
                    countryCodeEditable={false}
                    {...field}
                    required
                  />
                )}
                rules={{
                  required: "Phone number is required",
                  validate: value =>
                    value.length === 12 || "Phone number must be 10 digits.",
                }}
              />
              {errors.phoneNumber && (
                <span style={{ color: "red" }}>{errors.phoneNumber.message}</span>
              )}
            </Grid>

            {/* Product/Solution Name Dropdown */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Product/Solution Name</InputLabel>
                <Controller
                  name="productSolutionName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      label="Product/Solution Name"
                      {...field}
                      required
                      error={!!errors?.productSolutionName}
                      style={{textAlign: 'justify'}}
                    >
                      <MenuItem value="EAS">EAS</MenuItem>
                      <MenuItem value="VACH">VACH</MenuItem>
                      <MenuItem value="GLAM">GLAM</MenuItem>
                      <MenuItem value="DOOT">DOOT</MenuItem>
                    </Select>
                  )}
                />
                {errors.productSolutionName && (
                  <span style={{ color: "red" }}>{errors.productSolutionName.message}</span>
                )}
              </FormControl>
            </Grid>

            {/* Issue Type Dropdown */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Issue Type</InputLabel>
                <Controller
                  name="issueType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      label="Issue Type"
                      {...field}
                      required
                      error={!!errors?.issueType}
                      style={{textAlign: 'justify'}}
                    >
                      <MenuItem value="Access Issue">Access Issue</MenuItem>
                      <MenuItem value="Feature Not Working">Feature Not Working</MenuItem>
                      <MenuItem value="Error Page">Error Page</MenuItem>
                      <MenuItem value="Security">Security</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  )}
                />
                {errors.issueType && (
                  <span style={{ color: "red" }}>{errors.issueType.message}</span>
                )}
              </FormControl>
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Enter description"
                    {...field}
                    fullWidth
                    multiline
                    rows={4}
                    required
                    error={!!errors?.description}
                    helperText={errors?.description ? "Description is required" : ""}
                  />
                )}
                rules={{
                  required: "Description is required",
                }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              {isLoading ? (
                <CircularProgress color="primary" size={24} />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isFormValid}
                >
                  Submit Application
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </Container>

      {/* Success Modal */}
      {isSuccessModalVisible && (
        <Dialog open={isSuccessModalVisible} onClose={handleModalClose}>
          <DialogTitle style={{ backgroundColor: "#101c3d", color: "white" }}>
            Form Successfully Submitted!
          </DialogTitle>
          <DialogContent style={{ padding: "20px", textAlign: "center" }}>
            <p style={{ fontSize: "1.2rem", color: "#333" }}>
              Thank you for submitting your application. We'll get back to you soon!
            </p>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center", padding: "15px" }}>
            <Button
              onClick={handleModalClose}
              variant="contained"
              style={{ backgroundColor: "#101c3d", color: "white" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Support;
