import { Container, Row, Col, Card } from "react-bootstrap";
import { Done } from "@mui/icons-material";
import governencecomplance from "../../../assest/icons/governencecomplance.png";
import envmatrics from "../../../assest/icons/envmatrics.png";
import socialImpact from '../../../assest/icons/socialimpact.png';
import sustainReport from '../../../assest/icons/sustainreport.png';
import './Esgfeature.css';

const ESGFeatures = () => {
    const features = [
        {
            title: "Environmental Metrics",
            icon: envmatrics,
            points: ["Carbon Emission Tracking", "Energy Efficiency Analysis", "Water Usage Management"],
        },
        {
            title: "Social Impact",
            icon: socialImpact,
            points: ["Diversity and Inclusion Analysis", "Community Engagement", "Health & Safety Reporting"],
        },
        {
            title: "Governance Compliance",
            icon: governencecomplance,
            points: ["Risk Management", "Transparency Metrics", "Stakeholder Engagement"],
        },
        {
            title: "Sustainability Reporting",
            icon: sustainReport,
            points: ["Benchmarking", "Regulatory Compliance", "Corporate Responsibility"],
        },
    ];

    return (
        <Container id="features" className="py-5 esgfeatures-section">
            <Row className="esgfeatures-sectionrow text-left mb-3">
                <Col md={5} lg={5} sm={12} style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                    <h2 className="esgsection-title">Key Features of ESG Solutions</h2>
                    <p className="esgsection-subtitle">
                        Integrating Environmental, Social, and Governance Principles for Growth and Compliance.
                    </p>
                </Col>
            <Col lg={7} md={7} sm={12}>
            <Row className="gx-4 gy-4">
                {features.map((feature, index) => (
                    <Col md={5} lg={6} key={index}>
                        <Card className="featureesg-card text-center  position-relative border-0">
                            {/* Icon Positioned at Top Border */}
                            {/* <div className="esgfeature-icon">
                                <img src={feature.icon} alt={feature.title} className="icon-img" />
                            </div> */}

                            <Card.Body className="p-4">
                                <Card.Title className="esgtexttitle fs-5  text-left">{feature.title}</Card.Title>
                                <Card.Text className="textfeature text-left">
                                    {feature.points.map((point, i) => (
                                        <div key={i} className="d-flex align-items-start justify-content-left">
                                            {/* <Done className="check-icon" /> */}
                                            {point}
                                        </div>
                                    ))}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            </Col>
            </Row>
        </Container>
    );
};

export default ESGFeatures;
