import React, { useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Footer from "../Footer/Footer";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import { Link } from 'react-router-dom';
import "./partnership.css";
import { Helmet } from 'react-helmet-async';

const PartnershipPage = () => {


  // Define partner data
  // Define partner data
  const partners = [
    {
      name: "Azure",
      logo: "https://upload.wikimedia.org/wikipedia/commons/a/a8/Microsoft_Azure_Logo.svg",
      image: "../images/aureimg.webp",
      description: "Microsoft Azure enables building, deploying, and managing applications in the cloud. It offers a comprehensive suite of cloud services, including computing, analytics, storage, and networking. Azure is known for its hybrid cloud solutions and seamless integration with Microsoft tools.",
      moreInfo: "Azure empowers organizations to accelerate digital transformation by providing the necessary tools for developing and managing applications in a secure, scalable, and flexible cloud environment. Azure is widely used in industries such as finance, healthcare, and retail.",
      subheading: "Empowering Digital Transformation with Hybrid Cloud Solutions\nA Unified Cloud Platform for Enterprises",
      img: "../images/awsworkflow.png"
    },
    {
      name: "AWS",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
      image: "https://via.placeholder.com/400x300?text=AWS+Image",
      description: "Amazon Web Services (AWS) offers reliable, scalable, and cost-effective cloud solutions. AWS provides a suite of cloud computing services, including storage, databases, machine learning, and analytics. It is a leading platform for businesses of all sizes, helping them to reduce costs and improve efficiency.",
      moreInfo: "AWS helps businesses scale and innovate by providing essential cloud infrastructure services. With a robust ecosystem, AWS powers the cloud needs of startups, enterprises, and government organizations worldwide.",
      subheading: "Scalable Cloud Solutions for Businesses of All Sizes\nPowering Innovation with Reliable Cloud Infrastructure",
      img: "../images/azureworkflow.png"
    },

    {
      name: "Snowflake",
      logo: "../newimages/salesforce-removebg-preview.png",
      image: "../images/snowflake.webp",
      description: "Snowflake provides a cloud-based data warehousing solution for all your data needs. It enables users to store, manage, and analyze large amounts of structured and semi-structured data. Snowflake's unique architecture allows it to scale automatically and provide high performance with minimal management.",
      moreInfo: "Snowflake is recognized for its simplicity, ease of use, and ability to handle diverse data types. It supports cloud platforms like AWS, Azure, and Google Cloud, making it a versatile choice for businesses looking to optimize their data management and analytics capabilities.",
      subheading: "Simplifying Data Warehousing with Cloud-Native Solutions\nUnlocking Insights from Structured and Semi-Structured Data"
    },
    {
      name: "Databricks",
      logo: "../newimages/databricklogo.png",
      image: "../images/databricksimg.webp",
      description: "Databricks unifies data science and engineering for advanced analytics and AI. It provides a collaborative platform that enables teams to work together on data engineering, machine learning, and analytics. Databricks is powered by Apache Spark, which helps process large-scale data efficiently.",
      moreInfo: "Databricks accelerates innovation by bringing data scientists, engineers, and business analysts together to create data-driven solutions. It is used by leading organizations in AI, data analytics, and machine learning, offering an optimized environment for big data processing.",
      subheading: "Accelerating AI and Analytics with Unified Data Engineering\nCollaborative Platform for Data Science and Business Teams"
    },
    {
      name: "dbt",
      logo: "./newimages/dbt.png",
      image: "https://isamblobstorage.blob.core.windows.net/isamfilecotainer/PartnersImages/image (19).png",
      description: "dbt transforms raw data into meaningful insights with advanced transformation workflows. It allows analysts and engineers to collaborate on building scalable data pipelines. dbt helps automate the process of transforming raw data into models that are easy to query and analyze.",
      moreInfo: "dbt is widely adopted by teams working with modern data stacks. Its open-source nature and robust feature set make it a favorite tool for developing, testing, and maintaining data transformations at scale. It integrates seamlessly with popular data warehouses such as Snowflake, BigQuery, and Redshift.",
      subheading: "Transforming Data with Automated Pipelines\nPowering Scalable Data Models with Open-Source Tools"
    }
  ];
  // State to manage the selected partner


  const handleCardClick = (partner) => {
    setSelectedPartner(partner);

    // Sanitize the partner name to create a valid id (replace spaces or special characters if necessary)
    const sanitizedId = `partner-${partner.name.replace(/\s+/g, '-').toLowerCase()}`;

    // Log the sanitized ID to check it
    console.log(`Attempting to scroll to: ${sanitizedId}`);

    const element = document.getElementById(sanitizedId);

    // Check if the element exists before calling scrollIntoView
    if (element) {
      console.log('Element found, scrolling to it');
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error('Element not found:', sanitizedId);
    }
  };
  const [selectedPartner, setSelectedPartner] = useState(partners[0]);

  console.log("selectedPartner", selectedPartner.name)

  return (
    <>
      <Helmet>
        <title>{selectedPartner.name} - Partnership</title>
        <meta name="description" content={selectedPartner.description} />
        <link rel="canonical" href="https://www.onelogica.com/" />
      </Helmet>

      {/* First Row - Our Partner Heading */}
      <Container fluid className="hero-section text-white" style={{ background: "#101c3d", marginTop: "3rem" }}>
        <Row className="align-items-center">
          <Col md={7} className="text-left p-5">
            <h6 className="display-5" style={{ fontWeight: "400", color: "#d19e94" }}>
              Trusted Partnerships with Leading Platforms
            </h6>
            <p className="lead mb-4" style={{ fontSize: "1.2rem" }}>
              Collaborating with industry leaders like <b>AWS</b>, <b>Azure</b>,{" "}
              <b>Snowflake</b>, <b>Databricks</b>, and <b>dbt</b> to deliver scalable and innovative solutions.
            </p>
            <Link to="/product/glam">
              <Button
                variant="light"
                size="md"
                style={{
                  borderRadius: "20px",
                  background: "linear-gradient(90deg, #6a11cb, #2575fc)",
                  color: "#fff",
                  border: "none",
                  transition: "background 0.5s ease",
                }}
                onMouseEnter={(e) => (e.target.style.background = "linear-gradient(90deg, #ff7e5f, #feb47b)")}
                onMouseLeave={(e) => (e.target.style.background = "linear-gradient(90deg, #6a11cb, #2575fc)")}
              >
                Explore Our Solutions <TrendingFlatIcon />
              </Button>
            </Link>
          </Col>
          <Col md={5} className="text-center p-5">
            <img
              src="../images/partnership.webp"
              alt="Glam Illustration"
              className="img-fluid"
              style={{ opacity: "0.8", borderRadius: "35px", width: "50vw" }}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="text-center p-5" style={{ background: "#f8f9fa" }}>
        <h3 className="fw-bold">Our Partner</h3>
        <p style={{ padding: "25px 9rem" }}>While we are at the forefront of and specialize in design-build, we are very familiar with a number of delivery methods and are confident we can find the process that will best help you meet your goals.</p>
      </Container>

      {/* Second Row - Solid Line */}
      {/* Third Row - Partner Cards */}
      <Container>
        <div style={{ display: "flex" }}>
          {partners.map((partner, index) => (
            <div

              key={index}
              className={`m-2 ${selectedPartner.name === partner.name ? 'selected-partner' : 'selectPartner'}`}
              onClick={() => {
                console.log("partner", partner.name)
                setSelectedPartner(partner);
                const sanitizedId = `partner-${partner.name.replace(/\s+/g, '-').toLowerCase()}`;
                const element = document.getElementById(sanitizedId);
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >

              <Card>
                <Card.Img
                  variant="top"
                  src={partner.logo}
                  style={{ height: "50px", objectFit: "contain", padding: "10px" }}
                />
                {/* <Card.Body className="text-center">
                        <Card.Title className="fw-bold">{partner.name}</Card.Title>
                      </Card.Body> */}
              </Card>
            </div>
          ))}
        </div>
      </Container>
      <Container fluid>
        <hr
          style={{
            border: "none",
            height: "5px",
            background: "linear-gradient(to right, #6a11cb, #2575fc)",
            margin: "0 auto 2rem auto",
            width: "50%",
          }}
        />
      </Container>


      {/* Partner Details Section */}
      <Container>
        <Row className="align-items-center" id={`partner-${selectedPartner.name.replace(/\s+/g, '-').toLowerCase()}`}>
          <Col md={6}>
            <img
              src={selectedPartner.image}
              alt={selectedPartner.name}
              className="partnercardimg"
            />
          </Col>
          <Col md={6} className='mb-5'>
            <h3>{selectedPartner.name}</h3>
            <p><strong style={{ color: "red" }}>{selectedPartner.subheading}</strong></p>
            <p>{selectedPartner.description}</p>
            {/* <h5>More Info:</h5> */}
            <p>{selectedPartner.moreInfo}</p>
            <Button variant="primary">Learn More</Button>
          </Col>
          {/* <img src={selectedPartner.img}></img> */}
        </Row>
      </Container>



      {/* CTA Section */}
      <section
        style={{

          marginBottom: "10rem",
          marginTop: "95px",
          // /* overflow: hidden;
          height: "40vh",
          backgroundColor: "#053362",
          // padding: "1rem 0",
          // position: "relative",
          // overflow: "hidden",
        }}
      >
        <Container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {/* Image Section */}
          <div
            style={{
              position: "relative",
              flex: 1,
              // zIndex: 1,
            }}
          >
            <img
              src="../images/laptopcontactus.jpg"
              alt="Contact Us"
              style={{
                height: "75vh",
                width: "40vw",
                borderRadius: "14px",
                objectFit: "cover",
                position: "absolute",
                left: "53px", // Adjust overlap
                top: "-300px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            />
          </div>

          {/* Text Section */}
          <div
            style={{
              flex: 1,
              backgroundColor: "#ffffff",
              color: "#000000",
              width: "25vw",
              borderRadius: "14px",
              padding: "3rem 2rem",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
              zIndex: 2,
              marginTop: "3rem"
            }}
          >
            <h3 style={{ fontWeight: "bold" }}>Ready to Partner with Us?</h3>
            <p style={{ margin: "1rem 0" }}>
              Help you do your job better with innovative solutions and strategies.
            </p>
            <Link to='/product/support'>
              <Button
                variant="primary"
                style={{
                  backgroundColor: "#053362",
                  border: "none",
                  padding: "0.5rem 1rem",
                }}
              >
                Contact Us
              </Button>
            </Link>
          </div>
        </Container>
      </section>





      <Footer />
    </>
  );
};

export default PartnershipPage;
