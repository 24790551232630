import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./contactsection.css"; // Ensure correct path
import RequestButton from "./RequestButton";

const ContactSection = () => {
  return (
    <section className="contact-section">
      <Container className="py-5">
        <Row className="align-items-center justify-content-center">
          {/* Image Section */}
          <Col lg={7} md={7} sm={12} className="text-center mb-4 mb-lg-0">
            <img
              src="../images/laptopcontactus.jpg"
              alt="Contact Us"
              className="contact-img"
            />
          </Col>

          {/* Text Section */}
          <Col lg={5} md={5} sm={12} className="d-flex flex-column align-items-center">
            <div className="contact-text">
              <h3 className="fw-bold">Ready to Partner with Us?</h3>
              <p className="mb-1">
                Help you do your job better with innovative solutions and strategies.
              </p>
              <RequestButton href="/product/support">Contact Us</RequestButton>
              {/* <Button href="/product/support" className="contact-btn">
                Contact us
              </Button> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactSection;
