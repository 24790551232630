import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FaRobot, FaMicrophone, FaCogs, FaSearch, FaIndustry, FaGlobe } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const features = [
  { title: "Gen AI-Powered Conversations", icon: <FaRobot /> },
  { title: "Natural Language Processing (NLP)", icon: <FaMicrophone /> },
  { title: "Business Process Automation", icon: <FaCogs /> },
  { title: "Advanced Information Retrieval", icon: <FaSearch /> },
  { title: "Industry Flexibility", icon: <FaIndustry /> },
  { title: "Multilingual & 24/7 Support", icon: <FaGlobe /> },
];

const DootFeatures = () => {
  return (
    <Container className="py-5" style={{ display:"flex",flexDirection:"column",alignItems:"center" }}>
      <h2 className="mb-4 text-center" style={{fontSize:"2.5rem",fontWeight:600}}>Key Features</h2>
      <Row className="g-4">
        {features.map((feature, index) => (
          <Col key={index} xs={12} sm={6} md={4}>
            <Card className="h-100 text-center shadow-sm border-0 p-3" style={{background:"#0c2789",color:"white"}}>
              <Card.Body>
                <div
                  className="d-flex align-items-center justify-content-center rounded-circle bg-primary text-white mb-3 mx-auto"
                  style={{ width: "60px", height: "60px", fontSize: "1.5rem" }}
                >
                  {feature.icon}
                </div>
                <Card.Title className="fw-normal" style={{color:"white",textAlign:"center"}}>{feature.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default DootFeatures;
