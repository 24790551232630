import React from 'react'
import "./TrainingSection.css";
import Categery from './Categery'
import "slick-carousel/slick/slick-theme.css";
import CoursesSection from './CoursesSection';
import HeroSection from './HeroLanding';
import RequestButton from '../../ProductandSolution/common/RequestButton';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

const TrainingSection = () => {
  return (
    <div>
      <div className='top-container'>
        <div className="training-section">
          <div className="left-section">
            <h1>IT Corporate Training for Employees that Guarantee Higher ROI</h1>

            <div>
              <RequestButton
                href="/product/support"

              >
                Get in Touch

              </RequestButton>
            </div>
          </div>

        </div>
      </div>
      <Container fluid className="hero-section  text-white  product-container" style={{ background: "linear-gradient(45deg,rgb(3, 4, 86), rgb(0, 120, 237))", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Row style={{ maxWidth: '1200px' }}>
          <Col md={12} className="text-left p-5" >
            <p className="lead" style={{ fontSize: "18px", textAlign: "left" }}>
              Unlock the full potential of your workforce with CloudThat's expert-led IT corporate training programs.
              Our customized learning solutions help businesses upskill employees in cutting-edge technologies,
              ensuring they stay ahead in the competitive digital landscape. By investing in strategic training,
              you can enhance productivity, drive innovation, and achieve higher ROI—all while keeping costs under control.
              Experience seamless, hands-on learning tailored to your organization's unique needs.
            </p>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TrainingSection;
