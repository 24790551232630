/* eslint-disable jsx-a11y/img-redundant-alt */
// Career.jsx

import React from "react";
import {
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Link } from 'react-router-dom';
// import { useNavigate } from "react-router-dom";
import "./Career.css"; // Import the CSS file
import Footer from "../Footer/FooterSection";
import EmployeesReviewsPage from "./EmployeesReviewsPage";
import JobList from "./JobList";
import TestimonialSection from "./EmployeeTestmonials";
import { ArrowForward } from "@material-ui/icons";
import { School, Work, HealthAndSafety, Handshake, AutoGraph, VolunteerActivism } from '@mui/icons-material';
const Career = () => {

  const employeeData = [
    // {
    //   icon: "../images/tab_header_img.png",
    //   name: "Anshu, Full Stack Developer",
    //   picture: "../images/profile_img.jpg",
    //   reviews:
    //     '"At Onelogica, I have thrived as a full stack developer, benefitting from a dynamic and innovative environment. The organizations commitment to cutting-edge technologies, collaborative culture, and supportive leadership has made my experience highly positive. With promising prospects for career growth, Onelogica stands out as a rewarding workplace"',
    // },
    {
      icon: "../images/tab_header_img.png",
      name: "Ankit, Lead Data Scientist",
      picture: "../images/Ankit-Profile-img.jpg",
      reviews:
        '"Onelogicas unique approach to the R & D driven work environment keeps me challenged every day and enables us to provide our customer withthe best of the delivery. "',
    },
    {
      icon: "../images/tab_header_img.png",
      name: "Prachi, Lead BI Architect",
      picture: "../images/profile_img.jpg",
      reviews:
        '"We feel very empowered with the right fix of the team involved in the work. For us work is just fun as we enjoy working at Onelogica. Remote working is more fun the way onelogica has crafted."',
    },
    {
      icon: "../images/tab_header_img.png",
      name: "Vanshika, Data Analyst",
      picture: "../images/profile_img.jpg",
      reviews:
        '"I joined as a fresher and within a short span of time got ample opportunities to work on different tools and technology. Only performance matters at onelogica and I am getting all recognition and rewards. "',
    },
    {
      icon: "../images/tab_header_img.png",
      name: "Megha, Data Scientist",
      picture: "../images/megha-photo.png",
      reviews:
        '"Thrilled to be part of the Data Engineering & Analytics team at Onelogica. Collaborative atmosphere, impactful projects and access to cutting-edge tools make this a dynamic workplace. Excited about the prospects for growth and innovation! "',
    },
    {
      icon: "../images/tab_header_img.png",
      name: "Saurav, Web App Developer",
      picture: "../images/saurav-img.png",
      reviews:
        '"As a web application developer at Onelogica, I have had the privilege to contribute to innovative projects and collaborate with a talented team. My journey here has been one of continuous growth, both professionally and personally.I am proud to be part of a company that prioritizes employee development and values creativity and teamwork."',
    },



    // Add more employee data as needed
  ];

  return (
    <div className="careerPage">
      {/* Section 1: Panoramic Image */}
      <div className="panoramicImage heading">
        <Typography variant="h2" gutterBottom className="heading">
          Career
        </Typography>
        <Typography variant="h5" className="subheading">
          Join our team of innovative minds
        </Typography>
      </div>

      {/* Section 2: Ready to Change the World */}
      <div className="career-container">
        <div className="readyToChange">
          <Typography variant="h4" gutterBottom style={{textAlign:'center'}}>
            Ready to Change the World?
          </Typography>
          <Typography variant="subtitle1"style={{textAlign:'center'}}>
            If you think you can change the world through innovation, be part of
            our integral Onelogioca family.
          </Typography>
        </div>

        {/* Section 3: Cards for Different Audiences */}
        <Grid container spacing={3} className="cardContainer">
          {/* Card 1: Students and Recent Graduates */}
          <Grid item xs={12} md={6}>
            <Card className="careerCard">
              <img
                className="Card-img"
                src="../images/STudents_Img.jpg"
                alt="Student Career"
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Students and Recent Graduates
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  We offer a variety of internship opportunities designed to
                  empower you. Imagine the impact you can have and jumpstart
                  your career with limitless boundaries of creativity.
                </Typography>
                <Link to="/internspage">
                  <Button variant="contained" color="primary" className="careerbtn">
                    Explore Students Opportunities <ArrowForward/>
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 2: Experienced Professionals */}
          <Grid item xs={12} md={6}>
            <Card className="careerCard">
              <img
                className="Card-img"
                src="../images/Experiene_Career.jpg"
                alt="Experience Career"
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Experienced Professionals
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  If you think you can, step in to explore the wealth of career
                  opportunities and take your career to the next level.
                </Typography>
                <Link to="/fulltimepage">
                  <Button variant="contained" color="primary"  className="careerbtn">
                    Explore Career Opportunities <ArrowForward/>
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <TestimonialSection employees={employeeData} />
        {/* <EmployeesReviewsPage employees={employeeData} /> */}
      </div>




      {/* Section 4: Benefits */}

      <Container className="career-container">
      <Grid container spacing={3} className="cardContainer" style={{paddingTop:"4%"}}>
        {/* Card 1: Shape your Career */}
        <Grid item xs={12} md={4}>
          <Card className="careerCard">
            <CardContent>
              <Grid container alignItems="center">
                {/* Icon */}
                {/* <Grid item xs={2}>
                  <Work fontSize="large" color="primary" />
                </Grid> */}
                {/* Text */}
                {/* <Grid item xs={10}> */}
                <Work fontSize="large" color="primary" />
                  <Typography variant="h5" component="div" gutterBottom style={{marginLeft:"12px",marginBottom:"0px"}}>
                    Shape your Career
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{marginTop:"10px"}}>
                    Bring your ideas and pursue innovative career tracks, opportunities, and job rotations.
                  </Typography>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 2: Learn and Grow */}
        <Grid item xs={12} md={4}>
          <Card className="careerCard">
            <CardContent>
              <Grid container alignItems="center">
                {/* Icon */}
                {/* <Grid item xs={2}>
                  <School fontSize="large" color="primary" />
                </Grid> */}
                {/* Text */}
                {/* <Grid item xs={10}> */}
                <School fontSize="large" color="primary" />
                  <Typography variant="h5" component="div" gutterBottom style={{marginLeft:"12px",marginBottom:"0px"}}>
                    Learn and Grow
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{marginTop:"10px"}}>
                    Enhance your professional development through education and training.
                  </Typography>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 3: Keep Current */}
        <Grid item xs={12} md={4}>
          <Card className="careerCard">
            <CardContent>
              <Grid container alignItems="center">
                {/* Icon */}
                {/* <Grid item xs={2}>
                  <AutoGraph fontSize="large" color="primary" />
                </Grid> */}
                {/* Text */}
                {/* <Grid item xs={10}> */}
                <AutoGraph fontSize="large" color="primary" />
                  <Typography variant="h5" component="div" gutterBottom style={{marginLeft:"12px",marginBottom:"0px"}}>
                    Keep Current
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{marginTop:"10px"}}>
                    Our skills training helps you keep pace with the changing workplace.
                  </Typography>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 4: Give Back */}
        <Grid item xs={12} md={4}>
          <Card className="careerCard">
            <CardContent>
              <Grid container alignItems="center">
                {/* Icon */}
                {/* <Grid item xs={2}>
                  <VolunteerActivism fontSize="large" color="primary" />
                </Grid> */}
                {/* Text */}
                {/* <Grid item xs={10}> */}
                <VolunteerActivism fontSize="large" color="primary" />
                  <Typography variant="h5" component="div" gutterBottom  style={{marginLeft:"12px",marginBottom:"0px"}}>
                    Give Back
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{marginTop:"10px"}} >
                    Help improve our communities and environment.
                  </Typography>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 5: Stay Healthy */}
        <Grid item xs={12} md={4}>
          <Card className="careerCard">
            <CardContent>
              <Grid container alignItems="center">
                {/* Icon */}
                {/* <Grid item xs={2}>
                  <HealthAndSafety fontSize="large" color="primary" />
                </Grid> */}
                {/* Text */}
                {/* <Grid item xs={10}> */}
                <HealthAndSafety fontSize="large" color="primary" />
                  <Typography variant="h5" component="div" gutterBottom style={{marginLeft:"12px",marginBottom:"0px"}}>
                    Stay Healthy
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{marginTop:"10px"}}>
                    Be well with health plans that help you support your loved ones.
                  </Typography>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Card 6: Invest in Yourself */}
        <Grid item xs={12} md={4}>
          <Card className="careerCard">
            <CardContent>
              <Grid container alignItems="center">
                {/* Icon */}
                {/* <Grid item xs={2}>
                  <Handshake fontSize="large" color="primary" />
                </Grid> */}
                {/* Text */}
                {/* <Grid item xs={10}> */}
                <Handshake fontSize="large" color="primary" />
                  <Typography variant="h5" component="div" gutterBottom style={{marginLeft:"12px",marginBottom:"0px"}}>
                    Invest in Yourself
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{marginTop:"10px"}}>
                    Plan for the future with our compensation and benefits packages, including holidays.
                  </Typography>
                {/* </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>

      <JobList />

      <div className="container-hiring">
        <div className="hiring">
          <h1>Inclusive Hiring for all...</h1>
          <p>
            "At Onelogica, we know that having a diverse workforce which
            includes people with disabilities is essential if we are going to
            deliver on our mission to empower every person and every
            organization on the planet to achieve more. Our Neurodiversity
            Hiring Program, Ability Hiring events, and inclusive interviews
            enable all candidates to showcase their skills and bring their best
            selves."
          </p>
          <h1>Let's transform together!</h1>
        </div>
        <div className="image-container-hiring">
          <img
            className="hiringImg"
            src="../images/hiring-img.jpg"
            alt="hiring-Image"
          />
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Career;
