import React from "react";
import {
  FaChartPie,
  FaDatabase,
  FaCloud,
  FaBrain,
  FaChartLine,
  FaFilter,
} from "react-icons/fa";
import "./featuresSection.css"; // Import CSS

const features = [
  {
    title: "360-Degree Enterprise View",
    description: "Analyze data across Finance, Sales, IT, HR, and Marketing.",
    icon: <FaChartPie />,
  },
  {
    title: "Medallion Architecture",
    description:
      "Leverage Lakehouse, Onelake, and Delta lakes for advanced data processing.",
    icon: <FaDatabase />,
  },
  {
    title: "Cloud-Enabled Technology",
    description: "Powered by Microsoft Fabric, Databricks, and Snowflake.",
    icon: <FaCloud />,
  },
  {
    title: "Self-Service",
    description: "Customizable data processing tiers to fit organizational needs.",
    icon: <FaChartLine />,
  },
  {
    title: "AI-Driven Insights",
    description:
      "Proprietary AI & ML algorithms provide predictive, prescriptive, diagnostic, and generative intelligence.",
    icon: <FaBrain />,
  },

  {
    title: "Tailored Analytics",
    description: "User-friendly analytics for both business and citizen users.",
    icon: <FaFilter />,
  },
];

const FeaturesSection = () => {
  return (
    <div className="features-containereas">
      {/* Header Section */}
      <div className="features-header">
        <h2>Key Features</h2>
      </div>

      {/* Image Section */}
      {/* <div className="features-image">
        <img src="/your-image-path.png" alt="Dashboard" />
      </div> */}

      {/* Features Grid */}
      <div className="easfeatures-grid">
        {features.map((feature, index) => (
          <div key={index} className="easfeature-card">
            <div className="easfeature-icon">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>

    </div>
  );
};

export default FeaturesSection;
