import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
// import Footer from '../../Footer/Footer';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { Link } from '@material-ui/core';
import "./glam.css";
import { Helmet } from 'react-helmet-async';
import { QuestionAnswer, Translate } from '@material-ui/icons';
import { Insights, Summarize } from '@mui/icons-material';
import VideoSection from '../common/VideoCOmponent';
import ProductBenefits from './ProductBenefit';
import RequestButton from '../common/RequestButton';
import VideoShowcase from '../video/video-showcase';
import ContactSection from '../common/ContactSection';
import Footer from '../../Footer/FooterSection';

const GlamLandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Product | GLAM</title>
        <meta name="description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
        <meta name="keywords" content="AI, analytics, document translation, summarization, insights, Q&A, GLAM" />
        <meta name="robots" content="index, follow" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="author" content="OneLogica Team" />

        {/* Open Graph */}
        <meta property="og:title" content="Product | GLAM" />
        <meta property="og:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
        <meta property="og:image" content="URL_of_image.jpg" />
        <meta property="og:url" content="https://www.onelogica.com/product/glam" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="OneLogica" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Product | GLAM" />
        <meta name="twitter:description" content="AI-based analytics for document translation, summarization, insights, and Q&A." />
        <meta name="twitter:image" content="URL_of_image.jpg" />
        <meta name="twitter:site" content="@OneLogica" />

        <link rel="canonical" href="https://www.onelogica.com/product/glam" />
      </Helmet>

      {/* Hero Section */}
      <Container fluid className="hero-section industry-intro-section  text-white  product-container" style={{
        // backgroundColor: "rgb(51 64 80)",
        // background: "#101c3d",
        backgroundImage: `url(/product/glambg.png)`,
        filter: "brightness(75%)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginTop: "3rem",
        height: "95vh"
      }}>
        <Row className="align-items-center">
          <Col md={7} className="text-left p-5 industry-intro-text" >
            <h6 className="display-5" style={{ fontWeight: "400", color: "white" }}>Generative Language based Analytics and Maximization (GLAM)</h6>
            <RequestButton href="/product/support">Request Demo</RequestButton>  
          </Col>
        </Row>
      </Container>
      <Container fluid className="hero-section  text-white  product-container" style={{background: "linear-gradient(45deg,rgb(3, 4, 86), rgb(0, 120, 237))" }}>
        <Row>
          <Col md={12} className="text-left p-5" >
            <p className="lead" style={{fontSize:"18px",textAlign:"left"}}>GLAM (Generative Language based Analytics and Maximization). It is a cutting-edge platform that leverages Azure's AI capabilities to transform how you interact with your documents. Whether you need translations, summaries, insights, or Q&A, GLAM has got you covered.</p>
          </Col>
        </Row>
      </Container>


      {/* Features Section */}
      <Container id="features" className="py-5 features-section">
        <Row className="align-items-center">
          {/* Left Column: Title and Subheading */}
          <Col lg={4} className="text-left">
            <h2 className="productsection-title mb-3 text-left">Empowering Your Workflow with GLAM</h2>
            <p className="productsection-subtitle">
              Optimize, analyze, and transform your documents with ease.
            </p>
          </Col>

          {/* Right Column: 2x2 Card Grid */}
          <Col lg={8}>
            <Row className="gx-4 gy-4">
              {/* First Row */}
              <Col md={6}>
                <Card className="shadow-sm productglamfeature-card" style={{ border: "none", background: "none", padding: "10px" }}>
                  <Card.Body className="d-flex align-items-center">
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}>
                        <span
                          style={{
                            background: "linear-gradient(90deg, rgb(106, 17, 203), rgb(37, 117, 252))",
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            marginRight: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i
                            style={{ color: "white", fontSize: "1.3rem", display: "flex", justifyContent: "center", alignItems: "center" }}
                          ><Translate /></i>
                        </span>
                        <Card.Title className='cardtitle'>Translation</Card.Title>
                      </div>
                      <Card.Text style={{ textAlign:"left",fontSize:"14px" }}>
                        Easily translate your documents into multiple languages using Azure's  services.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6}>
                <Card className="shadow-sm productglamfeature-card" style={{ border: "none", background: "none", padding: "10px" }}>
                  <Card.Body className="d-flex align-items-center">
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}>
                        <span
                          // className="feature-icon me-3"
                          style={{
                            background: "linear-gradient(90deg, rgb(106, 17, 203), rgb(37, 117, 252))",
                            // borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            marginRight: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i
                            className="bi bi-file-earmark-text"
                            style={{ color: "white", fontSize: "1.3rem", display: "flex", justifyContent: "center", alignItems: "center" }}
                          ><Summarize /></i>
                        </span>
                        <Card.Title className='cardtitle'>Summarization</Card.Title>
                      </div>

                      <Card.Text  style={{ textAlign:"left",fontSize:"14px" }}>
                        Get concise and meaningful summaries of lengthy documents in seconds.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              {/* Second Row */}
              <Col md={6}>
                <Card className="shadow-sm productglamfeature-card" style={{ border: "none", background: "none", padding: "10px" }}>
                  <Card.Body className="d-flex align-items-center">
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}>
                        <span
                          style={{
                            background: "linear-gradient(90deg, rgb(106, 17, 203), rgb(37, 117, 252))",
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            marginRight: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i
                            className="bi bi-bar-chart"
                            style={{ color: "white", fontSize: "1.3rem", display: "flex", justifyContent: "center", alignItems: "center" }}
                          ><Insights /></i>
                        </span>
                        <Card.Title className='cardtitle'>Text Insights</Card.Title>
                      </div>
                      <Card.Text  style={{ textAlign:"left",fontSize:"14px" }}>
                        Unlock valuable insights from your text with advanced analytics.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6}>
                <Card className="shadow-sm productglamfeature-card" style={{ border: "none", background: "none", padding: "10px" }}>
                  <Card.Body className="d-flex align-items-center">
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}>
                        <span
                          // className="feature-icon me-3"
                          style={{
                            background: "linear-gradient(90deg, rgb(106, 17, 203), rgb(37, 117, 252))",
                            // borderRadius: "50%",
                            width: "35px",
                            height: "35px",
                            display: "flex",
                            marginRight: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <i
                            className="bi bi-question-circle"
                            style={{ color: "white", fontSize: "1.3rem", display: "flex", justifyContent: "center", alignItems: "center" }}
                          ><QuestionAnswer /></i>
                        </span>
                        <Card.Title className='cardtitle'>Q&A</Card.Title>
                      </div>
                      <Card.Text  style={{ textAlign:"left" ,fontSize:"14px"}}>
                        Ask questions and get answers based on the content of your document.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* Advantages Section */}
      <ProductBenefits />
      <VideoShowcase title="AOAI based Smart search, Q&A, summarization, translation, Entity Enrichment chat Engine" description="GLAM is a leading-edge offering that utilizes the power of Artificial OpenAI (AOAI) and Azure Search to provide advanced analytics, smart search, question answering, summarization, translation, moderation, and chat capabilities on unstructured data." videoUrl="https://www.youtube.com/watch?v=9FH-3r9h_D8"/>
    
      {/* CTA Section */}
      {/* <ContactSection/> */}
        <section
          style={{
            background: "linear-gradient(45deg, #a05353b0, #568ac37a)",
          }}
        >
         <ContactSection/>
        </section>
        <Footer/>
      {/* <Footer /> */}
    </>
  );
};

export default GlamLandingPage;
