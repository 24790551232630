import React from "react";
import {
  FaChartLine,
  FaShoppingCart,
  FaBullhorn,
  FaUsers,
  FaLaptopCode,
} from "react-icons/fa";
import "./analytics.css"; // Import CSS

const benefits = [
  {
    title: "Finance",
    description: "Track revenue, profitability, ROI, NPV, and regional performance.",
    icon: <FaChartLine />,
  },
  {
    title: "Sales",
    description: "Assess top product performance and profitability efficiency.",
    icon: <FaShoppingCart />,
  },
  {
    title: "Marketing",
    description: "Leverage recommendation systems, sentiment analysis, SEO optimization, and churn control.",
    icon: <FaBullhorn />,
  },
  {
    title: "HR",
    description: "Manage recruitment, optimize workforce, analyze productivity, and track employee satisfaction.",
    icon: <FaUsers />,
  },
  {
    title: "IT",
    description: "Improve ticket management, incident resolution time, and service level agreement compliance.",
    icon: <FaLaptopCode />,
  },
];

const ComprehensiveAnalytics = () => {
  return (
    <div className="analytics-container">
      {/* Top Section */}
      <h2 className="analytics-title">Comprehensive Analytics</h2>
      <div  className="analytics-Section">
      <div className="analytics-descriptionimg" >
      <div className="analytics-image" >
        <img src="../newimages/P4 - Product Details.png" alt="Dashboard"  style={{width:"40vw",border:"2px solid white"}}/>
      </div>
      <div className="analytics-image">
        <img src="../newimages/P8 - Sales Team Performance.png" alt="Dashboard"  style={{width:"40vw",marginLeft:"5rem",marginTop:"-10px",border:"2px solid white"}}/>
      </div>
      </div>

      {/* Bottom Section - Cards */}
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <div key={index} className="benefit-card">
            <div className="hexagon">
              <span className="iconnew">{benefit.icon}</span>
            </div>
            <h3>{benefit.title}</h3>
            <p>{benefit.description}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default ComprehensiveAnalytics;
