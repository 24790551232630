// "use client"
// import { useState } from "react"
// import BlogCard from "./BlogCard"
// import CategoryFilter from ".//CategoryFilter"
// import "./blogs.css"
// import Footer from "../../Footer/Footer"
// // Function to create a slug from a title
// const generateSlug = (title) => {
//   return title.toLowerCase().replace(/ /g, "-");
// };

// // Blog data
// const blogPosts = [
//   {
//     id: 1,
//     title: "Data analytics for retail growth ",
//     content:
//       "This case study explores how AI-powered analytics helps retailers enhance customer insights by unifying data sources across their business. With Microsoft data solutions, companies improved engagement, optimized store operations, and strengthened security, leading to increased sales and customer retention.",
//     category: "Data Analytics",
//     date: "Mar 27, 2025",
//     author: "Onelogica Team",
//     image: "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2023-03/61413ba6e2c7cb3fc42d5249_60c025e054f2267b7e657af5_60a30e27845e16729afaec26_shutterstock_1861448179-min.jpeg",

//     link: `/blog/${generateSlug("Data analytics for retail growth")}`,


//   },
//   {
//     id: 2,
//     title: "Elevating customer journeys in retail\u202F ",
//     content:
//       "<p>Uncover how data-driven strategies can reshape customer journeys and learn the art of staying attuned to your consumer preferences.\u202F\u202F </p>\n\n<p>\u202F </p>\n\n<p>Our latest blog article delves into the world of retail and CPG, sharing useful insights on the secrets to deciphering your consumer desires and enhancing their experiences. Read it now. <br />\n </p>\n",
//     category: "Retail",
//     date: "Mar 26, 2025",
//     author: "Onelogica Team",
//     image: "https://www.retail-insight-network.com/wp-content/uploads/sites/18/2023/09/shutterstock_1913195662.jpg",
//     link: `/blog/${generateSlug("Elevating customer journeys in retail")}`,

//   },
//   {
//     id: 3,
//     title: "How AI is changing retail for good\u202F ",
//     content:
//       "<p>Only by focusing on achieving the highest satisfaction rates can retailers cater to changing customer preferences and deliver consistent business growth. At the same time, they must remove inefficiencies from their operations, both internally and externally, with partners and suppliers.\u202F </p>\n\n<p>\u202F </p>\n\n<p>How can retailers strike this balance between service and growth? The answer lies in maximizing data. Download the research paper for the full picture. <br />\n </p>\n",
//     category: "AI",
//     date: "Mar 25, 2025",
//     author: "Onelogica Team",
//     image: "https://blog.emb.global/wp-content/uploads/2024/02/The-Future-of-Shopping-How-AI-Image-Recognition-is-Changing-Retail.webp",

//     link: `/blog/${generateSlug("How AI is changing retail for good")}`,

//   },

//   {
//     id: 5,
//     title: "Power your growth with cloud scale analytics\u202F ",
//     content:
//       "<p>Evolving with the customer is essential for consistent growth through competitive advantage. Learn how retail leaders are harnessing the power of their data for truly remarkable customer experiences, greater supply chain efficiency and fraud prevention.\u202F\u202F </p>\n\n<p>\u202F </p>\n\n<p>Download our eBook now to get actionable insights on maximizing data in retail. <br />\n </p>\n\n<p> </p>\n",
//     category: "Cloud",
//     date: "Mar 19, 2025",
//     author: "Onelogica Team",
//     image: "https://www.winsavvy.com/wp-content/uploads/2024/07/Predictive-Analytics-1.jpg",

//     link: `/blog/${generateSlug("Data analytics for retail growth")}`,


//   },
//   {
//     id: 6,
//     title: "Revenue growth management and generative AI ",
//     content:
//       "<p>It's a bold prediction. Generative AI will give more employees access to Revenue Growth Management (RGM) tools and insights, while providing a path to help companies leap from their current level to higher levels of maturity.\u202F\u202F </p>\n\n<p>\u202F </p>\n\n<p>Read more about the immense potential of RGM and how we can assist you with strategically embedded generative AI that will drive stronger RGM outcomes. </p>\n\n<p> </p>\n",
//     category: "AI",
//     date: "Mar 18, 2025",
//     author: "Onelogica Team",
//     image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUHbLoecb0Cx4NmJa_MRElSf3y4pMb5iSwlg&s",

//     link: `/blog/${generateSlug("Data analytics for retail growth")}`,

//   },


//   {
//     id: 9,
//     title: "Benefits of AI and analytics for retail ",
//     content:
//       '<p>From breaking down data silos to meeting the "instant-gratification" demands of customers, Microsoft\'s cloud analytics solutions provide you with:\u202F <br />\n </p>\n\n<p>✅ A single source of truth\u202F </p>\n\n<p>✅ Real-time insights\u202F </p>\n\n<p>✅ Personalized customer experiences\u202F </p>\n\n<p>✅ Resilient retail\u202F </p>\n\n<p>✅ Data-driven insights and opportunities\u202F </p>\n\n<p>\u202F\u202F </p>\n\n<p>Download our infographic to explore the benefits, challenges, and real-world success stories of retail organizations harnessing the power of their data.\u202F\u202F </p>\n\n<p> </p>\n\n<p> </p>\n',
//     category: "AI",
//     date: "Mar 12, 2025",
//     author: "Onelogica Team",
//     image: "https://media.licdn.com/dms/image/v2/C4D12AQGKKmmM6dqPdw/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1635688864610?e=2147483647&v=beta&t=hCbWcoeEFiUkd_S9vtptK5AUbbV98KqwBvIEWa8ictk",
//     link: `/blog/${generateSlug("Benefits of AI and analytics for retail")}`,

//   },
//   {
//     id: 10,
//     title: "4 key lessons in generative AI for retail ",
//     content:
//       "<p>In any technology-focused initiative, people, processes, and technology all need to be considered together. Generative AI is no different. Let our experts help you discover the four key lessons that shape the future of AI deployment. From concept to practical application, this article provides a road map for success in the world of AI innovation.\u202F </p>\n\n<p>\u202F\u202F </p>\n\n<p>Ready to harness the potential of generative AI in retail? Don't miss out on this essential read. </p>\n\n<p> </p>\n",
//     category: "AI",
//     date: "Mar 11, 2025",
//     author: "Onelogica Team",
//     image: "https://www.shutterstock.com/image-vector/modern-flat-design-isometric-concept-600nw-1766618132.jpg",
//     link: `/blog/${generateSlug("4 key lessons in generative AI for retail ")}`,


//   },
//   {
//     id: 11,
//     title: "Revolutionizing Retail with Cloud Scale Analytics ",
//     content:
//       "<p>Retail organizations that embrace AI for data analytics benefit from better customer experiences, smoother operations and enhanced security. Download our infographic to find out how Microsoft Cloud scale analytics solutions can revolutionize retail.\u202F </p>\n\n<p> </p>\n",
//     category: "Cloud",
//     date: "Mar 7, 2025",
//     author: "Onelogica Team",
//     image: "https://hgs.cx/wp-content/uploads/2024/11/Blog-Banner-Using-Cloud-Based-Platforms-to-Scale-Your-Digital-Marketing-Efforts-copy.webp",

//     link: `/blog/${generateSlug("Revolutionizing Retail with Cloud Scale Analytics")}`,


//   },
// ]

// // Get unique categories
// const categories = ["All", ...Array.from(new Set(blogPosts.map((post) => post.category)))]

// function BlogPage() {
//   const [activeCategory, setActiveCategory] = useState("All")
//   const [currentPage, setCurrentPage] = useState(1)
//   const postsPerPage = 6

//   // Filter posts by category
//   const filteredPosts =
//     activeCategory === "All" ? blogPosts : blogPosts.filter((post) => post.category === activeCategory)

//   // Calculate pagination
//   const indexOfLastPost = currentPage * postsPerPage
//   const indexOfFirstPost = indexOfLastPost - postsPerPage
//   const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost)
//   const totalPages = Math.ceil(filteredPosts.length / postsPerPage)

//   // Handle page change
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber)
//     window.scrollTo({ top: 0, behavior: "smooth" })
//   }


//   return (
//     <div>
//       {/* Hero Section */}
//       <div
//         className="header-banner text-white text-center d-flex flex-direction-column align-items-center justify-content-center"
//         style={{ flexDirection: "column" }}
//       >
//         <div>
//           <h1 className="hero-title">Exploring the Wonders of Hiking</h1>
//           <p className="hero-description">
//             Discover hidden trails, breathtaking gardens, and scenic escapes to fuel your adventure.
//           </p>
//         </div>
//       </div>
//       <div className="app-container">
//       <h1 className="blog-title">Blog</h1>

//       {/* Category Filter */}
//       <CategoryFilter categories={categories} activeCategory={activeCategory} onCategoryChange={setActiveCategory} />

//       {/* Blog Posts Grid */}
//       <div className="blog-grid">
//         {currentPosts.map((post) => (
//           <BlogCard key={post.id} post={post} />
//         ))}
//       </div>

//       {/* Pagination */}
//       {totalPages > 1 && (
//         <div className="pagination">
//           <button
//             className="pagination-button"
//             onClick={() => handlePageChange(currentPage - 1)}
//             disabled={currentPage === 1}
//             aria-label="Previous page"
//           >
//             &lt;
//           </button>

//           {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
//             <button
//               key={number}
//               className={`pagination-button ${currentPage === number ? "active" : ""}`}
//               onClick={() => handlePageChange(number)}
//             >
//               {number}
//             </button>
//           ))}

//           <button
//             className="pagination-button"
//             onClick={() => handlePageChange(currentPage + 1)}
//             disabled={currentPage === totalPages}
//             aria-label="Next page"
//           >
//             &gt;
//           </button>
//         </div>
//       )}
//     </div>

//       <Footer />
//     </div>
//   );
// };

// export default BlogPage;
"use client"

import { useState } from "react"
import "./blogs.css"
import CategoryFilter from "./CategoryFilter"
import Footer from "../../Footer/FooterSection"

// Dummy blog posts data
const blogPosts = [
  {
    id: 1,
    title: "Data Analytics for Retail Growth",
    tag: "Data Analytics",
    description:
      "This blog post explores how AI-powered analytics helps retailers enhance customer insights by unifying data sources across their business. With Microsoft data solutions, companies improved engagement, optimized store operations, and strengthened security, leading to increased sales and customer retention.",
    image: "https://www.institutedata.com/wp-content/uploads/2023/08/Data-privacy-and-security-concerns.png",
    slug: "data-analytics-retail-growth",
  },
  {
    id: 2,
    title: "Revolutionizing Retail with Data Analytics",
    tag: "Data Analytics",
    description:
      "This blog post highlights how advanced data analytics boosts revenue growth by improving customer experience, optimizing inventory, and refining marketing strategies. AI-driven insights streamline workflows, enhance engagement, and support data-driven decision-making for seamless retail operations.",
    image: "https://i0.wp.com/blog.nashtechglobal.com/wp-content/uploads/2024/01/1-28.jpg?fit=1024%2C536&ssl=1",
    slug: "retail-data-analytics-revolution",
  },
  {
    id: 3,
    title: "AI and Data Analytics in Retail",
    tag: "AI & Data Analytics",
    description:
      "This blog post explores how AI and data analytics drive retail growth by providing a unified data foundation, real-time insights, and personalized customer experiences. AI-powered strategies optimize e-commerce, automate workflows, enhance search, and drive innovation across retail operations.",
    image: "https://www.immerse.education/wp-content/uploads/2022/11/Website-Images.jpg",
    slug: "ai-data-analytics-retail",
  }
]

// Define your categories (ensure these match the tags in your blogPosts)
const categories = ["All", "Data Analytics", "AI & Data Analytics"]

function BlogPage() {
  const [currentPage, setCurrentPage] = useState(1)
  const postsPerPage = 4
  const totalPages = Math.ceil(blogPosts.length / postsPerPage)
  const [activeCategory, setActiveCategory] = useState("All")

  // Filter posts by category
  const filteredPosts =
    activeCategory === "All" ? blogPosts : blogPosts.filter((post) => post.tag === activeCategory)
    
  // Use filtered posts for pagination
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost)

  const nextPage = () => setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev))
  const prevPage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))

  return (
    <div>
      <div
        className="header-banner text-white text-center d-flex flex-direction-column align-items-center justify-content-center"
        style={{ flexDirection: "column" }}
      >
        <div>
          <h1 className="hero-title">Exploring the Wonders of Hiking</h1>
          <p className="hero-description">
            Discover hidden trails, breathtaking gardens, and scenic escapes to fuel your adventure.
          </p>
        </div>
      </div>
      <section className="blog-section">
        <h2 className="blog-title">Blog</h2>
        <CategoryFilter
          categories={categories}
          activeCategory={activeCategory}
          onCategoryChange={setActiveCategory}
        />

        <div className="blog-grid">
          {currentPosts.map((post) => (
            <div key={post.id} className="blog-card">
              <div className="blog-image-container">
                <span className="blog-tag">{post.tag}</span>
                <div className="blog-placeholder-image">
                  <img src={post.image} alt="Placeholder" className="blog-placeholder-icon" />
                </div>
              </div>
              <div className="blog-content">
                <h3 className="blog-post-title">{post.title}</h3>
                <p className="blog-description">{post.description}</p>
                <a href={`/blog/${post.slug}`} className="blog-read-more-btn">
                  Read More
                </a>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className="pagination-btn prev-btn"
            aria-label="Previous page"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M15 18l-6-6 6-6"></path>
            </svg>
          </button>

          {Array.from({ length: totalPages }).map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={`pagination-btn page-number ${currentPage === index + 1 ? "active" : ""}`}
              aria-label={`Page ${index + 1}`}
            >
              {index + 1}
            </button>
          ))}

          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className="pagination-btn next-btn"
            aria-label="Next page"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M9 18l6-6-6-6"></path>
            </svg>
          </button>
        </div>
      </section>
      <Footer/>
    </div>
  )
}

export default BlogPage
