import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import {
    Group, Security, Build, Campaign, CreditCard,
} from "@mui/icons-material";

const useCases = [
    { title: "Customer Segmentation", icon: <Group /> },
    { title: "Risk Management", icon: <Security /> },
    { title: "Predictive Maintenance", icon: <Build /> },
    { title: "Marketing Campaign", icon: <Campaign /> },
    { title: "Credit Risk Analysis", icon: <CreditCard /> },
];

const UseCases = (props) => {
    console.log("props", props)
    return (
        <Box sx={{ p: 1, textAlign: "center",  }}>

            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={12} md={6}>
                    <div style={{textAlign:"center",display:"flex",justifyContent:"left",alignItems:"center",flexDirection:"column",padding:"20px"}}>
                        <h3>{props.selectedItem.title}</h3>
                        <p style={{ textAlign:"center",marginTop:"20px"}}>{props.selectedItem.description}</p>
                    <div>
                        <img src={props.selectedItem.img} style={{height:"auto",width:"100%",borderRadius:"12px"}}></img>
                    </div>
                    </div>
                </Grid>
                    <Grid item xs={12} sm={12} md={6}   >
                {props.useCases.map((useCase, index) => (

                        <Paper
                            sx={{
                                // p: 4,
                                margin:"12px",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                textAlign: "center",
                                borderRadius: "20px",
                                transition: "0.3s",
                                width:"auto",
                                "&:hover": { boxShadow: 6, transform: "translateY(-5px)" },
                            }}
                        >
                            <Box
                                sx={{
                                    width: 80,
                                    height: 80,
                                    // border:"1px solid red",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    //   borderRadius: "50%",
                                    //   backgroundColor: "#f4a41c",
                                    // mb: 2,
                                }}
                            >
                                {React.cloneElement(useCase.icon, { sx: { fontSize: 30, color: "blue" } })}
                            </Box>
                            <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333" }}>
                                {useCase.title}
                            </Typography>
                        </Paper>

                ))}
                    </Grid>
            </Grid>
        </Box>
    );
};

export default UseCases;
