// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/cloud.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-benefits-section {
    position: relative;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
    background-size: cover;
    background-position: center;
    padding: 60px 0;
    color: white;
  }
  
  .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  }
  
  .glambenefit-card {
    background: #ffffffb3;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .glambenefit-card:hover {
    transform: translateY(-5px);
  }
  
  .benefit-number {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #0078ED;
    color: white;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 15px;
  }
  
  .learn-more {
    display: inline-block;
    margin-top: 10px;
    color: #030456;
    font-weight: bold;
    text-decoration: none;
    display: none;
  }
  
  .learn-more:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ProductandSolution/glam/productBenefit.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yDAAkD;IAClD,sBAAsB;IACtB,2BAA2B;IAC3B,eAAe;IACf,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B,EAAE,iBAAiB;EACnD;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,sCAAsC;EACxC;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,aAAa;EACf;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".product-benefits-section {\r\n    position: relative;\r\n    background-image: url(\"/public/images/cloud.webp\"); \r\n    background-size: cover;\r\n    background-position: center;\r\n    padding: 60px 0;\r\n    color: white;\r\n  }\r\n  \r\n  .bg-overlay {\r\n    position: absolute;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background: rgba(0, 0, 0, 0.5); /* Dark overlay */\r\n  }\r\n  \r\n  .glambenefit-card {\r\n    background: #ffffffb3;\r\n    border-radius: 8px;\r\n    padding: 20px;\r\n    text-align: center;\r\n    transition: transform 0.3s ease-in-out;\r\n  }\r\n  \r\n  .glambenefit-card:hover {\r\n    transform: translateY(-5px);\r\n  }\r\n  \r\n  .benefit-number {\r\n    display: inline-block;\r\n    width: 40px;\r\n    height: 40px;\r\n    background: #0078ED;\r\n    color: white;\r\n    border-radius: 50%;\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    line-height: 40px;\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .learn-more {\r\n    display: inline-block;\r\n    margin-top: 10px;\r\n    color: #030456;\r\n    font-weight: bold;\r\n    text-decoration: none;\r\n    display: none;\r\n  }\r\n  \r\n  .learn-more:hover {\r\n    text-decoration: underline;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
